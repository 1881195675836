var validationRules = {
  requiredstr: {
    method: function method(value, elAttrValue) {
      return value && elAttrValue === 'true';
    },
    message: 'GENERAL.requiredFieldMissingError'
  },
  minlengthval: {
    method: function method(value, elAttrValue) {
      return !value || value.length > parseInt(elAttrValue);
    },
    message: 'GENERAL.minLengthError'
  },
  maxlengthval: {
    method: function method(value, elAttrValue) {
      return !value || value.length <= parseInt(elAttrValue);
    },
    message: 'GENERAL.maxLengthError'
  },
  emailval: {
    method: function method(value, elAttrValue) {
      return elAttrValue === 'false' || !value || /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(value.toLowerCase());
    },
    message: 'GENERAL.invalidEmailFormat'
  }
};

var triggerValidationError = function triggerValidationError(element, validationAttr) {
  var event = new Event('invalid-data', {
    bubbles: false,
    cancelable: true,
    isValid: false
  });
  element.errormsg = validationRules[validationAttr.name].message;
  parseInt(validationAttr.value) && (element.errormsgparam = validationAttr.value);
  element.dispatchEvent(event);
};

var validateForm = function validateForm(form) {
  var isValidForm = true;
  Array.from(form.querySelectorAll('input:not([type="radio"]),textarea')).forEach(function (element) {
    var validations = [element.attributes['requiredstr'], element.attributes['minlengthval'], element.attributes['maxlengthval'], element.attributes['emailval']].filter(function (x) {
      return x && x.value && x.value !== 'false';
    });
    validations.forEach(function (val) {
      var isValid = validationRules[val.name].method(element.value, val.value);

      if (!isValid) {
        isValidForm = false;
        triggerValidationError(element, val);
      }
    });
  });
  Array.from(form.querySelectorAll('select')).forEach(function (element) {
    var validationAttr = element.attributes['requiredstr'];

    if (validationAttr && validationAttr.value === 'true') {
      var selectedValue = element[element.selectedIndex].value;
      var isValid = validationRules[validationAttr.name].method(selectedValue, validationAttr.value);

      if (!isValid) {
        isValidForm = false;
        triggerValidationError(element, validationAttr);
      }
    }
  });
  return isValidForm;
};

export default validateForm;