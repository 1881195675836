import React from 'react';
import ServiceClassEvent from './sections/ServiceClassEvent';
import ThingsToDoWithGuryou from './sections/ThingsToDoWithGuryou';
const Benefits = ({ width, tabletWidth, laptopWidth }) => {
  return (
    <>
      <ServiceClassEvent width={width} tabletWidth={tabletWidth} laptopWidth={laptopWidth} />
      <ThingsToDoWithGuryou width={width} tabletWidth={tabletWidth} laptopWidth={laptopWidth} />
    </>
  );
};

export default Benefits;
