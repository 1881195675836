import React from 'react';
import { useTranslation } from 'react-i18next';
import { colors } from '@guryou/colors';
import { LargeText } from '@guryou/html-components';
import CheckRoundedIcon from '@material-ui/icons/CheckRounded';
import ContentPiece from './ContentPiece';
const Content = ({ width, tabletWidth, laptopWidth }) => {
  const { t } = useTranslation();

  return (
    <>
      <LargeText
        marginBottom={10}
        fontSize={width < 600 ? '1.4rem' : width < tabletWidth ? '1.9rem' : width < laptopWidth ? '2.2rem' : '2.8rem'}
        fontWeight={700}
        color={width <= 768 ? colors.white : colors.mineShaft}
      >
        {t('YD_APP_BRANDED.createYourBrand')}
      </LargeText>
      <ContentPiece
        width={width}
        tabletWidth={tabletWidth}
        laptopWidth={laptopWidth}
        Icon={<CheckRoundedIcon fontSize="large" htmlColor={colors.lightningYellow} />}
        title={t('YD_APP_BRANDED.withoutWriting')}
        text={t('YD_APP_BRANDED.youDontNeedSkill')}
      />
      <ContentPiece
        width={width}
        tabletWidth={tabletWidth}
        laptopWidth={laptopWidth}
        Icon={<CheckRoundedIcon fontSize="large" htmlColor={colors.lightningYellow} />}
        title={t('YD_APP_BRANDED.chooseYourInferface')}
        text={t('YD_APP_BRANDED.customizeAppearence')}
      />
      <ContentPiece
        width={width}
        tabletWidth={tabletWidth}
        laptopWidth={laptopWidth}
        Icon={<CheckRoundedIcon fontSize="large" htmlColor={colors.lightningYellow} />}
        title={t('YD_APP_BRANDED.youCanRequestIt')}
        text={t('YD_APP_BRANDED.allTheData')}
      />
    </>
  );
};

export default Content;
