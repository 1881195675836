import React from 'react';
import { colors } from '@guryou/colors';
import { FlexColumn, FlexRow, MediumText, SmallText } from '@guryou/html-components';

const ContentPiece = ({ width, tabletWidth, laptopWidth, Icon, title, text }) => {
  return (
    <FlexRow margin={0} alignItems="center" marginBottom={5} xs={12}>
      {width > 350 && Icon}
      <FlexColumn xs={10} margin={0} alignItems="start" paddingLeft={width < 600 ? 2 : 10}>
        <MediumText
          color={width <= tabletWidth ? colors.white : colors.mineShaft}
          fontSize={width < 600 ? '1rem' : width < tabletWidth ? '1.2rem' : width < laptopWidth ? '1.3rem' : '1.4rem'}
          fontWeight={700}
        >
          {title}
        </MediumText>
        <SmallText color={width <= tabletWidth ? colors.white : colors.mineShaft} fontSize={width < laptopWidth ? '1rem' : '1.1rem'} fontWeight={500}>
          {text}
        </SmallText>
      </FlexColumn>
    </FlexRow>
  );
};

export default ContentPiece;
