import { makeStyles } from '@material-ui/core';
import gyNewHeroImage from '../assets/img/gyNewHeroImage.jpg';

const useSecondSlideStyle = makeStyles(() => ({
  slide: {
    opacity: '0',
    position: 'absolute',
    width: '100%',
    top: '111px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  rightContent: {
    width: '100%',
    display: 'flex',
    alignItems: 'end',
  },
  logoFeel: {
    width: '80%',
  },
  image: {
    width: '40%',
    borderRadius: '10%',
  },
  background: {
    position: 'absolute',
    top: '7vh',
    left: '0',
    zIndex: '-2',
    opacity: '0',
    backgroundImage: `url('${gyNewHeroImage}')`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'left top',
    height: '93vh',
    width: '70%',
  },
}));
export default useSecondSlideStyle;
