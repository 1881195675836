import React from 'react';
import { useSelector } from 'react-redux';
import { FlexColumn, MediumText, SmallText } from '@guryou/html-components';

const tabletWidth = 900;

const Category = ({ color, image, title, text }) => {
  const { width } = useSelector(state => state.windowSize);
  return (
    <FlexColumn xs={width < tabletWidth ? 12 : width < 1200 ? 6 : 4} textAlign="center" padding={10}>
      <img src={image} alt="logo" width="70px" />
      <MediumText color={color} marginTop={3} fontSize="1.2rem">
        {title}
      </MediumText>
      <SmallText marginTop={3} fontSize="1.1rem" fontWeight={400}>
        {text}
      </SmallText>
    </FlexColumn>
  );
};

export default Category;
