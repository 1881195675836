import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams, withRouter } from 'react-router-dom';
import { colors } from '@guryou/colors';
import {
  ContainedButton,
  FlexColumn,
  FlexRow,
  Form,
  FormActions,
  MediumText,
  PaperBlock,
  PasswordBox,
  SmallText,
  Spinner,
} from '@guryou/html-components';
import { cognitoSetNewPassword, stateIsLoaded, StateStatus } from '@guryou/redux';
import { cognitoPasswordRequirements } from '@guryou/utils';
import { persistor } from 'store';

const defaultState = {
  password: '',
  confirmPassword: '',
};

const ResetPassword = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const [ls, setLs] = useState(defaultState);
  const setNewPassword = useSelector(state => state.cognito.setNewPassword);
  const { email, code } = useParams();

  if (setNewPassword.status === StateStatus.LOADING) {
    return <Spinner big={true} />;
  }

  const handleInputsChange = (value, e, input) => {
    e.persist();
    setLs(prevState => ({ ...prevState, [input]: value }));
  };
  const handleSubmit = async data => {
    const { newPassword } = data;
    dispatch(cognitoSetNewPassword({ newPassword, email, code }, persistor));
  };

  const passwordStrength = cognitoPasswordRequirements(ls.password);
  const checkIfPasswordMatch = ls.password === ls.confirmPassword;

  return (
    <FlexRow xs={12} alignItems="flex-start" height="100vh" paddingTop="8vh" backgroundColor={colors.mineShaft} margin={0}>
      <PaperBlock xs={11} md={4} lg={4} margin="0 auto" textAlign="center">
        <Form className="login" onSubmit={handleSubmit} actions={false}>
          <div className="logoBg"></div>
          {stateIsLoaded(setNewPassword) ? (
            <>
              <MediumText textAlign="center">{t('NOTIFICATIONS.successChangedPass')}</MediumText>
            </>
          ) : (
            <>
              <MediumText textAlign="center">{t('CONFIRMATIONS.enterYourNewPassword')}</MediumText>
              <FlexColumn alignItems="center">
                <PasswordBox
                  onChange={(value, e) => handleInputsChange(value, e, 'password')}
                  name="newPassword"
                  label={t('GENERAL.password')}
                  autoComplete="current-password"
                  required
                />
                <PasswordBox
                  onChange={(value, e) => handleInputsChange(value, e, 'confirmPassword')}
                  name="confirmPassword"
                  label={t('PROFILE.confirmPass')}
                  autoComplete="current-password"
                  required
                />
              </FlexColumn>
            </>
          )}
          <FormActions>
            {stateIsLoaded(setNewPassword) ? (
              <ContainedButton
                color="primary"
                text={`${t('GENERAL.backToLogin')}`}
                onClick={e => {
                  e.preventDefault();
                  history.push(`${process.env.REACT_APP_DEAL_URL}/login`);
                }}
              />
            ) : (
              <ContainedButton disabled={!!passwordStrength || !checkIfPasswordMatch} color="primary" type="submit" text={t('GENERAL.save')} />
            )}
          </FormActions>
          <SmallText color="red" padding={2}>
            {setNewPassword.error && setNewPassword.error.message}
          </SmallText>
          <SmallText padding={2} color="red">
            {passwordStrength && passwordStrength}
          </SmallText>
          <SmallText padding={2} color="red">
            {ls.password.length >= 8 && ls.confirmPassword && !checkIfPasswordMatch && t('ACCOUNT.newAndConfirmPassDontMatch')}
          </SmallText>
        </Form>
      </PaperBlock>
    </FlexRow>
  );
};

export default withRouter(ResetPassword);
