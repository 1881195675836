import React from 'react';
import { MediumText } from '@guryou/html-components';

const DotText = ({ text, width, marginTop }) => {
  return (
    <MediumText width="200px" marginTop={width < 1100 ? marginTop : 0} fontSize="1.2rem" textAlign={width < 1100 ? 'left' : 'center'}>
      {text}
    </MediumText>
  );
};

export default DotText;
