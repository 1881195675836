export const CardInfo = [
  {
    id: 0,
    title: 'Prenota',
    content:
      'I migliori esperti, le migliori palestre e centri benessere vicino a te sono su GurYou! Crea subito il tuo calendario benessere settimanale ',
  },
  {
    id: 1,
    title: 'Leggi',
    content:
      'Il benessere si coltiva ogni giorni in ogni momento. Qui trovi tutto quello che devi sapere per mantenerti in forma, anche dopo la tua consueta sessione in palestra',
  },
  {
    id: 2,
    title: 'Video',
    content:
      'Benessere anche a distanza. I migliori servizi wellness sono con te a casa e ovunque tu lo desideri. Scopri i nostri tutorial per mantenerti in forma',
  },
];
