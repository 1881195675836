import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { colors } from '@guryou/colors';
import { FlexColumn, LargeText } from '@guryou/html-components';
import { handleAnalyticsClick } from 'analytics';
import HeroImageFeel from 'assets/img/mobileFeelHero.jpg';
import Background from '../../components/Background';
import FindOutBtn from '../deal/content/FindOutBtn';
const Feel = ({ width }) => {
  const { t, i18n } = useTranslation();
  return (
    <FlexColumn position="relative" xs={12} height={width < 600 ? '600px' : '850px'}>
      <Background width={width} color={colors.mandy} image={HeroImageFeel} backgroundPosition="right" />
      <FlexColumn
        alignItems={width < 600 ? 'center' : 'start'}
        width={width < 600 ? '' : width < 800 ? '500px' : '590px'}
        marginTop={15}
        xs={width < 600 ? 11 : width < 800 ? 9 : 6.5}
        margin={0}
        left={width < 600 ? '' : '30px'}
        position="absolute"
        paddingBottom={2}
      >
        <LargeText
          textAlign={width < 600 ? 'center' : 'left'}
          color={colors.mineShaft}
          fontSize={width < 600 ? '2rem' : width < 800 ? '2.2rem' : '2.5rem'}
          fontWeight={700}
          lineHeight="1.1"
          marginBottom={10}
        >
          {t('SLIDER.slide2Text')}
        </LargeText>
        <Link
          to={`/${i18n.language}/youdeal`}
          onClick={() =>
            handleAnalyticsClick('Second slide', 'Button clicked!', 'Find out button', 'Lead', 'Find out button on second slide clicked!')
          }
        >
          <FindOutBtn width={width} text={t('GENERAL.findHow')} color={colors.mineShaft} textColor={colors.mandy} />
        </Link>
      </FlexColumn>
    </FlexColumn>
  );
};

export default Feel;
