import React from 'react';
import HubspotForm from 'react-hubspot-form';
import { useTranslation } from 'react-i18next';
import { FlexColumn, LargeText, MediumText, Spinner } from '@guryou/html-components';
import { handleAnalyticsClick } from 'analytics';

const ContactForm = ({ width, tabletWidth }) => {
  const { t } = useTranslation();
  return (
    <FlexColumn marginTop={10} marginBottom={20}>
      <LargeText fontWeight={900} fontSize={width < 500 ? '1.5rem' : width < tabletWidth ? '12rem' : '2.7rem'} textAlign="center" marginBottom={2}>
        {t('CONTACT_US.doYouNeedInfo')}
      </LargeText>
      <MediumText fontSize={width < 500 ? '1.0rem' : '1.4rem'} fontWeight={400} textAlign={width < tabletWidth ? 'center' : ''} marginBottom={10}>
        {t('CONTACT_US.enterYourDetails')}
      </MediumText>
      {/* for the form below to work, https://github.com/escaladesports/react-hubspot-form/issues/18, manually adding jquery was used as solution */}
      <HubspotForm
        portalId={process.env.REACT_APP_HUBSPOT_FORMS_PORTAL_ID}
        formId={process.env.REACT_APP_HUBSPOT_FEEL_FORM_ID}
        onSubmit={() => handleAnalyticsClick('YouFeel', 'Form submited!', 'Info user form', 'Contact', 'Info about user on YouFeel submited!')}
        loading={<Spinner />}
      />
    </FlexColumn>
  );
};

export default ContactForm;
