import React from 'react';
import { Slide } from 'pure-react-carousel';
import CenterCard from './CenterCard';

const CarouselSlide = ({ width, data }) => {
  const slidesData = data.slice(Math.max(data.length - 10, 1));
  return slidesData.map(
    (card, index) =>
      index < 10 &&
      card.images && (
        <Slide index={card.id} key={index}>
          <CenterCard
            key={card.id}
            title={card.name}
            address={card.address}
            image={card.images[0].medium}
            description={card.description}
            width={width}
          />
        </Slide>
      )
  );
};

export default CarouselSlide;
