var loadingAnimations = {
  '.start': {
    opacity: 1,
    animation: 'loadingData 2s infinite'
  },
  '.stop': {
    opacity: 1,
    animation: 'stopLoadingData 0.5s forwards'
  },
  '@keyframes loadingData': {
    '0%': {
      transform: 'scaleX(0)'
    },
    '55%': {
      transform: 'scaleX(1)'
    },
    '100%': {
      transform: 'translate(100%)'
    }
  },
  '@keyframes stopLoadingData': {
    '0%': {
      transform: 'scaleY(1)'
    },
    '100%': {
      transform: 'scaleY(0)',
      opacity: 0
    }
  }
};
export { loadingAnimations };