import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { colors } from '@guryou/colors';
import { Block, FlexColumn, FlexRow, Image, LargeText, MediumText, PaperBlock, Spinner } from '@guryou/html-components';
import { getProviderGeneralInformation, getProviderLatestWidget, stateIsLoaded, stateIsNotInitialized } from '@guryou/redux';
import Chip from '@material-ui/core/Chip';
import LandingFooter from 'components/landingFooter';
import Carousel from './Carousel';
import CarouselSlide from './ProviderImagesCarousel';

const tabletWidth = 900;

const Provider = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const params = useParams();
  const { width } = useSelector(state => state.windowSize);

  const providerDetails = useSelector(state => state.generalInfo);
  const widget = useSelector(state => state.providerLatestWidget);

  const i18nCheck = t('GENERAL.actions');

  useEffect(() => {
    if (['Actions', 'Azioni'].includes(i18nCheck)) {
      if (stateIsNotInitialized(providerDetails)) {
        dispatch(getProviderGeneralInformation(true, +params.id));
      }
    }
    //eslint-disable-next-line
  }, [i18nCheck]);

  useEffect(() => {
    if (stateIsNotInitialized(widget)) {
      dispatch(getProviderLatestWidget(+params.id));
    }
    //eslint-disable-next-line
  }, []);

  if (!stateIsLoaded(providerDetails) || !stateIsLoaded(widget)) {
    return <Spinner />;
  }

  const widgetFrame = widget?.data ? (
    <iframe title="BOOK" style={{ width: '100%', height: '100%' }} src={`${process.env.REACT_APP_WIDGET}${widget?.data?.id}`}></iframe>
  ) : null;

  return (
    <>
      <Block height={200} backgroundColor={colors.mineShaft} margin={0} position="absolute" zIndex={-1}></Block>
      <Block xs={width < tabletWidth ? 11 : 8} margin="auto" height={110}>
        <LargeText
          fontSize={width < tabletWidth ? '1.6rem' : '2.5rem'}
          fontWeight={700}
          color={colors.white}
          marginTop={12}
          marginLeft={2}
          textShadow="3px 3px 3px rgba(0,0,0,0.2)"
        >
          {providerDetails?.data?.name}
        </LargeText>
      </Block>
      <FlexColumn xs={width < tabletWidth ? 10 : 8} boxShadow="0 0 10px rgba(0,0,0,0.3)" position="relative" marginBottom={15} marginTop={2}>
        <Block maxHeight={500} margin={0}>
          <Image
            height="auto"
            xs={12}
            src={providerDetails?.data?.images[0]?.xLarge || providerDetails?.data?.images[0]?.large || providerDetails?.data?.images[0]?.medium}
            verticalAlign="middle"
            objectFit="cover"
          />
        </Block>
        <PaperBlock display="flex" flexDirection={width < 650 ? 'column' : 'row'} justifyContent="space-between" xs={12} padding={2}>
          <FlexColumn xs={width < 650 ? 12 : 6}>
            <Block xs={12}>
              <MediumText>{t('PROFILE.categories')}</MediumText>
            </Block>
            <FlexRow xs={12}>
              {providerDetails.data?.categories &&
                providerDetails.data?.categories.length &&
                providerDetails.data?.categories.map((x, i) => (
                  <Block width="fit-content" key={i}>
                    <Chip label={x.name} size="medium" color="secondary" />
                  </Block>
                ))}
            </FlexRow>
          </FlexColumn>
          <FlexColumn
            xs={width < 650 ? 12 : 6}
            marginLeft="auto"
            padding={1}
            alignItems={width < 650 ? 'flex-start' : 'flex-end'}
            padddingTop={2}
            paddingRight={2}
          >
            <MediumText fontWeight="700" textAlign={width < 650 ? 'left' : 'right'} fontSize="1.3rem">
              {t('GENERAL.address')}: {providerDetails.data?.address}
            </MediumText>
            <FlexRow justifyContent={width < 650 ? 'flex-start' : 'flex-end'}>
              {providerDetails.data?.email && (
                <Block width="fit-content">
                  <Chip label={providerDetails.data?.email} size="medium" color="secondary" />
                </Block>
              )}
              {providerDetails.data?.phone && (
                <Block width="fit-content">
                  <Chip label={providerDetails.data?.phone} size="medium" color="secondary" />
                </Block>
              )}
            </FlexRow>
          </FlexColumn>
        </PaperBlock>

        <PaperBlock xs={12} marginBot={2}>
          <Carousel CarouselSlide={<CarouselSlide images={providerDetails.data?.images} />} totalSlides={10} width={width} />
        </PaperBlock>

        <PaperBlock xs={12} textAlign="center" padding={2}>
          <MediumText>{providerDetails.data?.description}</MediumText>
        </PaperBlock>
        {widgetFrame && (
          <PaperBlock xs={12} padding={2} height="500px">
            <MediumText textAlign="center" font-size="1.75rem" marginBottom={4}>
              {t('FOOTER.book')}
            </MediumText>
            {widgetFrame}
          </PaperBlock>
        )}
      </FlexColumn>
      <LandingFooter width={width} tabletWidth={tabletWidth} color={colors.mineShaft} textColor={colors.white} />
    </>
  );
};

export default Provider;
