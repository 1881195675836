import { WINDOW_RESIZE } from '../types';

var windowSize = function windowSize() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {
    width: null,
    height: null
  };
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case WINDOW_RESIZE:
      return {
        width: action.payload.width,
        height: action.payload.height
      };

    default:
      return state;
  }
};

export { windowSize };