import { Action } from '../core/Action';
var RANDOM_PROVIDERS = {
  entity: 'RANDOM_PROVIDERS',
  action: Action.GET
};
var PROVIDER_DETAILS = {
  entity: 'PROVIDER_DETAILS',
  action: Action.GET
};
var SEARCH_PROVIDERS = {
  entity: 'SEARCH_PROVIDERS',
  action: Action.GET
};
var CLASS_DETAILS = {
  entity: 'CLASS_DETAILS',
  action: Action.GET
};
var ALL_CATEGORIES = {
  entity: 'ALL_CATEGORIES',
  action: Action.GET
};
var ALL_CITITES = {
  entity: 'ALL_CITITES',
  action: Action.GET
};
var SESSIONS_FOR_DAY = {
  entity: 'SESSIONS_FOR_DAY',
  action: Action.GET
};
export { RANDOM_PROVIDERS, PROVIDER_DETAILS, SEARCH_PROVIDERS, CLASS_DETAILS, ALL_CATEGORIES, ALL_CITITES, SESSIONS_FOR_DAY };