import React from 'react';
import { colors } from '@guryou/colors';
import { Block } from '@guryou/html-components';

const Background = ({ width, tabletWidth, isRiminiOrDeal }) => {
  return (
    <Block
      borderTop={width < tabletWidth ? '' : `876px solid ${isRiminiOrDeal ? colors.rimini : colors.mineShaft}`}
      borderRight={width < tabletWidth ? '' : '300px solid transparent'}
      width={width < tabletWidth ? '100%' : '55%'}
      height={width < tabletWidth ? '1050px' : ''}
      background={
        width < 400
          ? `linear-gradient(160deg, ${isRiminiOrDeal ? colors.rimini : colors.mineShaft}, ${
              isRiminiOrDeal ? colors.rimini : colors.mineShaft
            } 70%, transparent calc(70% + 1px), transparent)`
          : width < tabletWidth
          ? `linear-gradient(160deg, ${isRiminiOrDeal ? colors.rimini : colors.mineShaft}, ${
              isRiminiOrDeal ? colors.rimini : colors.mineShaft
            } 60%, transparent calc(60% + 1px), transparent)`
          : ''
      }
      position="absolute"
      top="0px"
      left="0px"
      zIndex={-1}
      margin={0}
    />
  );
};

export default Background;
