import { Action } from '../core';
var CALENDAR_EVENTS = {
  entity: 'CALENDAR_EVENTS',
  action: Action.GET_MANY
};
var CALENDAR_FILTER_GET_SERVICES = {
  entity: 'CALENDAR_FILTER_SERVICES',
  action: Action.GET_MANY
};
var CALENDAR_FILTER_GET_COURSES = {
  entity: 'CALENDAR_FILTER_COURSES',
  action: Action.GET_MANY
};
var CALENDAR_FILTER_GET_EVENTS = {
  entity: 'CALENDAR_FILTER_EVENTS',
  action: Action.GET_MANY
};
var CALENDAR_FILTER_GET_SUBSERVICES = {
  entity: 'CALENDAR_FILTER_SUBSERVICES',
  action: Action.GET_MANY
};
var CALENDAR_FILTER_GET_CLASSES = {
  entity: 'CALENDAR_FILTER_CLASSES',
  action: Action.GET_MANY
};
var ORDER_EVENTS = {
  entity: 'ORDER_EVENTS',
  action: Action.GET_MANY
};
var ORDER_EVENT_CREATE = {
  entity: 'ORDER_EVENT',
  action: Action.CREATE
};
export { ORDER_EVENT_CREATE, ORDER_EVENTS, CALENDAR_EVENTS, CALENDAR_FILTER_GET_SERVICES, CALENDAR_FILTER_GET_COURSES, CALENDAR_FILTER_GET_EVENTS, CALENDAR_FILTER_GET_SUBSERVICES, CALENDAR_FILTER_GET_CLASSES };