import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { colors } from '@guryou/colors';
import { FlexColumn, FlexRow, Image, MediumText } from '@guryou/html-components';
import AppStore from '@guryou/html-components/src/assets/app-store.png';
import GooglePlay from '@guryou/html-components/src/assets/google-play.png';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DealLogo from '../assets/img/guryouIcons/gyIcon37.png';
import FeelLogo from '../assets/img/guryouIcons/gyIcon43.png';
import StartLogo from '../assets/img/youstartLogo.png';

const DownloadTheAppModal = props => {
  const { onClose, open } = props;
  const { width } = useSelector(state => state.windowSize);
  const { t } = useTranslation();
  const handleClose = () => {
    onClose();
  };

  return (
    <Dialog
      onClose={handleClose}
      disableScrollLock={true}
      aria-labelledby="simpleDialog1"
      open={open}
      PaperProps={{
        style: { width: '50%', maxWidth: width < 900 ? '300px' : '500px', minWidth: '250px', minHeight: width < 800 ? '550px' : '400px' },
      }}
    >
      <DialogTitle id="titleDeal" backgroundColor={colors.mineShaft}>
        <MediumText xs={12} textAlign="center" color={colors.light1}>
          {t('NAVBAR.downloadApp')}
        </MediumText>
      </DialogTitle>
      <FlexColumn backgroundColor={colors.light1} xs={12} marginTop={4} overflowY="initial">
        <FlexRow justifyContent="center" alignItems="center">
          <Image width="60px" height="60px" src={DealLogo} />
          <MediumText color={colors.mineShaft} display="flex" fontSize="2.2rem" alignItems="center">
            YouDeal
          </MediumText>
        </FlexRow>
        <Wrapper size={width < 900 ? true : false}>
          <a rel="noopener noreferrer" target="_blank" href={process.env.REACT_APP_APPLE_STORE_DEAL}>
            <Image marginTop={2} width="200px" src={AppStore} />
          </a>

          <a rel="noopener noreferrer" target="_blank" href={process.env.REACT_APP_ANDROID_STORE_DEAL}>
            <Image marginTop={2} width="200px" src={GooglePlay} />
          </a>
        </Wrapper>
      </FlexColumn>
      <FlexColumn backgroundColor={colors.ligh1} xs={12} marginBottom={4} marginTop={4} overflowY="initial">
        <FlexRow justifyContent="center" alignItems="center">
          <Image width="60px" height="60px" src={FeelLogo} />
          <MediumText color={colors.mandy} display="flex" fontSize="2.2rem" alignItems="center">
            YouFeel
          </MediumText>
        </FlexRow>
        <Wrapper size={width < 900 ? true : false}>
          <a rel="noopener noreferrer" target="_blank" href={process.env.REACT_APP_APPLE_STORE_FEEL}>
            <Image marginTop={2} width="200px" src={AppStore} />
          </a>
          <a rel="noopener noreferrer" target="_blank" href={process.env.REACT_APP_ANDROID_STORE_FEEL}>
            <Image marginTop={2} width="200px" src={GooglePlay} />
          </a>
        </Wrapper>
      </FlexColumn>
      <FlexColumn backgroundColor={colors.ligh1} xs={12} marginBottom={4} overflowY="initial">
        <FlexRow justifyContent="center" alignItems="center">
          <Image width="60px" height="60px" src={StartLogo} />
          <MediumText color={colors.fruitSalad} display="flex" fontSize="2.2rem" alignItems="center">
            YouStart
          </MediumText>
        </FlexRow>
        <Wrapper size={width < 900 ? true : false}>
          <a rel="noopener noreferrer" target="_blank" href={process.env.REACT_APP_APPLE_STORE_START}>
            <Image marginTop={2} width="200px" src={AppStore} />
          </a>
          <a rel="noopener noreferrer" target="_blank" href={process.env.REACT_APP_ANDROID_STORE_START}>
            <Image marginTop={2} width="200px" src={GooglePlay} />
          </a>
        </Wrapper>
      </FlexColumn>
    </Dialog>
  );
};

export default DownloadTheAppModal;

const Wrapper = ({ children, size }) =>
  size ? (
    <FlexColumn xs={12}>{children}</FlexColumn>
  ) : (
    <FlexRow marginTop={5} xs={12} justifyContent="space-around" alignItems="center">
      {children}
    </FlexRow>
  );
