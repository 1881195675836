import { Action } from '../core/Action';
var USER_EMAIL_GET = {
  entity: 'USER_EMAIL',
  action: Action.GET
};
var USER_UPDATE = {
  entity: 'USER',
  action: Action.UPDATE
};
var USERS_CREATE = {
  entity: 'USERS',
  action: Action.CREATE
};
var USERS_FILTER = {
  entity: 'USERS',
  action: Action.GET
};
var USERS_FILTER_RESET = {
  entity: 'USERS',
  action: Action.RESET
};
var USERS_GET_MANY = {
  entity: 'USERS',
  action: Action.GET_MANY
};
var USERS_POST = {
  entity: 'USERS',
  action: Action.CREATE
};
var USERS_STAFF_GET = {
  entity: 'USERS_STAFF',
  action: Action.GET
};
var USERS_STATUS = {
  entity: 'USERS_STATUS',
  action: Action.HANDLE
};
var USER_ALL_ORDERS = {
  entity: 'USER_ALL_ORDERS',
  action: Action.GET
};
export { USERS_STATUS, USERS_GET_MANY, USERS_CREATE, USER_EMAIL_GET, USER_UPDATE, USERS_POST, USERS_STAFF_GET, USERS_FILTER, USERS_FILTER_RESET, USER_ALL_ORDERS };