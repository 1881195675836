import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { colors } from '@guryou/colors';
import { FlexColumn, LargeText } from '@guryou/html-components';
import { handleAnalyticsClick } from 'analytics';
import { motion } from 'framer-motion';
import FourthSlideStyle from '../../makeStyles/FourthSlideStyle';
import FindOutBtn from './firstSlideContent/FindOutBtn';

const FourthSlide = ({ fourthSlideVisible, width }) => {
  const { t, i18n } = useTranslation();
  const classes = FourthSlideStyle();
  const slideAnimation = {
    opacity: fourthSlideVisible ? 1 : 0,
  };
  const slideTransition = {
    duration: 0.8,
    delay: 0.2,
  };
  const contentTransition = {
    duration: 0.8,
    delay: fourthSlideVisible ? 0.4 : 0,
  };
  const contentAnimation = {
    display: fourthSlideVisible ? 'block' : 'none',
  };
  return (
    <motion.div className={classes.slide} animate={slideAnimation} transition={slideTransition}>
      <motion.div className={classes.rightContent} animate={contentAnimation} transition={contentTransition}>
        <FlexColumn
          width="45%"
          marginLeft="auto"
          textAlign="center"
          margin={0}
          marginTop="21vh"
          alignItems={width < 1200 ? 'center' : 'start'}
          justifyContent="flex-end"
        >
          <LargeText
            width="95%"
            color={colors.mineShaft}
            fontSize={width < 1350 ? '2.4rem' : '3.4rem'}
            fontWeight={900}
            lineHeight="1.1"
            marginBottom={10}
          >
            {t('SLIDER.slide4Text')}
          </LargeText>
          <Link
            style={{ margin: '0 auto', marginLeft: '18.3rem' }}
            to={`/${i18n.language}/youdeal`}
            onClick={() =>
              handleAnalyticsClick('Second slide', 'Button clicked!', 'Find out button', 'Lead', 'Find out button on second slide clicked!')
            }
          >
            <FindOutBtn text={t('GENERAL.findHow')} color={colors.mineShaft} textColor={colors.mandy} />
          </Link>
        </FlexColumn>
      </motion.div>
    </motion.div>
  );
};

export default FourthSlide;
