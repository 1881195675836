import React from 'react';
import { FlexColumn, Image } from '@guryou/html-components';

const SectionImage = ({ image }) => {
  return (
    <FlexColumn xs={12} md={6} padding={10}>
      <Image src={image} backgroundRepeat="no-repeat" backgroundSize="cover" backgroundPosition="left top" borderRadius="25px"></Image>
    </FlexColumn>
  );
};

export default SectionImage;
