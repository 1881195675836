import { defaultPalette } from '@guryou/colors';
import { spacer, TABLE_ROW_HEIGHT } from '../constants';
var tableOverrides = {
  MUIDataTable: {
    paper: {
      boxShadow: 'none',
      '& .MuiToolbar-regular': {
        minHeight: 'unset'
      }
    }
  },
  MUIDataTableBodyCell: {
    root: {
      '&:focus': {
        backgroundColor: 'red'
      }
    }
  },
  MUIDataTableBodyRow: {
    root: {
      '&.active': {
        backgroundColor: defaultPalette.grey[200]
      }
    }
  },
  MUIDataTableToolbar: {
    root: {
      display: 'none !important'
    }
  },
  MuiTableRow: {
    root: {
      height: TABLE_ROW_HEIGHT
    }
  },
  MuiTableCell: {
    root: {
      padding: spacer.spacer1,
      '&.small': {
        width: spacer.spacer3
      },
      '&.medium': {
        width: 5 * spacer.spacer6
      },
      '&.center': {
        textAlign: 'center'
      },
      '&.right': {
        textAlign: 'right'
      },
      '& .MuiSwitch-root .Mui-checked + .MuiSwitch-track': {
        backgroundColor: "".concat(defaultPalette.success.main, " !important"),
        opacity: "1 !important"
      }
    }
  }
};
export { tableOverrides };