import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { colors } from '@guryou/colors';
import { Block, FlexColumn, FlexRow, LargeText, MediumText, OutlinedButton, SmallText } from '@guryou/html-components';
import { printDate } from '@guryou/utils';

const tabletWidth = 900;

const HeroSection = ({ articles }) => {
  const { t, i18n } = useTranslation();
  const { width } = useSelector(state => state.windowSize);
  return (
    <FlexRow margin={0}>
      <Block
        margin={0}
        xs={12}
        height={650}
        display="flex"
        alignItems="center"
        backgroundImage={`url('${articles?.image?.url}')`}
        backgroundRepeat="no-repeat"
        backgroundSize="cover"
        backgroundPosition="center center"
      >
        <FlexColumn>
          <FlexColumn background="rgba(0, 0, 0, 0.6)" xs={width < 900 ? 11 : 8} padding={10} margin={0} color={colors.white} textAlign="center">
            <LargeText fontSize={width < tabletWidth ? '1.5rem' : '2rem'} fontWeight={600} color={colors.white} marginBottom={5}>
              {articles?.title}
            </LargeText>
            <MediumText fontSize={width < tabletWidth ? '0.9rem' : '1.4rem'} fontWeight={400} color={colors.white} marginBottom={5}>
              {articles?.description}
            </MediumText>
            <Link to={`/${i18n.language}/post/${articles?.Slug}`}>
              <OutlinedButton
                text={t('HOME_READ_PAGE.readMore')}
                minWidth="150px"
                color={colors.white}
                border-color={colors.white}
                borderWidth="2px"
              ></OutlinedButton>
            </Link>
          </FlexColumn>
          <FlexRow
            justifyContent="space-between"
            padding={0}
            paddingLeft={5}
            paddingRight={5}
            height={35}
            backgroundColor={colors.white}
            color={colors.white}
            margin={0}
            xs={width < 900 ? 11 : 8}
            textAlign="center"
            alignItems="center"
          >
            <SmallText fontSize="0.6rem">{`${t('PROFILE.date')}: ${printDate(Date())}`}</SmallText>
            <Link to={`/${i18n.language}/author/${articles?.author?.Slug}`}>
              <SmallText fontSize="0.6rem" textDecoration="underline" fontStyle="italic">
                {articles?.author?.display_name}
              </SmallText>
            </Link>
          </FlexRow>
        </FlexColumn>
      </Block>
    </FlexRow>
  );
};

export default HeroSection;
