"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.GenderTarget = exports.Gender = exports.UserTypes = void 0;
var UserTypes = {
  admin: 'admin',
  author: 'author',
  collaborator: 'collaborator',
  customer: 'customer',
  director: 'director',
  manager: 'manager',
  owner: 'owner'
};
exports.UserTypes = UserTypes;
var Gender = {
  male: 'M',
  female: 'F'
};
exports.Gender = Gender;
var GenderTarget = {
  all: 'all',
  male: 'male',
  female: 'female'
};
exports.GenderTarget = GenderTarget;