"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.isCalendarDayNotAvailable = exports.getReservationInitialDate = void 0;

var _dateTimeUtils = require("./dateTimeUtils");

var getReservationInitialDate = function getReservationInitialDate(eventDate, personnelDateSlots) {
  if (!personnelDateSlots.data || !eventDate) {
    return eventDate || new Date();
  }

  if ((0, _dateTimeUtils.dateIsBefore)(eventDate, personnelDateSlots.data.minDate, true)) {
    return new Date(personnelDateSlots.data.minDate);
  }

  if (typeof eventDate === 'string') {
    return new Date(eventDate);
  }

  if (typeof eventDate.toDate === 'function') {
    return eventDate.toDate();
  }

  return eventDate;
};

exports.getReservationInitialDate = getReservationInitialDate;

var isCalendarDayNotAvailable = function isCalendarDayNotAvailable(date, personnelDateSlots) {
  var weekDay = date.day();

  if (!personnelDateSlots.data.availableDays.includes(weekDay) || !(0, _dateTimeUtils.dateIsBetween)(date, personnelDateSlots.data.minDate, personnelDateSlots.data.maxDate)) {
    return true;
  }

  if (personnelDateSlots.data.holidays) {
    return personnelDateSlots.data.holidays.some(function (x) {
      return x.isWholeDay && (0, _dateTimeUtils.dateIsBetween)(date, x.startTime, x.endTime);
    });
  }

  return false;
};

exports.isCalendarDayNotAvailable = isCalendarDayNotAvailable;