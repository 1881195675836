import React from 'react';
import { useTranslation } from 'react-i18next';
import StatisticsCard from './StatisticsCard';

const Content = () => {
  const { t } = useTranslation();

  return (
    <>
      <StatisticsCard
        title={t('NEWHOME.analyticsFirstCardTitle')}
        number={t('NEWHOME.totalClients')}
        percentNumber={`${t('NEWHOME.precentClients')}%`}
        trending={true}
      />
      <StatisticsCard
        title={t('NEWHOME.analyticsSecondCardTitle')}
        number={t('NEWHOME.totalEvents')}
        percentNumber={`${t('NEWHOME.precentEvents')}%`}
        trending={true}
      />
      <StatisticsCard
        title={t('NEWHOME.analyticsThirdCardTitle')}
        number={t('NEWHOME.totalProviders')}
        percentNumber={`${t('NEWHOME.precentProviders')}%`}
        trending={true}
      />
    </>
  );
};

export default Content;
