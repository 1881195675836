import React from 'react';
import { useTranslation } from 'react-i18next';
import AboutUsCard from './AboutUsCard';

const Content = ({ width, tabletWidth, textColor }) => {
  const { t } = useTranslation();
  return (
    <>
      <AboutUsCard
        title={t('NEWHOME.aboutUsFirstTitle')}
        width={width}
        tabletWidth={tabletWidth}
        subTitle={t('NEWHOME.aboutUsFirstSubTitle')}
        text={t('NEWHOME.aboutUsFirstText')}
        rating={5}
        textColor={textColor}
      />
      <AboutUsCard
        title={t('NEWHOME.aboutUsSecondTitle')}
        width={width}
        tabletWidth={tabletWidth}
        subTitle={t('NEWHOME.aboutUsSecondSubTitle')}
        text={t('NEWHOME.aboutUsSecondText')}
        rating={5}
        textColor={textColor}
      />
    </>
  );
};

export default Content;
