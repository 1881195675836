import React from 'react';
import { LargeText } from '@guryou/html-components';
const FirstSlideTitle = ({ width, text, color, textAlign }) => {
  return (
    <LargeText
      width="100%"
      color={color}
      fontSize={width < 500 ? '1rem' : width < 1200 ? '1.4rem' : '1.3rem'}
      fontWeight={900}
      lineHeight="1.1"
      textAlign={textAlign ? textAlign : 'left'}
      marginBottom={5}
    >
      {text}
    </LargeText>
  );
};

export default FirstSlideTitle;
