import React from 'react';
import HubspotForm from 'react-hubspot-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { colors } from '@guryou/colors';
import { FlexColumn, LargeText, MediumText, Spinner } from '@guryou/html-components';
import { handleAnalyticsClick } from 'analytics';
import LandingFooter from 'components/landingFooter';
import AboutUs from './sections/aboutUs';
import Benefits from './sections/benefits';
import CreateYourAppBrand from './sections/createYourAppBrand';
import Hero from './sections/hero';
import PricingPlans from './sections/pricingPlans';

const tabletWidth = 900;
const laptopWidth = 1300;

const Deal = () => {
  const { t } = useTranslation();
  const { width } = useSelector(state => state.windowSize);
  const location = useLocation();

  const isRiminiOrDeal = location.pathname.includes('youdeal') || location.pathname.includes('rimini-wellness');

  return (
    <>
      <Hero width={width} tabletWidth={tabletWidth} laptopWidth={laptopWidth} />
      <Benefits width={width} tabletWidth={tabletWidth} laptopWidth={laptopWidth} />
      <CreateYourAppBrand width={width} tabletWidth={tabletWidth} laptopWidth={laptopWidth} />
      <PricingPlans width={width} tabletWidth={tabletWidth} laptopWidth={laptopWidth} />
      <AboutUs width={width} />
      <FlexColumn marginTop={10} marginBottom={20}>
        <LargeText fontWeight={900} fontSize={width < 500 ? '1.5rem' : width < tabletWidth ? '1.8rem' : '2.7rem'} textAlign="center" marginBottom={2}>
          {t('CONTACT_US.doYouNeedInfo')}
        </LargeText>
        <MediumText fontSize={width < 500 ? '1.0rem' : '1.4rem'} fontWeight={400} textAlign={width < tabletWidth ? 'center' : ''} marginBottom={10}>
          {t('CONTACT_US.enterYourDetails')}
        </MediumText>
        {/* for the form below to work, https://github.com/escaladesports/react-hubspot-form/issues/18, manually adding jquery was used as solution */}
        <HubspotForm
          portalId={process.env.REACT_APP_HUBSPOT_FORMS_PORTAL_ID}
          formId={process.env.REACT_APP_HUBSPOT_FEEL_FORM_ID}
          onSubmit={() => handleAnalyticsClick('YouDeal', 'Form submited!', 'Info user form', 'Contact', 'Info about user on YouDeal submited!')}
          loading={<Spinner />}
        />
      </FlexColumn>

      <LandingFooter width={width} tabletWidth={tabletWidth} color={isRiminiOrDeal ? colors.rimini : colors.mineShaft} textColor={colors.white} />
    </>
  );
};

export default Deal;
