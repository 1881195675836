import React from 'react';
import { useTranslation } from 'react-i18next';
import { colors } from '@guryou/colors';
import { FlexColumn, FlexRow, LargeText } from '@guryou/html-components';
import BenefitsBackground from 'components/BackgroundFeelDeal/BenefitsBackground';
import Content from './Content';
const Analytics = ({ width, tabletWidth, laptopWidth }) => {
  const { t } = useTranslation();

  return (
    <FlexColumn textAlign={width < 500 && 'center'} xs={12} position="relative">
      <BenefitsBackground rectangleColor={colors.light1} trapezoidColorUp={colors.light1} trapezoidColorDown={colors.light1} />
      <LargeText
        fontWeight={900}
        fontSize={width < 600 ? '1.5rem' : width < tabletWidth ? '2rem' : width < laptopWidth ? '2.4rem' : '2.7rem'}
        textAlign="center"
        marginBottom={10}
        marginTop={15}
        color={colors.mineShaft}
      >
        {t('NEWHOME.analyticsNumbers')}
      </LargeText>
      <FlexRow margin={0} marginTop={5} paddingBottom={15}>
        <Content />
      </FlexRow>
    </FlexColumn>
  );
};

export default Analytics;
