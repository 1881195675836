import { ActionStatus, buildActionType } from '../core';
import { CONFIRM_REGISTER_BIZ } from '../types';

var confirmRegisterBiz = function confirmRegisterBiz() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {
    items: [],
    loading: false,
    error: null
  };
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case buildActionType(CONFIRM_REGISTER_BIZ, ActionStatus.START):
      return {
        loading: true,
        items: [],
        error: null
      };

    case buildActionType(CONFIRM_REGISTER_BIZ, ActionStatus.DONE):
      return {
        loading: false,
        items: action.payload,
        error: null
      };

    case buildActionType(CONFIRM_REGISTER_BIZ, ActionStatus.FAILED):
      return {
        loading: false,
        items: [],
        error: action.payload
      };

    default:
      return state;
  }
};

export { confirmRegisterBiz };