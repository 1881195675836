import { defaultPalette } from '@guryou/colors';
var accordionOverrides = {
  MuiAccordion: {
    root: {
      '& .MuiAccordion-root': {
        backgroundColor: defaultPalette.grey[100]
      },
      '& .MuiCollapse-container': {
        padding: 0
      }
    }
  },
  MuiAccordionDetails: {
    root: {
      padding: 0
    }
  },
  MuiAccordionSummary: {
    root: {
      padding: '0 4px'
    },
    content: {
      '&.Mui-expanded': {
        margin: '4px 0'
      }
    }
  }
};
export { accordionOverrides };