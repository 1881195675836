import { DateLocalizer } from 'react-big-calendar';
import { PRINT_TIME_FORMAT, printDate, printTime } from '@guryou/utils';
import 'moment/locale/it';
import * as dates from './dates';

var dateRangeFormat = function dateRangeFormat(_ref) {
  var start = _ref.start,
      end = _ref.end;
  return "".concat(printDate(start), " - ").concat(printDate(end));
};

var timeRangeFormat = function timeRangeFormat(_ref2) {
  var start = _ref2.start,
      end = _ref2.end;
  return "".concat(printTime(start), " - ").concat(printTime(end));
};

var timeRangeStartFormat = function timeRangeStartFormat(_ref3) {
  var start = _ref3.start;
  return "".concat(printTime(start), " - ");
};

var timeRangeEndFormat = function timeRangeEndFormat(_ref4) {
  var end = _ref4.end;
  return " - ".concat(printTime(end));
};

var weekRangeFormat = function weekRangeFormat(_ref5, culture, local) {
  var start = _ref5.start,
      end = _ref5.end;
  return "".concat(local.format(start, 'MMMM DD', culture), " \u2013 ").concat(local.format(end, dates.eq(start, end, 'month') ? 'DD' : 'MMMM DD', culture));
};

export var formats = {
  dateFormat: 'DD',
  dayFormat: 'DD ddd',
  weekdayFormat: 'ddd',
  selectRangeFormat: timeRangeFormat,
  eventTimeRangeFormat: timeRangeFormat,
  eventTimeRangeStartFormat: timeRangeStartFormat,
  eventTimeRangeEndFormat: timeRangeEndFormat,
  timeGutterFormat: PRINT_TIME_FORMAT,
  monthHeaderFormat: 'MMMM YYYY',
  dayHeaderFormat: 'dddd MMM DD',
  dayRangeHeaderFormat: weekRangeFormat,
  agendaHeaderFormat: dateRangeFormat,
  agendaDateFormat: 'ddd MMM DD',
  agendaTimeFormat: PRINT_TIME_FORMAT,
  agendaTimeRangeFormat: timeRangeFormat
};

var gyLocalizer = function gyLocalizer(moment) {
  var locale = function locale(m, c) {
    return c ? m.locale(c) : m;
  };

  return new DateLocalizer({
    formats: formats,
    firstOfWeek: function firstOfWeek() {
      return 1;
    },
    format: function format(value, _format, culture) {
      return locale(moment(value), culture).format(_format);
    }
  });
};

export default gyLocalizer;