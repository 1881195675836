import { spacer } from '../constants';
var cardOverrides = {
  MuiCard: {
    root: {
      width: "calc(25% - ".concat(2 * spacer.spacer1, "px)"),
      //width.small,
      margin: spacer.spacer1,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between'
    }
  },
  MuiCardMedia: {
    root: {
      height: 0,
      paddingTop: '56.25%'
    }
  },
  MuiCardActions: {
    root: {
      paddingBottom: 0
    }
  },
  MuiCardHeader: {
    avatar: {
      '& img': {
        borderRadius: '100%',
        width: '50px',
        height: '50px',
        maxWidth: 'none'
      }
    }
  },
  MuiCardContent: {
    root: {
      padding: '12.5px',
      display: 'flex',
      '& svg': {
        marginRight: spacer.spacer1,
        height: '0.8em'
      }
    }
  },
  MuiTypography: {
    body2: {
      fontSize: '0.75rem'
    }
  }
};
export { cardOverrides };