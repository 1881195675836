import { ActionStatus, buildActionType } from '../core';

var resetState = function resetState(type) {
  return function (dispatch) {
    return dispatch({
      type: buildActionType(type, ActionStatus.RESET)
    });
  };
};

export { resetState };