import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { FlexColumn, FlexRow, LargeText, SmallText, Spinner } from '@guryou/html-components';
import { randomProvidersActionByKeywordOrAddress, stateIsLoaded } from '@guryou/redux';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import CardCarousel from 'components/carousel';
import CarouselSlide from './CarouselSlide';
import Filters from './Filters';

const defaultState = {
  type: null,
  keyword: null,
  address: null,
};

const Centers = ({ width }) => {
  const { t } = useTranslation();
  const [values, setValues] = useState(defaultState);

  const dispatch = useDispatch();

  const randomProviders = useSelector(state => state.randomProviders);

  useEffect(() => {
    dispatch(randomProvidersActionByKeywordOrAddress());
  }, [dispatch]);

  const handleSwitchChange = () => setValues(pv => ({ ...pv, type: !pv.type }));
  const handleKeywordChange = e => {
    e.persist();
    setValues(pv => ({ ...pv, keyword: e.target?.value }));
  };
  const handleAddressChange = address => setValues(pv => ({ ...pv, address }));

  const onSubmit = () => {
    const { type, keyword, address } = values;
    let city = null;
    let country = null;
    if (address?.address_components) {
      const localityCity = address?.address_components.find(x => x.types.includes('locality'));
      const localityCountry = address?.address_components.find(x => x.types.includes('country'));
      if (localityCity) {
        city = localityCity?.long_name;
      }
      if (localityCountry) {
        country = localityCountry.long_name;
      }
    }
    dispatch(randomProvidersActionByKeywordOrAddress(keyword, city, country, type === null ? null : type ? 'P' : 'C'));
  };

  return (
    <FlexColumn marginTop={width < 900 && 15} marginBottom={10}>
      <Filters
        width={width}
        onChange={handleSwitchChange}
        switchValue={values.type}
        handleKeywordChange={handleKeywordChange}
        handleAddressChange={handleAddressChange}
        onSubmit={onSubmit}
      />
      <FlexRow xs={11} justifyContent={width < 650 ? 'center' : 'space-between'} marginBottom={10} marginTop={width > 650 && 15} alignItems="center">
        <LargeText fontSize="2.7rem" fontWeight={900}>
          {t('YF_SEARCH.recommendedForYou')}
        </LargeText>
        {width < 650 ? (
          ''
        ) : (
          <SmallText fontSize={width < 1400 ? '1rem' : '1.5rem'}>
            {t('YF_SEARCH.lookingForCenter')} <ArrowForwardIcon style={{ verticalAlign: 'middle' }} />
          </SmallText>
        )}
      </FlexRow>
      {!stateIsLoaded(randomProviders) ? (
        <Spinner />
      ) : (
        <CardCarousel
          CarouselSlide={
            <CarouselSlide width={width} data={randomProviders.data?.response} type={values.type} noResult={t('GENERAL.noProvidersFound')} />
          }
          width={width}
          totalSlides={values.type === null ? 10 : 1000}
        />
      )}
    </FlexColumn>
  );
};

export default Centers;
