import { colors } from '@guryou/colors';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  topTrapezoidLeft: {
    borderLeft: '0 solid transparent',
    borderRight: '300px solid transparent',
    height: '0',
    width: '55%',
    minWidth: '950px',
    [theme.breakpoints.between(1200, 1350)]: {
      minWidth: '850px',
    },
    [theme.breakpoints.between(1000, 1200)]: {
      minWidth: '700px',
    },
    position: 'absolute',
    top: '0',
    left: '0',
    zIndex: '-1',
  },

  topTrapezoidRight: {
    borderLeft: '300px solid transparent',
    borderRight: '0px solid transparent',
    height: '0',
    width: '55%',
    minWidth: '950px',
    [theme.breakpoints.between(1200, 1350)]: {
      minWidth: '850px',
    },
    [theme.breakpoints.between(1000, 1200)]: {
      minWidth: '700px',
    },
    position: 'absolute',
    top: '0',
    right: '0',
    zIndex: '-1',
  },

  bottomTrapezoidLeft: {
    borderLeft: '0 solid transparent',
    borderRight: '5vw solid transparent',
    minWidth: '600px',
    width: '35vw',
    [theme.breakpoints.between(1200, 1350)]: {
      minWidth: '500px',
    },
    [theme.breakpoints.between(1000, 1200)]: {
      minWidth: '300px',
    },
    height: '0',
    position: 'absolute',
    bottom: '0',
    left: '0',
  },

  bottomTrapezoidLeftWrapper: {
    position: 'absolute',
    top: '90vh',
    height: '10.1vh',
    left: '0',
    marginTop: '-1px',
  },

  bottomTrapezoidRight: {
    borderLeft: '5vw solid transparent',
    borderRight: '0 solid transparent',
    minWidth: '600px',
    width: '35vw',
    [theme.breakpoints.between(1200, 1350)]: {
      minWidth: '500px',
    },
    [theme.breakpoints.between(1000, 1200)]: {
      minWidth: '300px',
    },
    height: '0',
    position: 'absolute',
    bottom: '0',
    right: '0',
  },

  bottomTrapezoidRightWrapper: {
    position: 'absolute',
    top: '90vh',
    height: '10.1vh',
    right: '0',
    marginTop: '-1px',
  },

  topRectangle: {
    height: '0',
    position: 'absolute',
    top: '0',
    right: '0',
    zIndex: '-1',
    backgroundColor: `${colors.mineShaft}`,
  },

  bottomRectangle: {
    height: '0',
    position: 'absolute',
    bottom: '0',
    backgroundColor: `${colors.mandy}`,
  },

  bottomRectangleWrapper: {
    position: 'absolute',
    top: '90vh',
    height: '10.1vh',
    width: '100%',
    left: '0',
    marginTop: '-1px',
  },

  sliderDots: {
    position: 'absolute',
    width: '200px',
    right: '85px',
    top: '94vh',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    zIndex: '500',
  },
  circleEmpty: {
    height: '25px',
    width: '25px',
    borderRadius: '50%',
    border: `2px solid ${colors.mandy}`,
    backgroundColor: 'transparent',
    cursor: 'pointer',
    '&:hover': { backgroundColor: `${colors.mandy}` },
  },

  circleFilled: {
    height: '25px',
    width: '25px',
    borderRadius: '50%',
    border: `2px solid ${colors.mandy}`,
    backgroundColor: `${colors.mandy}`,
    cursor: 'pointer',
  },
  circleEmptyBlack: {
    height: '25px',
    width: '25px',
    borderRadius: '50%',
    border: `2px solid ${colors.mineShaft}`,
    backgroundColor: 'transparent',
    cursor: 'pointer',
    '&:hover': { backgroundColor: `${colors.mineShaft}` },
  },

  circleFilledBlack: {
    height: '25px',
    width: '25px',
    borderRadius: '50%',
    border: `2px solid ${colors.mineShaft}`,
    backgroundColor: `${colors.mineShaft}`,
    cursor: 'pointer',
  },

  arrowLeftDiv: {
    position: 'absolute',
    top: '50vh',
    left: '0',
    zIndex: '500',
    cursor: 'pointer',
  },

  arrowRightDiv: {
    position: 'absolute',
    top: '50vh',
    right: '0',
    zIndex: '500',
    cursor: 'pointer',
  },

  arrowLeft: {
    width: '50px',
    height: '50px',
  },

  arrowRight: {
    width: '50px',
    height: '50px',
  },
}));

export default useStyles;
