import React from 'react';
import { FlexColumn, FlexRow, Image, MediumText } from '@guryou/html-components';
const Thing = ({ width, tabletWidth, icon, title, text }) => {
  return (
    <FlexColumn
      xs={width < 600 ? 12 : width < tabletWidth ? 6 : 6}
      alignItems="center"
      margin={0}
      marginBottom={10}
      paddingLeft={width < 600 ? 2 : width < tabletWidth ? 4 : 20}
      paddingRight={width < 600 ? 2 : width < tabletWidth ? 4 : 20}
      textAlign={width < tabletWidth ? 'center' : ''}
    >
      <FlexRow justifyContent={width <= 768 ? 'center' : 'start'} alignItems="center">
        <Image width="60px" height="60px" src={icon} marginLeft={-2} marginRight={2} />
        <MediumText fontSize="1.5rem" fontWeight={700}>
          {title}
        </MediumText>
      </FlexRow>

      <MediumText fontSize="1.3rem" fontWeight={400}>
        {text}
      </MediumText>
    </FlexColumn>
  );
};

export default Thing;
