import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { colors } from '@guryou/colors';
import { Spinner } from '@guryou/html-components';
import { getArticles, stateIsLoaded } from '@guryou/redux';
import LandingFooter from 'components/landingFooter';
import ArticleSection from './articleSection';
import HeroSection from './heroSection';
import ReadSection from './readSection';

const tabletWidth = 900;

const BlogArticles = () => {
  const { slug } = useParams();
  const { width } = useSelector(state => state.windowSize);

  const dispatch = useDispatch();
  const articles = useSelector(state => state.articles.articles);
  useEffect(() => {
    if (!stateIsLoaded(articles)) {
      dispatch(getArticles());
    }
    //eslint-disable-next-line
  }, [dispatch]);

  if (!stateIsLoaded(articles)) {
    return <Spinner />;
  }
  return (
    <>
      <HeroSection
        articles={slug === 'all' ? articles.data?.articles[0] : articles.data?.articles.filter(article => article?.category?.Slug === slug)[0]}
      />
      <ReadSection width={width} />
      <ArticleSection
        articles={slug === 'all' ? articles.data?.articles : articles.data?.articles.filter(article => article?.category?.Slug === slug)}
        width={width}
      />
      <LandingFooter width={width} tabletWidth={tabletWidth} color={colors.mandy} />
    </>
  );
};

export default BlogArticles;
