import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { Link as LinkScroll } from 'react-scroll';
import { colors } from '@guryou/colors';
import { FlexColumn, FlexRow, LinkButton, MediumText } from '@guryou/html-components';
import { handleAnalyticsClick } from 'analytics';
import HeroImage from 'assets/img/gyNewHeroImage.jpg';
import PhoneImage from 'assets/img/phone1youStart.png';
import RiminiImage from 'assets/img/RiminiImage.png';
import HeroBackground from 'components/BackgroundFeelDeal/HeroBackground';
import Description from './Description';
import Title from './Title';
import TitleBusinessDeal from './TitleBusinessDeal';
import HeroButton from './Button/HeroButton';

const Hero = ({ width, tabletWidth, laptopWidth }) => {
  const { t, i18n } = useTranslation();
  const location = useLocation();

  const isRouteBusinessDeal =
    location.pathname.includes('businessDeal') || location.pathname.includes('rimini-wellness') || location.pathname.includes('guryoudeal');
  const isRimini = location.pathname.includes('rimini-wellness') || location.pathname.includes('guryoudeal');
  const isGuryouDeal = location.pathname.includes('guryoudeal');

  return (
    <>
      <FlexColumn alignItems="flex-end">
        <Link to={`/${i18n.language}/youfeel`}>
          {width < 650
            ? ''
            : !isRouteBusinessDeal && (
                <MediumText
                  color={colors.white}
                  fontSize={width < tabletWidth ? '0.9rem' : width < laptopWidth ? '1rem' : '1.2rem'}
                  textDecoration="underline"
                  margin={5}
                >
                  {t('HERO.areYouLookingForAFitness')}
                </MediumText>
              )}
        </Link>
      </FlexColumn>
      <HeroBackground
        isBusiness={isRouteBusinessDeal}
        isRimini={isRimini}
        laptopWidth={laptopWidth}
        width={width}
        tabletWidth={tabletWidth}
        image={HeroImage}
        image2={PhoneImage}
        image3={RiminiImage}
        color={colors.mineShaft}
        isDeal={true}
      />
      <FlexColumn
        marginTop={10}
        xs={width < tabletWidth ? 12 : 11}
        alignItems="start"
        paddingLeft={5}
        paddingBottom={5}
        paddingRight={width < tabletWidth ? 5 : 0}
        marginLeft={width > 700 ? 10 : 0}
        height={width < 450 ? '650px' : isRimini ? '600px' : '750px'}
      >
        {!isRouteBusinessDeal && <Title width={width} laptopWidth={laptopWidth} color={colors.white} />}
        {isRouteBusinessDeal && (
          <TitleBusinessDeal isGuryouDeal={isGuryouDeal} isRimini={isRimini} width={width} laptopWidth={laptopWidth} color={colors.white} />
        )}
        {!isRimini && <Description width={width} laptopWidth={laptopWidth} />}

        <FlexRow>
          <LinkButton to={`/${i18n.language}/info`}>
            <HeroButton width={width} tabletWidth={tabletWidth} text={t('HERO.requestInfo')} />
          </LinkButton>
          {!isRouteBusinessDeal && (
            <LinkButton to={`/${i18n.language}/demo`}>
              <HeroButton width={width} tabletWidth={tabletWidth} text={t('HERO.requestADemo')} />
            </LinkButton>
          )}
          {isRimini && (
            <LinkScroll
              smooth={true}
              offset={-50}
              duration={1500}
              onClick={() =>
                handleAnalyticsClick('YouDeal', 'Button Clicked!', 'Request a Demo button', 'Contact', 'Request a Demo button on YouDeal clicked!')
              }
            >
              <HeroButton width={width} tabletWidth={tabletWidth} text={t('HERO.requestADemo')} />
            </LinkScroll>
          )}
        </FlexRow>
      </FlexColumn>
    </>
  );
};

export default Hero;
