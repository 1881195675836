import React from 'react';
import { colors } from '@guryou/colors';
import { StarIcon } from '@guryou/html-components';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import StarHalfIcon from '@material-ui/icons/StarHalf';

const Stars = ({ rating }) => {
  const getStars = () => {
    const stars = [];
    const fullStars = Math.trunc(rating);
    let otherStars = rating - fullStars;
    for (let i = 0; i < 5; i++) {
      if (fullStars > i) {
        stars.push(<StarIcon key={i} htmlColor={colors.rimini} />);
      } else {
        if (otherStars >= 0.5) {
          stars.push(<StarHalfIcon key={i} htmlColor={colors.rimini} />);
          otherStars = 0;
        } else {
          stars.push(<StarBorderIcon key={i} htmlColor={colors.rimini} />);
        }
      }
    }

    return stars;
  };

  return <>{getStars()}</>;
};

export default Stars;
