import React from 'react';
import { useSelector } from 'react-redux';
import { FlexColumn } from '@guryou/html-components';
import Blog from './sections/blog';
import Deal from './sections/deal';
import Feel from './sections/feel';
import Start from './sections/start';

const MobileHome = () => {
  const { width } = useSelector(state => state.windowSize);
  return (
    <FlexColumn>
      <Deal width={width} />
      <Feel width={width} />
      <Start width={width} />
      <Blog width={width} />
    </FlexColumn>
  );
};

export default MobileHome;
