import React from 'react';
import { useTranslation } from 'react-i18next';
import { SmallText } from '@guryou/html-components';
import './index.css';

const ToggleSwitch = ({
  width,
  marginTop = 0,
  marginLeft = 0,
  name,
  onChange,
  values = ['centers', 'professionals'],
  register = false,
  fontSize = '1rem',
  disabled = false,
  switchValue,
}) => {
  const { t } = useTranslation();
  let togWidth = '285px';
  if (width <= 350) {
    togWidth = '260px';
  }
  return (
    <div style={{ width: togWidth }}>
      <label className="switch" style={{ cursor: width < 900 ? 'none' : 'pointer', marginTop, marginLeft }}>
        <input type="checkbox" name={name} onChange={onChange} disabled={disabled} defaultChecked={switchValue} />
        <span className={`slider round ${register ? 'register' : ''}`}>
          {values.map((x, i) => (
            <SmallText key={i} fontWeight="500" fontSize={fontSize} className={`${register && 'register'} ${!i ? `text` : 'text2'}`}>
              {t(`GENERAL.${x}`)}
            </SmallText>
          ))}
        </span>
      </label>
    </div>
  );
};

export default ToggleSwitch;
