import { InMemoryCache } from 'apollo-cache-inmemory';
import { ApolloClient } from 'apollo-client';
import { HttpLink } from 'apollo-link-http';

const cache = new InMemoryCache();
const link = new HttpLink({
  uri: `https://admin.guryou.com/graphql`,
});
const client = new ApolloClient({
  cache,
  link,
});

export default client;
