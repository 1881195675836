"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.checkIfGrowOrAbove = exports.checkIfGrow = exports.checkIfVideo = exports.checkIfAllInOne = void 0;

var checkIfAllInOne = function checkIfAllInOne(providerInfo) {
  var _providerInfo$data;

  if (providerInfo !== null && providerInfo !== void 0 && (_providerInfo$data = providerInfo.data) !== null && _providerInfo$data !== void 0 && _providerInfo$data.plan) {
    var _providerInfo$data2, _providerInfo$data2$p;

    return (providerInfo === null || providerInfo === void 0 ? void 0 : (_providerInfo$data2 = providerInfo.data) === null || _providerInfo$data2 === void 0 ? void 0 : (_providerInfo$data2$p = _providerInfo$data2.plan) === null || _providerInfo$data2$p === void 0 ? void 0 : _providerInfo$data2$p.id) === 3;
  }

  return false;
};

exports.checkIfAllInOne = checkIfAllInOne;

var checkIfVideo = function checkIfVideo(providerInfo) {
  var _providerInfo$data3;

  if (providerInfo !== null && providerInfo !== void 0 && (_providerInfo$data3 = providerInfo.data) !== null && _providerInfo$data3 !== void 0 && _providerInfo$data3.plan) {
    var _providerInfo$data4, _providerInfo$data4$p;

    return (providerInfo === null || providerInfo === void 0 ? void 0 : (_providerInfo$data4 = providerInfo.data) === null || _providerInfo$data4 === void 0 ? void 0 : (_providerInfo$data4$p = _providerInfo$data4.plan) === null || _providerInfo$data4$p === void 0 ? void 0 : _providerInfo$data4$p.id) === 2;
  }

  return false;
};

exports.checkIfVideo = checkIfVideo;

var checkIfGrow = function checkIfGrow(providerInfo) {
  var _providerInfo$data5;

  if (providerInfo !== null && providerInfo !== void 0 && (_providerInfo$data5 = providerInfo.data) !== null && _providerInfo$data5 !== void 0 && _providerInfo$data5.plan) {
    var _providerInfo$data6, _providerInfo$data6$p;

    return (providerInfo === null || providerInfo === void 0 ? void 0 : (_providerInfo$data6 = providerInfo.data) === null || _providerInfo$data6 === void 0 ? void 0 : (_providerInfo$data6$p = _providerInfo$data6.plan) === null || _providerInfo$data6$p === void 0 ? void 0 : _providerInfo$data6$p.id) === 1;
  }

  return false;
};

exports.checkIfGrow = checkIfGrow;

var checkIfGrowOrAbove = function checkIfGrowOrAbove(providerInfo) {
  var _providerInfo$data7;

  if (providerInfo !== null && providerInfo !== void 0 && (_providerInfo$data7 = providerInfo.data) !== null && _providerInfo$data7 !== void 0 && _providerInfo$data7.plan) {
    var _providerInfo$data8, _providerInfo$data8$p;

    var id = providerInfo === null || providerInfo === void 0 ? void 0 : (_providerInfo$data8 = providerInfo.data) === null || _providerInfo$data8 === void 0 ? void 0 : (_providerInfo$data8$p = _providerInfo$data8.plan) === null || _providerInfo$data8$p === void 0 ? void 0 : _providerInfo$data8$p.id;
    return id > 0 && id <= 3;
  }

  return false;
};

exports.checkIfGrowOrAbove = checkIfGrowOrAbove;