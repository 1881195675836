import _objectSpread from "/Users/petarbozhinovski/projects/guryou-mono/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/objectSpread2";
import { ActionStatus } from './ActionStatus';
import { buildActionType } from './buildActionType';
import { StateStatus } from './StateStatus';
var initialState = {
  data: null,
  errors: null,
  status: StateStatus.NOT_INITIALIZED,
  version: 0,
  report: null
};

var basicReducer = function basicReducer(actionType) {
  var reduceFunction = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
  return function () {
    var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
    var action = arguments.length > 1 ? arguments[1] : undefined;

    switch (action.type) {
      case buildActionType(actionType, ActionStatus.START):
        return _objectSpread(_objectSpread({}, initialState), {}, {
          status: StateStatus.LOADING,
          version: state.version,
          entity: actionType.entity
        });

      case buildActionType(actionType, ActionStatus.DONE):
        var payload = action.payload.payload || action.payload;
        var request = action.payload.request;

        if (reduceFunction && typeof reduceFunction === 'function') {
          payload = reduceFunction(payload);
        }

        var data = 'data' in payload ? payload.data : payload;
        return {
          data: data,
          status: StateStatus.LOADED,
          entity: actionType.entity,
          version: +new Date(),
          report: action.report,
          request: request
        };

      case buildActionType(actionType, ActionStatus.FAILED):
        return {
          status: StateStatus.ERROR,
          errors: action.payload,
          entity: actionType.entity
        };

      case buildActionType(actionType, ActionStatus.RESET):
        return initialState;

      default:
        return state;
    }
  };
};

export { basicReducer };