import React from 'react';
import Analytics from './Analytics';
const BenefitsDeal = ({ width, tabletWidth, laptopWidth }) => {
  return (
    <>
      <Analytics width={width} tabletWidth={tabletWidth} laptopWidth={laptopWidth} />
    </>
  );
};

export default BenefitsDeal;
