import React from 'react';
import { colors } from '@guryou/colors';
import { Block } from '@guryou/html-components';

const HeroBackground = ({ width, tabletWidth, laptopWidth, image, image2, image3, color, isDeal, isBusiness, isRimini }) => {
  return (
    <>
      <Block
        borderTop={width < tabletWidth ? '' : `${isRimini ? '700px' : '876px'} solid ${isRimini || isDeal ? colors.rimini : color}`}
        borderRight={width < tabletWidth ? '' : '300px solid transparent'}
        width={width < tabletWidth ? '100%' : width < laptopWidth ? '70%' : width < 1600 ? '65%' : isRimini ? '51%' : '60%'}
        height={width < tabletWidth ? `${!isBusiness && !isRimini ? '1200px' : '1100px'}` : ''}
        background={
          width < 400 && isDeal
            ? `linear-gradient(160deg, ${isRimini || isDeal ? colors.rimini : color}, ${
                isRimini || isDeal ? colors.rimini : color
              } 60%, transparent calc(60% + 1px), transparent)`
            : width < tabletWidth
            ? `linear-gradient(160deg, ${isRimini || isDeal ? colors.rimini : color}, ${
                isRimini || isDeal ? colors.rimini : color
              } 60%, transparent calc(60% + 1px), transparent)`
            : ''
        }
        position="absolute"
        top={width < tabletWidth ? '45px' : '0'}
        left="0"
        zIndex="-1"
        margin={0}
      />
      {!isBusiness && width > tabletWidth && (
        <Block
          position="absolute"
          top={width < tabletWidth ? '50px' : '20px'}
          right="0"
          width={width < tabletWidth ? '100%' : '61%'}
          zIndex="-2"
          backgroundImage={`url('${image}')`}
          backgroundRepeat="no-repeat"
          backgroundSize="cover"
          backgroundPosition={width < tabletWidth ? 'center' : '0% 50%'}
          height="825px"
          margin={0}
        />
      )}
      {isRimini && width > tabletWidth && (
        <Block
          position="absolute"
          top={width < tabletWidth ? '50px' : '20px'}
          right="0"
          width={width < tabletWidth ? '100%' : '75%'}
          zIndex="-2"
          backgroundImage={`url('${image3}')`}
          backgroundRepeat="no-repeat"
          backgroundSize="cover"
          backgroundPosition={width < tabletWidth ? 'center' : 'left center'}
          height={isRimini ? '650px' : '85%'}
          margin={0}
        />
      )}
      {!isRimini && isBusiness && width > tabletWidth && (
        <>
          <Block zIndex={-3} position="absolute" right={0} backgroundColor={colors.grey} height="80%" margin={0} padding={0} />
          <Block
            position="absolute"
            top={width < tabletWidth ? '180px' : '75px'}
            right="0"
            width={width < tabletWidth ? '100%' : '40%'}
            zIndex="2"
            backgroundImage={`url('${image2}')`}
            backgroundRepeat="no-repeat"
            backgroundSize="contain"
            backgroundPosition={width < tabletWidth ? 'center' : '0% 50%'}
            height="85%"
            margin={0}
          />
        </>
      )}
    </>
  );
};

export default HeroBackground;
