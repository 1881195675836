import React from 'react';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'react-router';
import { useLocation } from 'react-router-dom';
import { colors } from '@guryou/colors';
import { AppBar } from '@guryou/html-components';
import Toolbar from './Toolbar';

const Header = () => {
  const location = useLocation();
  const { i18n } = useTranslation();

  let appBarColor = colors.mineShaft;

  if (
    location?.pathname === `/${i18n.language}/youdeal` ||
    location?.pathname === `/${i18n.language}` ||
    location?.pathname === `/${i18n.language}/`
  ) {
    appBarColor = colors.rimini;
  }
  if (location?.pathname === `/${i18n.language}/youfeel`) {
    appBarColor = colors.mandy;
  }
  if (location?.pathname === `/${i18n.language}/youstart`) {
    appBarColor = colors.fruitSalad;
  }

  return (
    <>
      <AppBar position="static" style={{ backgroundColor: appBarColor, boxShadow: '0 4px 2px -2px rgba(0, 0, 0, 0.1)' }}>
        <Toolbar />
      </AppBar>
    </>
  );
};

export default withRouter(Header);
