import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { colors } from '@guryou/colors';
import { FlexColumn, FlexRow, LinkButton, MediumText, OutlinedButton } from '@guryou/html-components';
import CheckRoundedIcon from '@material-ui/icons/CheckRounded';

const PlanCard = ({ plan, price, description, listOfBenefits, buttonText, currency, t }) => {
  const location = useLocation();
  const { i18n } = useTranslation();

  const isRouteBusinessDeal = location.pathname.includes('businessDeal') || location.pathname.includes('rimini-wellness');

  const planTitle = plan === 'All In One' ? 'allinone' : plan;

  return (
    <FlexColumn margin={1} width="340px" paddingTop={9} paddingLeft={2} paddingRight={2} marginBottom={10} paddingBottom={5}>
      <FlexColumn
        alignItems="start"
        boxShadow="3px 3px 10px rgba(0, 0, 0, 0.1)"
        borderRadius="0.5rem"
        padding={5}
        height={isRouteBusinessDeal ? '500px' : '570px'}
        justifyContent="space-between"
      >
        <FlexColumn margin={0} alignItems="start">
          <MediumText fontSize="1.1rem" fontWeight={700} color={colors.mandy}>
            {plan}
          </MediumText>
          <FlexRow margin={0} alignItems="baseline">
            <MediumText fontWeight={700}>{price}</MediumText>
            <MediumText marginLeft={2} fontSize="0.9rem" fontWeight={700}>
              {currency}
            </MediumText>
          </FlexRow>
          <MediumText marginTop={3} fontSize="1rem" fontWeight={500}>
            {description}
          </MediumText>
          <FlexColumn marginTop={3} marginBottom={7} margin={0}>
            {listOfBenefits.map(benefit => (
              <FlexRow key={`${benefit}_${plan}`} margin={0} marginTop={1} marginBottom={1} alignItems="center">
                <FlexRow margin={0} xs={1}>
                  <CheckRoundedIcon />
                </FlexRow>
                <MediumText xs={11} fontSize="1rem" fontWeight={400} paddingLeft={2}>
                  {t(`YD_PLANS_OFFERS.${planTitle.toLowerCase()}${benefit}`)}
                </MediumText>
              </FlexRow>
            ))}
          </FlexColumn>
        </FlexColumn>
        {!isRouteBusinessDeal && (
          <FlexRow justifyContent="center">
            <LinkButton to={`/${i18n.language}/register`} xs={10}>
              <OutlinedButton xs={12} paddingTop={4} paddingBottom={4} textTransform="none" fontWeight={700} fontSize="1.1rem">
                {buttonText}
              </OutlinedButton>
            </LinkButton>
          </FlexRow>
        )}
      </FlexColumn>
    </FlexColumn>
  );
};

export default PlanCard;
