import React from 'react';
import { FlexColumn, FlexRow, MediumText } from '@guryou/html-components';
const MyBenefit = ({ Icon, title, text, width, tabletWidth, laptopWidth }) => {
  return (
    <FlexColumn
      xs={width < tabletWidth ? 12 : 3}
      alignItems={width < 500 ? 'start' : width < tabletWidth ? 'center' : 'start'}
      margin={0}
      marginBottom={10}
      paddingLeft={width < 500 ? 0 : width < tabletWidth ? 20 : 6}
      paddingRight={width < 500 ? 0 : width < tabletWidth ? 20 : 6}
    >
      {width < 500 ? (
        <FlexRow margin={0} alignItems="center" justifyContent="center" textAlign="center">
          {Icon}
          <MediumText xs={10} fontSize="1.1rem" fontWeight={700}>
            {title}
          </MediumText>
        </FlexRow>
      ) : (
        <>
          {' '}
          {Icon}
          <MediumText fontSize={width < 600 ? '1.2rem' : width < tabletWidth ? '1.3rem' : width < laptopWidth ? '1.2rem' : '1.6rem'} fontWeight={700}>
            {title}
          </MediumText>
        </>
      )}
      <MediumText
        fontSize={width < 600 ? '1.1rem' : width < tabletWidth ? '1.3rem' : width < laptopWidth ? '1.1rem' : '1.3rem'}
        textAlign={width < 500 ? 'center' : width < tabletWidth ? 'center' : ''}
        paddingLeft={0}
        fontWeight={400}
      >
        {text}
      </MediumText>
    </FlexColumn>
  );
};

export default MyBenefit;
