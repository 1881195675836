import { basicReducer } from '../core';
import { CLASSES_GET_MANY, SESSIONS_GET_MANY, SUB_SERVICES_GET_MANY } from '../new';
import { CLASSES_FOR_BOOKING } from '../new/class';
import { RESERVATIONS_CREATE } from '../new/reservation';
import { PERSONNEL_DATE_SLOTS_GET_MANY, PERSONNEL_GET_MANY, PERSONNEL_TIME_SLOTS_GET_MANY } from '../new/resource';
import { SUBSCRIPTIONS_CREATE, SUBSCRIPTIONS_PRICE_GET } from '../new/subscription';
import { SUBSERVICES_FOR_BOOKING } from '../new/subService';
import { PAYMENT_INTENT_GET, PRODUCT_FILE_PREVIEW, PRODUCT_VIDEOS_GET_MANY, WIDGET_INTEGRATION, WIDGET_SERVICE_GROUPS, WIDGET_SERVICES } from '../types';
export var widgetReducers = {
  paymentIntent: basicReducer(PAYMENT_INTENT_GET),
  integration: basicReducer(WIDGET_INTEGRATION, {}),
  svcGroups: basicReducer(WIDGET_SERVICE_GROUPS, []),
  services: basicReducer(WIDGET_SERVICES, []),
  classes: basicReducer(CLASSES_GET_MANY, []),
  subServices: basicReducer(SUB_SERVICES_GET_MANY),
  sessions: basicReducer(SESSIONS_GET_MANY, []),
  reservationCreate: basicReducer(RESERVATIONS_CREATE),
  subscriptionCreate: basicReducer(SUBSCRIPTIONS_CREATE),
  subscriptionPrice: basicReducer(SUBSCRIPTIONS_PRICE_GET),
  personnel: basicReducer(PERSONNEL_GET_MANY, []),
  videos: basicReducer(PRODUCT_VIDEOS_GET_MANY, []),
  videoPreview: basicReducer(PRODUCT_FILE_PREVIEW, []),
  personnelTimeSlots: basicReducer(PERSONNEL_TIME_SLOTS_GET_MANY, []),
  personnelDateSlots: basicReducer(PERSONNEL_DATE_SLOTS_GET_MANY, []),
  classesForBooking: basicReducer(CLASSES_FOR_BOOKING, []),
  subservicesForBooking: basicReducer(SUBSERVICES_FOR_BOOKING, [])
};