import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { colors } from '@guryou/colors';
import { FlexColumn } from '@guryou/html-components';
import { handleAnalyticsClick } from 'analytics';
import HeroImageDeal from 'assets/img/firstSlideHeroColor.jpg';
import Background from '../../components/Background';
import FindOutBtn from './content/FindOutBtn';
import Title from './content/Title';

const Deal = ({ width }) => {
  const { t, i18n } = useTranslation();
  return (
    <FlexColumn position="relative" xs={12} height={width < 600 ? '600px' : '850px'} alignItems="start">
      <Background width={width} color={colors.rimini} image={HeroImageDeal} backgroundPosition={'right'} />
      <FlexColumn
        xs={width < 600 ? 11 : width < 800 ? 9 : 7}
        margin={0}
        alignItems={width < 600 ? 'center' : 'start'}
        width={width < 600 ? '' : width < 800 ? '500px' : '550px'}
        marginTop={width < 353 ? 5 : 10}
        marginLeft={7}
      >
        <Title width={width} />
        <Link
          to={`/${i18n.language}/youdeal`}
          onClick={() => handleAnalyticsClick('First slide', 'Button clicked!', 'Find out button', 'Lead', 'Find out button on first slide clicked!')}
        >
          <FindOutBtn text={t('GENERAL.findHow')} color={colors.white} textColor={colors.mineShaft} width={width} />
        </Link>
      </FlexColumn>
    </FlexColumn>
  );
};

export default Deal;
