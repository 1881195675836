import React from 'react';
//import { colors } from '@guryou/colors';
import { MediumText } from '@guryou/html-components';

const getFontSize = width => {
  if (width < 500) {
    return '2.2rem';
  }
  if (width < 1150) {
    return '3.4rem';
  }
  if (width < 1380) {
    return '4.2rem';
  }
  return '4.6rem';
};

const FirstSlideSubtitle = ({ width, text, redText, text2, color, textAlign }) => {
  return (
    <MediumText
      width="100%"
      color={color}
      fontSize={getFontSize(width)}
      fontWeight={900}
      textAlign={textAlign ? textAlign : 'left'}
      lineHeight="1.1"
      marginBottom={5}
    >
      {text}
      <span style={{ color: 'salmon' }}>{redText}</span>
      {text2}
    </MediumText>
  );
};

export default FirstSlideSubtitle;
