import React from 'react';
import { Block, FlexRow } from '@guryou/html-components';
import phone from 'assets/img/phone2youStart.png';

const HeroBackground = ({ width, tabletWidth, laptopWidth, color, isDeal, image }) => {
  return (
    <>
      <Block
        borderTop={width < tabletWidth ? '' : `876px solid ${color}`}
        borderRight={width < tabletWidth ? '' : '300px solid transparent'}
        width={width < tabletWidth ? '100%' : width < laptopWidth ? '70%' : width < 1600 ? '65%' : '60%'}
        height={width < tabletWidth ? '1200px' : ''}
        background={
          width < 400 && isDeal
            ? `linear-gradient(160deg, ${color}, ${color} 70%, transparent calc(60% + 1px), transparent)`
            : width < tabletWidth
            ? `linear-gradient(160deg, ${color}, ${color} 60%, transparent calc(60% + 1px), transparent)`
            : ''
        }
        position="absolute"
        top={width < tabletWidth ? '45px' : '0'}
        left="0"
        zIndex="-1"
        margin={0}
      />
      {width > tabletWidth && (
        <Block
          position="absolute"
          top={width < tabletWidth ? '50px' : '20px'}
          right="0"
          width={width < tabletWidth ? '100%' : '61%'}
          zIndex="-2"
          backgroundImage={`url('${image}')`}
          backgroundRepeat="no-repeat"
          backgroundSize="cover"
          backgroundPosition={width < tabletWidth ? 'center' : '0% 50%'}
          height="850px"
          margin={0}
        ></Block>
      )}
      {width > tabletWidth && (
        <FlexRow zIndex="3" justifyContent="space-around" position="absolute" xs={5.4} right="0">
          <Block
            xs={9}
            backgroundPosition="50% 50%"
            backgroundImage={`url('${phone}')`}
            backgroundRepeat="no-repeat"
            backgroundSize="cover"
            height="650px"
            margin={0}
          />
        </FlexRow>
      )}
    </>
  );
};

export default HeroBackground;
