import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { colors } from '@guryou/colors';
import { AppBar, Block, FlexColumn, FlexRow, ImageLogo, ImageSmall, LinkButton, SmallText, Toolbar } from '@guryou/html-components';
import { borderDefinition, sizes } from '@guryou/html-theme';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { useTheme } from '@material-ui/core/styles';
import MenuIcon from '@material-ui/icons/Menu';
import Logo from 'assets/img/guryouLogos/gyLogo01.png';
import DealLogo from '../../assets/img/guryouIcons/gyIcon37.png';
import FeelLogo from '../../assets/img/guryouIcons/gyIcon43.png';
import StartLogo from '../../assets/img/youstartLogo.png';

const tabletWidth = 800;

const SubHeader = () => {
  const { t, i18n } = useTranslation();
  const theme = useTheme();
  const history = useHistory();
  const location = useLocation();
  const { width } = useSelector(state => state.windowSize);

  const menuItems = [
    { label: 'YouDeal', route: `/${i18n.language}/youdeal`, logo: DealLogo },
    { label: 'YouFeel', route: `/${i18n.language}/youfeel`, logo: FeelLogo },
    { label: 'YouStart', route: `/${i18n.language}/youstart`, logo: StartLogo },
  ];

  const isRouteBusinessDeal =
    location.pathname.includes('businessDeal') || location.pathname.includes('info') || location.pathname.includes('rimini-wellness');
  const isRimini = location.pathname.includes('rimini-wellness');

  const [menu, setMenu] = useState(null);

  const createAuthItems = useCallback(
    () => [
      {
        label: t('GENERAL.register').toUpperCase(),
        // route: isRouteBusinessDeal ? `/${i18n.language}/registerBusiness` : `/${i18n.language}/register`,
        route: `/${i18n.language}/register`,
        displayOnBusiness: true,
      },
      { label: t('MODALS.login').toUpperCase(), route: `${process.env.REACT_APP_DEAL_URL}/login`, displayOnBusiness: false },
    ],
    [i18n.language, t]
  );

  const authItems = useMemo(() => createAuthItems(), [createAuthItems]);

  const handleClickMenu = event => {
    setMenu(event.currentTarget);
  };

  const handleCloseMenu = () => setMenu(null);

  const handleLogoClick = () => history.push(`/${i18n.language}`);

  return (
    <>
      <AppBar
        style={{ backgroundColor: theme.palette.background.default, boxShadow: '0 4px 2px -2px rgba(0, 0, 0, 0.1)' }}
        position="sticky"
        minHeight={width < 800 ? sizes.mobileSubHeaderHeight : sizes.footerBarHeight}
      >
        {width < 900 ? (
          <FlexRow justifyContent="space-between" alignItems="center">
            <ImageLogo src={Logo} marginLeft={width < 900 ? 3 : 0} maxWidth="120px" height="35px" onClick={handleLogoClick} />
            <FlexRow xs={6} justifyContent="flex-end">
              <>
                {' '}
                <Button aria-controls="simple-menu" aria-haspopup="true" onClick={handleClickMenu} style={{ padding: '0', minWidth: '50px' }}>
                  <MenuIcon />
                </Button>
                <Menu
                  id="simple-menu"
                  anchorEl={menu}
                  keepMounted
                  open={Boolean(menu)}
                  onClose={() => setMenu(null)}
                  PaperProps={{ style: { width: '180px' } }}
                >
                  {!isRouteBusinessDeal &&
                    menuItems.map((element, idx) => (
                      <MenuItem
                        key={idx}
                        style={{ padding: '10px', paddingLeft: '3px', paddingRight: '3px', display: 'flex', justifyContent: 'center' }}
                      >
                        <LinkButton
                          marginLeft={width < 900 ? 0 : width < tabletWidth ? 2 : 5}
                          fontSize="1.2rem"
                          fontWeight={width < 900 ? 400 : 500}
                          key={`${idx}_${element.label}`}
                          to={element.route}
                          onClick={() => handleCloseMenu(element.label)}
                        >
                          <FlexRow margin={0} width="150px" justifyContent="center">
                            <ImageSmall maxWidth={'30px'} src={element.logo} width={'30px'} height={'30px'} />
                            <SmallText fontSize="1.2rem" paddingLeft={element.label === 'YouStart' ? 2 : 3} fontWeight={400}>
                              {element.label}
                            </SmallText>
                          </FlexRow>
                        </LinkButton>
                      </MenuItem>
                    ))}
                  <Block xs={10} margin="0 auto" border={borderDefinition()}></Block>
                  {authItems.map(
                    (element, idx) =>
                      (!isRouteBusinessDeal || element.displayOnBusiness) && (
                        <MenuItem
                          key={idx}
                          style={{ padding: '10px', paddingLeft: '3px', paddingRight: '3px', display: 'flex', justifyContent: 'center' }}
                        >
                          <LinkButton
                            marginLeft={width < 900 ? 0 : width < tabletWidth ? 2 : 5}
                            fontSize="1.2rem"
                            fontWeight={width < 900 ? 400 : 500}
                            key={`${idx}_${element.label}`}
                            to={element.route}
                            onClick={() => handleCloseMenu(element.label)}
                          >
                            <FlexRow margin={0} width="150px" justifyContent="center">
                              <SmallText fontSize="1.2rem" paddingLeft={3} fontWeight={400}>
                                {element.label}
                              </SmallText>
                            </FlexRow>
                          </LinkButton>
                        </MenuItem>
                      )
                  )}
                </Menu>{' '}
              </>
            </FlexRow>
          </FlexRow>
        ) : (
          <Toolbar>
            <FlexRow justifyContent="space-between">
              <FlexRow paddingLeft={2} margin={0} alignItems="center" xs={8.2} flexWrap="nowrap">
                <ImageLogo src={Logo} marginLeft={3} maxWidth="200px" onClick={handleLogoClick} />

                <FlexRow xs={width < 480 ? 12 : 8} margin={0} flexWrap="nowrap">
                  {!isRouteBusinessDeal &&
                    menuItems.map((element, idx) => (
                      <LinkButton
                        marginLeft={width < tabletWidth ? 2 : 4}
                        fontSize={width <= 1135 ? '1rem' : '1.2rem'}
                        fontWeight={400}
                        key={`${idx}_${element.label}`}
                        to={element.route}
                        borderRadius="0.2em"
                        width={width <= 1135 ? '120px' : '140px'}
                        textAlign="center"
                      >
                        <FlexColumn
                          borderRadius="0.2em"
                          border="1px solid rgba(33,33,33,.2)"
                          transition="all .2s ease-in-out;"
                          hover={true}
                          hoverEffect={{ 'box-shadow': '0px 0px 10px rgba(33,33,33,.2)' }}
                        >
                          <FlexRow alignItems="center" flexWrap="nowrap">
                            <ImageSmall
                              src={element.logo}
                              width={width <= 1135 ? '25px' : '30px'}
                              height={width <= 1135 ? '25px' : '30px'}
                              marginRight={1}
                              marginLeft={3}
                            />
                            {element.label}
                          </FlexRow>
                        </FlexColumn>
                      </LinkButton>
                    ))}
                </FlexRow>
              </FlexRow>

              <FlexRow xs={3.8} margin={0} alignItems="center" justifyContent="flex-end">
                {authItems.map(
                  (element, idx) =>
                    (!isRouteBusinessDeal || element.displayOnBusiness) && (
                      <LinkButton
                        marginLeft={width < tabletWidth ? 2 : 4}
                        fontSize={width <= 1135 ? '1rem' : '1.2rem'}
                        fontWeight={400}
                        key={`${idx}_${element.label}`}
                        to={element.route}
                        borderRadius="0.2em"
                        width={width <= 1135 ? '130px' : '140px'}
                        textAlign="center"
                      >
                        <FlexColumn
                          borderRadius="0.2em"
                          border={`1px solid ${isRimini ? colors.rimini : theme.palette.secondary.main}`}
                          transition="all .2s ease-in-out;"
                          hover={true}
                          padding="5px"
                          hoverEffect={
                            !idx
                              ? { 'box-shadow': `1px 1px 10px ${isRimini ? colors.rimini : theme.palette.secondary.main}` }
                              : { 'box-shadow': '0px 0px 10px rgba(33,33,33,.2)' }
                          }
                          color={!idx ? theme.palette.background.white : ''}
                          backgroundColor={!idx ? (isRimini ? colors.rimini : theme.palette.secondary.main) : ''}
                        >
                          {element.label}
                        </FlexColumn>
                      </LinkButton>
                    )
                )}
              </FlexRow>
            </FlexRow>
          </Toolbar>
        )}
      </AppBar>
    </>
  );
};

export default SubHeader;
