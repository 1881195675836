import React from 'react';
import { Block } from '@guryou/html-components';

const Background = ({ width, color, image, backgroundPosition }) => {
  return (
    <>
      <Block
        width="100%"
        height="450px"
        background={`linear-gradient(175deg, ${color}, ${color} 60%, transparent calc(60% + 1px), transparent)`}
        position="absolute"
        zIndex="-1"
        margin={0}
      />
      <Block
        position="absolute"
        width="100%"
        top={width < 600 ? '250px' : '220px'}
        zIndex={-2}
        backgroundImage={`url('${image}')`}
        backgroundRepeat="no-repeat"
        backgroundSize="cover"
        backgroundPosition={backgroundPosition}
        height={width < 600 ? '350px' : '700px'}
        margin={0}
      />
    </>
  );
};

export default Background;
