import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { colors } from '@guryou/colors';
import { FlexColumn, FlexRow, LinkButton, MediumText, OutlinedButton, SmallText } from '@guryou/html-components';
import CheckRoundedIcon from '@material-ui/icons/CheckRounded';
import { handleAnalyticsClick } from 'analytics';

const SpecialPlanCard = ({ plan, price, description, listOfBenefits, buttonText, currency }) => {
  const { t, i18n } = useTranslation();
  const location = useLocation();

  const isRouteBusinessDeal = location.pathname.includes('businessDeal') || location.pathname.includes('rimini-wellness');
  const isRiminiOrDeal = location.pathname.includes('rimini-wellness') || location.pathname.includes('youdeal');

  const planTitle = plan === 'All In One' ? 'allinone' : plan;

  return (
    <FlexColumn margin={1} width="340px" position="relative" paddingTop={9} paddingLeft={2} paddingRight={2} marginBottom={10} paddingBottom={5}>
      <FlexColumn
        backgroundColor={isRiminiOrDeal ? colors.rimini : colors.mineShaft}
        alignItems="start"
        boxShadow="3px 3px 10px rgba(0, 0, 0, 0.1)"
        borderRadius="0.5rem"
        padding={5}
        height={isRouteBusinessDeal ? '500px' : '570px'}
        justifyContent="space-between"
      >
        <FlexColumn margin={0} alignItems="start">
          <MediumText fontSize="1.1rem" fontWeight={700} color={colors.mandy}>
            {plan}
          </MediumText>
          <FlexRow margin={0} alignItems="baseline">
            <MediumText fontWeight={700} color={colors.light1}>
              {price}
            </MediumText>
            <MediumText marginLeft={2} fontSize="0.9rem" fontWeight={700} color={colors.light1}>
              {currency}
            </MediumText>
          </FlexRow>
          <MediumText marginTop={3} fontSize="1rem" fontWeight={500} color={colors.light1}>
            {description}
          </MediumText>
          <FlexColumn marginTop={3} marginBottom={7} margin={0}>
            {listOfBenefits.map(benefit => (
              <FlexRow key={`${benefit}_${plan}`} margin={0} marginTop={1} marginBottom={1} alignItems="center">
                <FlexRow margin={0} xs={1}>
                  <CheckRoundedIcon htmlColor={colors.lightningYellow} />
                </FlexRow>
                <MediumText xs={11} fontSize="1rem" fontWeight={400} marginLeft={2} color={colors.light1}>
                  {t(`YD_PLANS_OFFERS.${planTitle.toLowerCase()}${benefit}`)}
                </MediumText>
              </FlexRow>
            ))}
          </FlexColumn>
        </FlexColumn>
        {!isRouteBusinessDeal && (
          <FlexRow justifyContent="center">
            <LinkButton
              to={`/${i18n.language}/register`}
              onClick={() =>
                handleAnalyticsClick(
                  'Pricing Plan',
                  'Pricing Plan button clicked!',
                  'Pricing Plan button clicked',
                  'Lead',
                  'Pricing plan button clicked!'
                )
              }
              xs={10}
            >
              <OutlinedButton
                backgroundColor={colors.light1}
                xs={12}
                paddingTop={4}
                paddingBottom={4}
                textTransform="none"
                fontWeight={700}
                fontSize="1.1rem"
              >
                {buttonText}
              </OutlinedButton>
            </LinkButton>
          </FlexRow>
        )}
      </FlexColumn>
      <FlexColumn
        position="absolute"
        top="0"
        right="0"
        margin={0}
        justifyContent="center"
        boxShadow="0px 5px 3px rgba(0, 0, 0, 0.2)"
        borderRadius="50%"
        background="radial-gradient(circle at 100px 100px, #ff8008, #ffc837)"
        width="75px"
        height="75px"
        textAlign="center"
        fontSize="0.8rem"
        fontWeight={700}
        color={colors.white}
      >
        <SmallText color={colors.white}>{t('YD_PLANS.theMostChoise')}</SmallText>
      </FlexColumn>
    </FlexColumn>
  );
};

export default SpecialPlanCard;
