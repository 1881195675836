import { StateStatus } from './StateStatus';

var stateHasItems = function stateHasItems(obj) {
  return obj && obj === StateStatus.LOADED && obj.data && obj.data.length > 0;
};

var stateIsEmpty = function stateIsEmpty(obj) {
  var _obj$data;

  return (obj === null || obj === void 0 ? void 0 : obj.status) === StateStatus.LOADED && !(obj !== null && obj !== void 0 && (_obj$data = obj.data) !== null && _obj$data !== void 0 && _obj$data.length);
};

var stateIsLoading = function stateIsLoading(obj) {
  if (Array.isArray(obj)) {
    return obj.some(function (x) {
      return x.status === StateStatus.LOADING;
    });
  }

  return !obj || obj.status === StateStatus.LOADING;
};

var stateIsNotInitialized = function stateIsNotInitialized(obj) {
  return !obj || obj.status === StateStatus.NOT_INITIALIZED;
};

var stateIsNotReady = function stateIsNotReady(obj) {
  if (Array.isArray(obj)) {
    return obj.some(function (x) {
      return x.status <= StateStatus.LOADING || x.status === StateStatus.REFRESHING;
    });
  }

  return !obj || obj.status <= StateStatus.LOADING || obj.status === StateStatus.REFRESHING;
};

var stateIsRefreshing = function stateIsRefreshing(obj) {
  return obj && obj.status === StateStatus.REFRESHING;
};

var stateIsLoaded = function stateIsLoaded(obj) {
  if (Array.isArray(obj)) {
    return obj.some(function (x) {
      return x.status === StateStatus.LOADED;
    });
  }

  return obj && obj.status === StateStatus.LOADED;
};

var stateError = function stateError(obj) {
  if (Array.isArray(obj)) {
    return obj.some(function (x) {
      return x.status === StateStatus.ERROR;
    });
  }

  return obj && obj.status === StateStatus.ERROR;
};

export { stateHasItems, stateIsEmpty, stateIsLoading, stateIsNotInitialized, stateIsNotReady, stateIsLoaded, stateIsRefreshing, stateError };