import React from 'react';
import { useTranslation } from 'react-i18next';
import { colors } from '@guryou/colors';
import { FlexColumn, FlexRow, MediumText } from '@guryou/html-components';
import Category from './Category';
import health from '../../assets/img/health.png';
import lifestyle from '../../assets/img/lifestyle.png';
import mindset from '../../assets/img/mindset.png';
import moves from '../../assets/img/moves.png';
import wellbeing from '../../assets/img/wellbeing.png';
import wellness from '../../assets/img/wellness.png';
const Centers = () => {
  const { t } = useTranslation();
  return (
    <FlexColumn marginBottom={30}>
      <MediumText fontSize="2rem" fontWeight={700}>
        {t('ABOUT_PAGE.centers')}
      </MediumText>
      <MediumText fontSize="1.4rem" textAlign="center" fontWeight={400}>
        {t('ABOUT_PAGE.centersCategoriesTitle')}
      </MediumText>
      <FlexRow margin={0} marginTop={10}>
        <Category color={colors.pomegranate} image={health} title={'HEALTH'} text={t('ABOUT_PAGE.healthText')} />
        <Category color={colors.lightningYellow} image={moves} title={'MOVES'} text={t('ABOUT_PAGE.movesText')} />
        <Category color={colors.purpleHeart} image={mindset} title={'MINDSET'} text={t('ABOUT_PAGE.mindsetText')} />
        <Category color={colors.spray} image={wellbeing} title={'WELLBEING'} text={t('ABOUT_PAGE.wellbeingText')} />
        <Category color={colors.fruitSalad2} image={lifestyle} title={'LIFESTYLE & TRAVEL'} text={t('ABOUT_PAGE.lifestyleText')} />
        <Category color={colors.portage} image={wellness} title={'WELNESS@WORK'} text={t('ABOUT_PAGE.wellnessText')} />
      </FlexRow>
    </FlexColumn>
  );
};

export default Centers;
