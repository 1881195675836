import React from 'react';
import { Image } from '@guryou/html-components';
import redZone from 'assets/img/newHomepageLogos/redZone.png';
import sayonaraMotta from 'assets/img/newHomepageLogos/sayonaraMotta.png';
import yogaCoach from 'assets/img/newHomepageLogos/yogaCoach.png';

const FullLogos = () => {
  return (
    <>
      <Image src={yogaCoach} height="60px" padding={1.5} margin={1.5} width="auto"></Image>
      <Image src={redZone} height="60px" padding={1.5} margin={1.5} width="auto"></Image>
      <Image src={sayonaraMotta} height="60px" padding={1.5} margin={1.5} width="auto"></Image>
    </>
  );
};

export default FullLogos;
