import ReactPixel from 'react-facebook-pixel';
import ReactGA from 'react-ga';

export const buttonClickEventGA = (GATitle, GAAction, GALabel) => {
  ReactGA.event({
    category: GATitle,
    action: GAAction,
    label: GALabel,
  });
};

export const buttonClickEventFB = (FBStandardEvent, FBCustomProperty) => {
  if (FBStandardEvent === 'Contact') {
    ReactPixel.track(FBStandardEvent, { description: `${FBCustomProperty}` });
  }
  if (FBStandardEvent === 'Lead') {
    ReactPixel.track(FBStandardEvent, { content_name: `${FBCustomProperty}` });
  }
};

export const handleAnalyticsClick = (GATitle, GAAction, GALabel, FBStandardEvent, FBCustomProperty) => {
  buttonClickEventGA(GATitle, GAAction, GALabel);
  buttonClickEventFB(FBStandardEvent, FBCustomProperty);
};
