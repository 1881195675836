import React from 'react';
import { colors } from '@guryou/colors';
import { Block } from '@guryou/html-components';

const Dot = () => {
  return (
    <>
      <Block
        width="20px"
        height="20px"
        border={`1px solid ${colors.black}`}
        borderRadius="50%"
        margin={0}
        display="flex"
        alignItems="center"
        justifyContent="center"
        position="relative"
        backgroundColor={colors.light1}
      >
        <Block width="10px" height="10px" borderRadius="50%" backgroundColor={colors.black} margin={0} position="aboslute" />
      </Block>
    </>
  );
};

export default Dot;
