import { initReactI18next } from 'react-i18next';
import i18n from 'i18next';
import detector from 'i18next-browser-languagedetector';
import XHR from 'i18next-xhr-backend';

i18n
  .use(detector)
  .use(XHR)
  .use(initReactI18next)
  .init({
    whitelist: ['en', 'it'],
    fallbackLng: 'it',
    detection: {
      order: ['path', 'localStorage'],
    },
    react: {
      useSuspense: false,
    },
    backend: {
      loadPath: `${process.env.REACT_APP_S3_TRANSLATIONS_URL}/${process.env.REACT_APP_NAME_LANDING}/{{lng}}.json`,
      allowMultiLoading: false,
      crossDomain: false,
      withCredentials: false,
      load: 'currentOnly',
      backendOptions: {
        expirationTime: 0,
      },
    },
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
