var buildActionType = function buildActionType(actionType) {
  var status = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '';

  if (typeof actionType === 'string') {
    return "".concat(actionType).concat(status);
  }

  return "".concat(actionType.entity).concat(actionType.action).concat(status);
};

export { buildActionType };