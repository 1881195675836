import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { colors } from '@guryou/colors';
import { Block, FlexColumn, MediumText } from '@guryou/html-components';
import { boxShadowDefinition } from '@guryou/html-theme/src/constants';
import LandingFooter from 'components/landingFooter';
import Centers from './Centers';
import DotsWithText from './DotsWithText';
// import Team from './Team';
import bannerImage from '../../assets/img/banner-about-img.png';
import pharmaImage from '../../assets/img/pharma.png';

const tabletWidth = 900;

const About = () => {
  const { t } = useTranslation();
  const { width } = useSelector(state => state.windowSize);
  return (
    <>
      <Block margin={0} xs={12} height="75vh" backgroundColor={colors.mandy}>
        <Block
          height="100%"
          backgroundImage={`url('${bannerImage}')`}
          backgroundPosition="center"
          backgroundSize="contain"
          backgroundRepeat="no-repeat"
        />
      </Block>
      <DotsWithText />
      <FlexColumn
        backgroundImage={`url('${pharmaImage}')`}
        backgroundAttachment="fixed"
        backgroundSize="cover"
        backgroundRepeat="no-repeat"
        position="sticky"
        padding="5%"
        boxShadow={boxShadowDefinition('inset 0px 0px 0px 1000px', 'rgba(0,0,0,0.5)')}
        textAlign="center"
      >
        <MediumText color={colors.white} fontWeight={400} fontSize="1.4rem" marginBottom={5}>
          {t('ABOUT_PAGE.parallaxText1')}
        </MediumText>
        <MediumText color={colors.white} fontWeight={400} fontSize="1.4rem">
          {t('ABOUT_PAGE.parallaxText2')}
        </MediumText>
      </FlexColumn>
      <FlexColumn padding="10%" paddingTop="4%">
        <Centers />
        {/* <Team /> */}
      </FlexColumn>
      <LandingFooter width={width} tabletWidth={tabletWidth} color={colors.mandy} />
    </>
  );
};

export default About;
