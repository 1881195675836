import React from 'react';
import { Block } from '@guryou/html-components';

const BackgroundLandingFooter = props => {
  const { width, color, mobileWidth, homepage } = props;
  return (
    <Block
      borderBottom={`98px solid ${color}`}
      borderRight={`${homepage ? '0' : width < 900 ? '15vw' : '5vw'} solid transparent`}
      width={homepage ? '100%' : mobileWidth ? '100%' : width < 1250 ? '45vw' : '35vw'}
      margin={0}
      height="0"
      position="absolute"
      top="0"
      left="0"
      marginTop="-1px"
      zIndex="-1"
    />
  );
};

export default BackgroundLandingFooter;
