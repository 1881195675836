import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { colors } from '@guryou/colors';
import { Block, FlexColumn, FlexRow, SmallText, Spinner } from '@guryou/html-components';
import { extraLargeBorderRadius } from '@guryou/html-theme/src/constants';
import { getBlogCategories, stateIsLoaded } from '@guryou/redux';
import seeAllCategory from 'assets/img/seeAllCategory.png';

const Categories = ({ width }) => {
  const { t, i18n } = useTranslation();

  const dispatch = useDispatch();
  const categories = useSelector(state => state.articles.blogCategories);

  useEffect(() => {
    if (!stateIsLoaded(categories)) {
      dispatch(getBlogCategories());
    }
    //eslint-disable-next-line
  }, [dispatch]);

  if (!stateIsLoaded(categories)) {
    return <Spinner />;
  }

  return (
    <FlexRow justifyContent="space-around" margin={10} xs={10}>
      {categories.data?.categories.map(category => (
        <FlexColumn xs={width < 400 ? 12 : width < 600 ? 6 : width < 1200 ? 4 : 1.7} minWidth={90} key={category?.id} marginBottom={5}>
          <Link to={`/${i18n.language}/category/${category?.Slug}`}>
            <Block
              backgroundImage={`url(${category?.image?.url})`}
              margin={0}
              borderRadius={extraLargeBorderRadius}
              width={90}
              height={90}
              background-repeat="no-repeat"
              background-position="center center"
              background-size="cover"
            ></Block>
          </Link>
          <SmallText color={category?.color} textAlign="center" marginTop={2}>
            {category?.name?.toUpperCase()}
          </SmallText>
        </FlexColumn>
      ))}
      <FlexColumn xs={width < 400 ? 12 : width < 600 ? 6 : width < 1200 ? 4 : 1.7} minWidth={90}>
        <Link to={`/${i18n.language}/category/all`}>
          <Block
            backgroundImage={`url(${seeAllCategory})`}
            margin={0}
            borderRadius={extraLargeBorderRadius}
            width={90}
            height={90}
            background-repeat="no-repeat"
            background-position="center center"
            background-size="cover"
          ></Block>
        </Link>
        <SmallText color={colors.mandy} marginTop={2}>
          {t('GENERAL.all')}
        </SmallText>
      </FlexColumn>
    </FlexRow>
  );
};

export default Categories;
