import AlessioBoni from 'assets/img/AlessioBoni.jpg';
import AndreaDelPiano from 'assets/img/AndreaDelPiano.jpg';
import FrancescaPinnaPintor from 'assets/img/FrancescaPinnaPintor.jpg';
import MarziaOppizio from 'assets/img/MarziaOppizio.jpeg';
import SayonaraMotta from 'assets/img/SayonaraMotta.jpg';

export const CenterCardInfo = [
  {
    id: 0,
    image: AlessioBoni,
    name: 'Alessio Boni',
    job: 'Musicoterapeuta',
    description:
      'GurYou è un’app semplice da usare che aiuta a mettere in connessione professionisti del settore.  Adatta a chi vuole aumentare e fidelizzare i propri clienti e utilissima, soprattutto in questo momento, per chi vuole unire l’attività online a quella in presenza.',
  },
  {
    id: 1,
    image: AndreaDelPiano,
    name: 'Andrea Del Piano',
    job: 'Roma-OCR',
    description:
      'GurYou è un’app fantastica. Mi ha permesso di smaltire e automatizzare le antipatiche procedure di prenotazione online. È molto intuitiva e i clienti non hanno difficoltà nell’utilizzo, per un centro sportivo è di primaria importanza.  È in continua evoluzione e soprattutto segue ciò che sono le richieste dei gestori di centri sportivi come me. Consiglio vivamente di provarla, ne resterete contenti!',
  },
  {
    id: 2,
    image: MarziaOppizio,
    name: 'Marzia Oppizio',
    job: 'Personal Trainer',
    description:
      'Piattaforma eccellente che consiglierei a tutti gli appassionati del mondo del wellness e ai colleghi professionisti che hanno intenzione di ampliare e migliorare il proprio business.  App utilissima e intuitiva sia per gli utenti che per i professionisti che la utilizzano come supporto; team sempre disponibile (quasi H24) e pronto a rispondere ad ogni esigenza!',
  },
  {
    id: 3,
    image: SayonaraMotta,
    name: 'Sayonara Motta',
    job: 'Nike Training Team e Yoga Leader',
    description:
      'Sul piano del Wellness, nella speciale situazione che stiamo vivendo, trovo che la piattaforma di GurYou sia una delle più professionali sul mercato italiano. Offre la possibilità a centri ﬁtness, coach, Fitness trainer e altri professionisti di creare il loro catalogo di servizi, gestire i pagamenti, ricevere prenotazioni 24h/24, creando un’interazione reale di accoglienza con i propri clienti.',
  },
  {
    id: 4,
    image: FrancescaPinnaPintor,
    name: 'Francesca Pinna Pintor',
    job: 'Personal Trainer',
    description:
      'Ho scelto GurYou perché mi permette di mettermi in gioco presentando la mia attività professionale al pubblico, mi stimola ad approfondire la mia crescita e le mie conoscenze perché il team social mi coinvolge per dirette online e post. Inoltre è in continua espansione e innovazione.',
  },
];
