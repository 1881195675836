import { colors } from '@guryou/colors';
import { makeStyles } from '@material-ui/core';
import HeroImageDeal from '../assets/img/newImages/1.png';

const useFirstSlideStyles = makeStyles(theme => ({
  container: {
    width: '100%',
    height: 'calc(100vh - 117.95px)',
    position: 'relative',
  },

  topTrapezoid: {
    borderLeft: '0 solid transparent',
    borderRight: '40vh solid transparent',
    borderTop: props => `calc(100vh - 117.95px) solid ${colors[`${props.topColor}`]}`,
    height: '0',
    minWidth: '65%',
    [theme.breakpoints.between(950, 1300)]: {
      minWidth: '60%',
      borderRight: '30vh solid transparent',
    },
    position: 'absolute',
    top: '0',
    left: '0',
    zIndex: '-1',
  },
  slide: {
    opacity: '1',
    position: 'absolute',
    width: '95%',
    paddingLeft: '60px',
    top: '50px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  leftContent: {
    width: '100%',
    marginLeft: '15px',
  },
  background: {
    position: 'absolute',
    top: '0',
    right: -160,
    zIndex: '-2',
    opacity: '1',
    backgroundImage: `url('${HeroImageDeal}')`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'right top',
    height: 'calc(100vh - 117.95px)',
    width: '70%',
    [theme.breakpoints.between(1250, 1300)]: {
      right: -150,
      width: '70%',
    },
    [theme.breakpoints.between(1150, 1300)]: {
      right: -120,
      width: '72%',
    },
    [theme.breakpoints.between(950, 1150)]: {
      right: -150,
      width: '80%',
    },
  },
}));

export default useFirstSlideStyles;
