import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { colors } from '@guryou/colors';
import { FlexColumn, LargeText } from '@guryou/html-components';

const Calendar = () => {
  const { t } = useTranslation();
  const location = useLocation();

  const { width } = useSelector(state => state.windowSize);

  const isGuryouDeal = location.pathname.includes('guryoudeal');

  return (
    <FlexColumn id="calendar" xs={12} height="100vh" paddingTop="8vh" backgroundColor={colors.mineShaft}>
      <LargeText
        paddingLeft={5}
        paddingRight={5}
        fontSize={width < 1400 ? '2.2rem' : '2.8rem'}
        textAlign="center"
        marginBottom={10}
        fontWeight={700}
        color={colors.white}
      >
        {t('CONTENT.bookYourFreeDemo')}
      </LargeText>
      <iframe
        frameBorder="0"
        width="100%"
        height="700px"
        title="meetings"
        src={isGuryouDeal ? process.env.REACT_APP_CALENDAR_FRANCESCO_URL : process.env.REACT_APP_CALENDAR_RIMINI_MEETINGS_URL}
      ></iframe>
    </FlexColumn>
  );
};

export default Calendar;
