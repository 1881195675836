import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { colors } from '@guryou/colors';
import { CustomButton, DefaultText, FlexColumn, FlexRow, LargeText, LinkButton, MediumText, SmallText } from '@guryou/html-components';
import { createUser, NOTIFICATION, stateIsLoaded, stateIsLoading } from '@guryou/redux';
import { Box, Checkbox, CircularProgress, Paper } from '@material-ui/core';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { handleAnalyticsClick } from 'analytics';
import ToggleSwitch from '../feel/sections/centers/toggleSwitch';
import CustomTextField from './components/CustomTextField';

const defaultStates = {
  agreements: { marketing: { type: 'marketing', decision: false }, email: { type: 'email', decision: false } },
  role: { role: 'owner' },
};

const Register = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const location = useLocation();

  const [checkbox, setCheckbox] = useState(false);
  const [formData, setFormData] = useState(defaultStates.role);
  const [agreements, setAgreements] = useState(defaultStates.agreements);

  const createdUser = useSelector(state => state.user.createUser);
  const { width } = useSelector(state => state.windowSize);

  const isBusinessRegister = location.pathname.includes('registerBusiness');

  const handleRegisterClick = () => {
    const { firstName, lastName, email, role } = formData;
    const { marketing, email: agreementsEmail } = agreements;
    dispatch(createUser({ firstName, lastName, email, role, agreements: [marketing, agreementsEmail] }));
  };

  const handleChange = event => {
    const { name, value } = event.target;
    setFormData(prevState => ({ ...prevState, [name]: value }));
  };

  const handleCheckBoxesChange = event => {
    const { name, checked } = event.target;
    setAgreements(prevState => ({ ...prevState, [name]: { type: name, decision: checked } }));
  };

  const handleSwitchChange = event => {
    const { name, checked } = event.target;
    setFormData(prevState => ({ ...prevState, [name]: checked ? 'customer' : 'owner' }));
  };

  const handleErrors = () => {
    if (!checkbox) {
      dispatch({ type: NOTIFICATION, payload: { message: t('CONTENT.mustAgreeOnTerms'), variant: 'error' } });
    }

    handleRegisterClick();
    handleAnalyticsClick(
      'Complete Registration button',
      'Complete registration button clicked!',
      'registration complete on /register',
      'Lead',
      'Complete registration button clicked on /register page!'
    );
  };

  const successMessage = (
    <FlexColumn alignItems="center">
      <LargeText textAlign="center" marginTop="25px" color={colors.white}>
        {t('CONFIRMATIONS.checkYourEmailForFurtherInstructions')}
      </LargeText>
    </FlexColumn>
  );

  return (
    <FlexRow xs={12} height="calc(100vh - 100px)" paddingTop="8vh" backgroundColor={colors.mineShaft} margin={0}>
      {stateIsLoaded(createdUser) ? (
        successMessage
      ) : (
        <>
          <FlexColumn xs={width < 900 ? 12 : 6} padding={3} margin={0} backgroundColor={colors.mineShaft}>
            <Paper>
              <Box width="90%" margin="0 auto">
                <MediumText color={colors.mineShaft} textAlign="center" fontSize="1.4rem" marginTop={2} marginBottom={3}>
                  {t('CONTENT.createYourProfile')}
                </MediumText>
                <DefaultText textAlign="center" color={colors.grey[300]} fontWeight={500} fontSize="0.9rem">
                  {t('CONTENT.whatKindOfUserYouWant')}
                </DefaultText>
                <ToggleSwitch
                  values={isBusinessRegister ? ['centroProf'] : ['centroProf', 'privateUser']}
                  name="role"
                  width={width}
                  tabletWidth={width + 1}
                  onChange={handleSwitchChange}
                  marginTop="15px"
                  marginLeft="8.5px"
                  fontSize="0.72rem"
                  register={true}
                  disabled={isBusinessRegister}
                />
                <CustomTextField text="Name" name="firstName" required={true} handleChange={handleChange} />
                <CustomTextField text="Last Name" name="lastName" required={true} handleChange={handleChange} />
                <CustomTextField text="Email" name="email" required={true} type="email" handleChange={handleChange} />
                <FlexRow justifyContent="center">
                  <FormControlLabel
                    style={{ marginTop: '16px' }}
                    control={
                      <Checkbox value={checkbox} onChange={() => setCheckbox(!checkbox)} style={{ color: colors.mineShaft }} name="checkedC" />
                    }
                    label={
                      <MediumText textAlign="left" color={colors.mineShaft} fontSize="0.9rem" fontWeight={600}>
                        {t('CONTENT.iAcceptPrivacyPolicy')}
                      </MediumText>
                    }
                  />
                </FlexRow>
                <MediumText textAlign="center" color={colors.mineShaft} fontSize="0.9rem" fontWeight={400} marginTop={4} marginBottom={4}>
                  {t('CONTENT.consultThe')}{' '}
                  <LinkButton color={colors.mineShaft} borderBottom="1px solid black" to={process.env.REACT_APP_PRIVACY_POLICY}>
                    {t('FOOTER.privacyPolicy')}
                  </LinkButton>{' '}
                  e i{' '}
                  <LinkButton color={colors.mineShaft} borderBottom="1px solid black" to={process.env.REACT_APP_TERMS_AND_CONDITIONS}>
                    {t('FOOTER.terms')}
                  </LinkButton>
                  .
                </MediumText>
                {stateIsLoading(createdUser) ? (
                  <FlexRow justifyContent="center">
                    <CircularProgress size={30} />
                  </FlexRow>
                ) : (
                  <CustomButton
                    disabled={stateIsLoading(createdUser) || !checkbox}
                    onClick={handleErrors}
                    color={colors.white}
                    backgroundColor={checkbox ? colors.mandy : colors.grey}
                    fontSize="1rem"
                    padding={5}
                    marginBottom={5}
                    whiteSpace="nowrap"
                    margin="0 auto"
                    width="100%"
                  >
                    {t('CONTENT.completeRegistration')}
                  </CustomButton>
                )}
                <SmallText padding={2} color="red">
                  {!createdUser.status && createdUser.errors && createdUser.errors[0].message}
                </SmallText>
              </Box>
            </Paper>
          </FlexColumn>
          <FlexColumn
            xs={width < 900 ? 12 : 6}
            alignItems={width < 900 ? 'center' : 'start'}
            paddingLeft={width < 1250 ? 7 : 20}
            paddingRight={width < 1250 ? 7 : 20}
            paddingBottom={width < 900 && 20}
            backgroundColor={colors.mineShaft}
          >
            <MediumText textAlign={width < 900 ? 'center' : 'left'} color={colors.white} fontSize="1.2rem" fontWeight={400} marginTop={5}>
              {t('ACCOUNT.data')}
            </MediumText>
            <MediumText textAlign={width < 900 ? 'center' : 'left'} color={colors.white} fontSize="1.2rem" fontWeight={400}>
              {t('ACCOUNT.gyWillUsePersonalData')}
            </MediumText>
            <MediumText textAlign={width < 900 ? 'center' : 'left'} color={colors.white} fontSize="1.6rem" fontWeight={600} marginTop={5}>
              {t('ACCOUNT.indicateWayToRecieveUpdates')}
            </MediumText>
            <FlexColumn alignItems="start" xs={width < 900 ? 8 : 12} marginTop={3} marginBottom={3}>
              <FormControlLabel
                control={<Checkbox style={{ color: colors.white }} onChange={handleCheckBoxesChange} name="email" />}
                label={
                  <MediumText color={colors.white} fontSize="1rem" fontWeight={400}>
                    {t('FOOTER.newsletter')}
                  </MediumText>
                }
              />
              <FormControlLabel
                control={<Checkbox onChange={handleCheckBoxesChange} style={{ color: colors.white }} name="marketing" />}
                label={
                  <MediumText color={colors.white} whiteSpace="nowrap" fontSize="1rem" fontWeight={400}>
                    {t('ACCOUNT.marketingCommunications')}
                  </MediumText>
                }
              />
            </FlexColumn>

            <MediumText
              textAlign={width < 900 ? 'center' : 'left'}
              color={colors.white}
              fontSize="1.2rem"
              fontWeight={400}
              marginTop={3}
              marginBottom={3}
            >
              {t('ACCOUNT.youCanChangeYourMind')}
            </MediumText>
          </FlexColumn>
        </>
      )}
    </FlexRow>
  );
};

export default Register;
