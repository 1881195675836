import React from 'react';
import { ApolloProvider } from 'react-apollo';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ApolloProvider as ApolloHooksProvider } from '@apollo/react-hooks';
import { GuryouApiConfig } from '@guryou/api-client';
import App from 'App';
import Amplify, { Auth } from 'aws-amplify';
import { PersistGate } from 'redux-persist/integration/react';
import { persistor, store } from 'store';
import './i18n';
import client from './apolloClient';

const getAccessToken = async () => {
  try {
    const currentSession = await Auth.currentSession();
    return { Authorization: currentSession.getIdToken().getJwtToken() };
  } catch (error) {
    return {};
  }
};

GuryouApiConfig.getApiEndpoint = () => process.env.REACT_APP_WAAS_API_ENDPOINT;
GuryouApiConfig.getProxyApiEndpoint = () => process.env.REACT_APP_GRAPHQL_URL;
GuryouApiConfig.getAccessToken = getAccessToken;

const awsConfig = {
  API: {
    graphql_endpoint: process.env.REACT_APP_GRAPHQL_URL,
    graphql_headers: getAccessToken,
  },
  aws_project_region: process.env.REACT_APP_AWS_PROJECT_REGION,
  aws_cognito_region: process.env.REACT_APP_AWS_COGNITO_REGION,
  aws_user_pools_id: process.env.REACT_APP_AWS_USER_POOL_ID,
  aws_user_pools_web_client_id: process.env.REACT_APP_AWS_USER_POOL_WEB_CLIENT_ID,
  Auth: {
    authenticationFlowType: 'USER_PASSWORD_AUTH',
  },
};

Amplify.configure(awsConfig);

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <ApolloProvider client={client}>
        <ApolloHooksProvider client={client}>
          <App />
        </ApolloHooksProvider>
      </ApolloProvider>
    </PersistGate>
  </Provider>,
  document.getElementById('root')
);
