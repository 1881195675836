import React from 'react';
import { useTranslation } from 'react-i18next';
import { colors } from '@guryou/colors';
import { FlexColumn, FlexRow, LargeText } from '@guryou/html-components';
import BenefitsBackground from 'components/BackgroundFeelDeal/BenefitsBackground';
import Content from './Content';
import FullLogos from './FullLogos';
const LogosDivider = ({ width, tabletWidth, laptopWidth }) => {
  const { t } = useTranslation();

  return (
    <FlexColumn xs={12} height="auto" marginTop={20}>
      <BenefitsBackground rectangleColor={colors.lightgrey} trapezoidColorUp={colors.lightgrey} trapezoidColorDown={colors.lightgrey} />
      <FlexRow xs={12} height="auto" margin={0} marginTop={10} justifyContent="space-around" alignItems="center">
        <FullLogos width={width} tabletWidth={tabletWidth} />
      </FlexRow>
      <LargeText
        fontWeight={600}
        fontSize={width < 600 ? '1.5rem' : width < tabletWidth ? '1.8rem' : width < laptopWidth ? '2.4rem' : '2.6rem'}
        textAlign="center"
        marginBottom={10}
        marginTop={10}
        color={colors.mineShaft}
      >
        {t('NEWHOME.appBrand')}
      </LargeText>
      <FlexRow xs={12} md={12} margin={0} marginTop={5} justifyContent="space-around" alignItems="center" paddingBottom={20}>
        <Content width={width} tabletWidth={tabletWidth} />
      </FlexRow>
    </FlexColumn>
  );
};

export default LogosDivider;
