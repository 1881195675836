import React from 'react';
import { colors } from '@guryou/colors';
import { FlexRow } from '@guryou/html-components';
import { makeStyles } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { ButtonBack, ButtonNext, CarouselProvider, Slider } from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';

const useStyles = makeStyles(width => ({
  button: {
    borderRadius: '50%',
    width: '40px',
    height: '40px',
    border: 'none',
    '&:focus': {
      outline: 'none',
    },
  },
  slider: {
    minHeight: width < 650 && '275px',
    maxHeight: width < 650 && '275px',
    userSelect: 'none',
    '&:focus': {
      outline: 'none',
    },
  },
}));

const Carousel = ({ CarouselSlide, width, totalSlides }) => {
  const theme = useTheme();
  const { button, slider } = useStyles(width);

  return (
    <CarouselProvider
      style={{ width: '100%', height: '275px', marginTop: '24px' }}
      naturalSlideWidth={200}
      naturalSlideHeight={200}
      totalSlides={totalSlides}
      visibleSlides={width < 800 ? 1 : width < 1150 ? 2 : width < 1500 ? 3 : width < 1770 ? 4 : 5}
      infinite
    >
      <Slider
        className={slider}
        style={{ paddingLeft: width < 480 ? '0px' : '50px', paddingRight: width < 480 ? '0px' : '50px', height: width < 650 && '250px' }}
      >
        {CarouselSlide}
      </Slider>
      <FlexRow xs={11.7} justifyContent="flex-end">
        <ButtonBack className={{ ...button, backgroundColor: theme.palette.warning.main }}>
          <NavigateBeforeIcon style={{ color: colors.black }} />
        </ButtonBack>
        <ButtonNext className={{ ...button, backgroundColor: theme.palette.warning.main }} style={{ marginLeft: '15px' }}>
          <NavigateNextIcon style={{ color: colors.black }} />
        </ButtonNext>
      </FlexRow>
    </CarouselProvider>
  );
};

export default Carousel;
