"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.calculateSinglePrice = void 0;

var calculateSinglePrice = function calculateSinglePrice(gyClass, session) {
  var model = gyClass.classes_model || gyClass.classesModel;

  if (model === 'total') {
    return null;
  }

  if (model === 'hour') {
    var hours = +(session.sessions_duration || session.sessionsDuration) / 60;
    var price = +(gyClass.classes_price || gyClass.classesPrice);
    return hours * price;
  }

  return +(session.sessions_price || session.sessionsPrice);
};

exports.calculateSinglePrice = calculateSinglePrice;