"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.generateRandomString = exports.calculateContrastText = exports.hexToRgb = exports.sanitizeUrl = exports.sanitizeColor = exports.capitalizeFirstLetter = exports.tryParseJson = exports.isValidUrl = exports.stripHtml = exports.parseJwt = exports.isValidEmail = exports.generateHashCode = exports.parsePrice = exports.printPercent = exports.printPrice = exports.replaceDoubleSlashes = exports.parseAsBool = exports.getInitials = void 0;

var _typeof2 = _interopRequireDefault(require("@babel/runtime/helpers/typeof"));

var getInitials = function getInitials(fullName) {
  var maxCharacters = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 3;
  return ((fullName === null || fullName === void 0 ? void 0 : fullName.match(/\b\w/g)) || ['n', '/', 'a']).slice(0, maxCharacters).join('').toUpperCase();
};

exports.getInitials = getInitials;

var parseAsBool = function parseAsBool(val) {
  if (!val || typeof val === 'boolean') {
    return !!val;
  }

  return ['true', 'y', 'yes'].includes(val.toString().toLowerCase());
};

exports.parseAsBool = parseAsBool;

var replaceDoubleSlashes = function replaceDoubleSlashes(str) {
  return str && str.replace(/\/\//g, '/');
};

exports.replaceDoubleSlashes = replaceDoubleSlashes;

var printPrice = function printPrice(val) {
  var currency = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'EUR';

  if (val === null || val === undefined) {
    return null;
  }

  return new Intl.NumberFormat('it-IT', {
    style: 'currency',
    currency: currency
  }).format(val);
};

exports.printPrice = printPrice;

var printPercent = function printPercent(val) {
  var decimalPlaces = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 2;

  if (val === null || val === undefined) {
    return null;
  }

  if (decimalPlaces) {
    return "".concat(+val.toFixed(2), "%");
  }

  return "".concat(Math.floor(+val), "%");
};

exports.printPercent = printPercent;

var parsePrice = function parsePrice(val) {
  return parseFloat(val.replace('€', '').replace(',', '.').replace(/[.](?=.*[.])/g, ''));
};

exports.parsePrice = parsePrice;

var generateHashCode = function generateHashCode(value) {
  if (!value) {
    return '0';
  }

  var str = (0, _typeof2["default"])(value) === 'object' ? JSON.stringify(value) : value;
  var hash = 0,
      i,
      chr;

  for (i = 0; i < str.length; i++) {
    chr = str.charCodeAt(i);
    hash = (hash << 5) - hash + chr;
    hash |= 0; // Convert to 32bit integer
  }

  return hash;
};

exports.generateHashCode = generateHashCode;

var isValidEmail = function isValidEmail(email) {
  var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

exports.isValidEmail = isValidEmail;

var parseJwt = function parseJwt(token) {
  var base64Url = token.split('.')[1];
  var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  var jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
    return "%".concat(('00' + c.charCodeAt(0).toString(16)).slice(-2));
  }).join(''));
  return JSON.parse(jsonPayload);
};

exports.parseJwt = parseJwt;

var stripHtml = function stripHtml(str) {
  return str && str.replace(/(<([^>]+)>)/gi, '');
};

exports.stripHtml = stripHtml;

var isValidUrl = function isValidUrl(str) {
  if (!str) {
    return false;
  }

  var pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
  '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
  '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
  '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
  '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
  '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator

  return !!pattern.test(str);
};

exports.isValidUrl = isValidUrl;

var tryParseJson = function tryParseJson(str) {
  if ((0, _typeof2["default"])(str) === 'object') {
    return str;
  }

  try {
    return JSON.parse(str);
  } catch (_unused) {
    return null;
  }
};

exports.tryParseJson = tryParseJson;

var capitalizeFirstLetter = function capitalizeFirstLetter(str) {
  return str && str.charAt(0).toUpperCase() + str.slice(1);
};

exports.capitalizeFirstLetter = capitalizeFirstLetter;

var sanitizeColor = function sanitizeColor(color) {
  if (!color || color.startsWith('#')) {
    return color;
  }

  return "#".concat(color);
};

exports.sanitizeColor = sanitizeColor;

var sanitizeUrl = function sanitizeUrl(url) {
  if (!url || url.startsWith('http')) {
    return url;
  }

  return "https://".concat(url);
};

exports.sanitizeUrl = sanitizeUrl;

var hexToRgb = function hexToRgb(hex) {
  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result ? {
    r: parseInt(result[1], 16),
    g: parseInt(result[2], 16),
    b: parseInt(result[3], 16)
  } : null;
};

exports.hexToRgb = hexToRgb;

var calculateContrastText = function calculateContrastText(color) {
  var _hexToRgb = hexToRgb(color),
      r = _hexToRgb.r,
      g = _hexToRgb.g,
      b = _hexToRgb.b; // calculate contrast of color (standard grayscale algorithmic formula)


  var contrast = (Math.round(r * 299) + Math.round(g * 587) + Math.round(b * 114)) / 1000;
  return contrast >= 128 ? '#000' : '#fff';
};

exports.calculateContrastText = calculateContrastText;

var generateRandomString = function generateRandomString(length) {
  var result = [];
  var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  var charactersLength = characters.length;

  for (var i = 0; i < length; i++) {
    result.push(characters.charAt(Math.floor(Math.random() * charactersLength)));
  }

  return result.join('');
};

exports.generateRandomString = generateRandomString;