import { Action } from '../core/Action';
var ARCHIVED_PRODUCTS = {
  entity: 'ARCHIVED_PRODUCTS',
  action: Action.GET
};
var PRODUCT_ARCHIVE = {
  entity: 'PRODUCT',
  action: Action.ARCHIVE
};
var PRODUCT_CREATE = {
  entity: 'PRODUCT',
  action: Action.CREATE
};
var PRODUCT_FILE_PREVIEW = {
  entity: 'PRODUCT_FILE_PREVIEW',
  action: Action.GET
};
var PRODUCT_GET = {
  entity: 'PRODUCT',
  action: Action.GET
};
var PRODUCT_GROUP_CREATE = {
  entity: 'PRODUCT_GROUP',
  action: Action.CREATE
};
var PRODUCT_GROUP_DELETE = {
  entity: 'PRODUCT_GROUP',
  action: Action.DELETE
};
var PRODUCT_GROUP_UPDATE = {
  entity: 'PRODUCT_GROUP',
  action: Action.UPDATE
};
var PRODUCT_GROUPS_GET = {
  entity: 'PRODUCT_GROUPS',
  action: Action.GET
};
var PRODUCT_S3 = {
  entity: 'PRODUCT_S3',
  action: Action.CREATE
};
var PRODUCT_SECTION_CREATE = {
  entity: 'PRODUCT_SECTION',
  action: Action.CREATE
};
var PRODUCT_SECTION_DELETE = {
  entity: 'PRODUCT_SECTION',
  action: Action.DELETE
};
var PRODUCT_SECTION_UPDATE = {
  entity: 'PRODUCT_SECTION',
  action: Action.UPDATE
};
var PRODUCT_STATUS_UPDATE = {
  entity: 'PRODUCT_STATUS',
  action: Action.UPDATE
};
var PRODUCT_UPDATE = {
  entity: 'PRODUCT',
  action: Action.UPDATE
};
var PRODUCT_VIDEO_CREATE = {
  entity: 'PRODUCT_VIDEO',
  action: Action.CREATE
};
var PRODUCT_VIDEO_DELETE = {
  entity: 'PRODUCT_VIDEO',
  action: Action.DELETE
};
var PRODUCT_VIDEO_UPDATE = {
  entity: 'PRODUCT_VIDEO',
  action: Action.UPDATE
};
var PRODUCT_VIDEOS_GET_MANY = {
  entity: 'PRODUCT_VIDEOS',
  action: Action.GET_MANY
};
var PRODUCTS_GET_MANY = {
  entity: 'PRODUCT',
  action: Action.GET_MANY
};
var PRODUCTS_SECTIONS_GET_MANY = {
  entity: 'SECTIONS',
  action: Action.GET_MANY
};
var PRODUCT_FILES = {
  entity: 'PRODUCT_FILES',
  action: Action.GET_MANY
};
var RESTORE_PRODUCT = {
  entity: 'RESTORE_PRODUCT',
  action: Action.UPDATE
};
var SECTION_VIDEOS_GET = {
  entity: 'SECTION_VIDEOS',
  action: Action.GET
};
var SUB_SERVICES_REORDER = {
  entity: 'SUB_SERVICES_REORDER',
  action: Action.UPDATE
};
var CLASSES_REORDER = {
  entity: 'CLASSES_REORDER',
  action: Action.UPDATE
};
export { ARCHIVED_PRODUCTS, PRODUCT_ARCHIVE, PRODUCT_CREATE, PRODUCT_FILE_PREVIEW, PRODUCT_GET, PRODUCT_GROUP_CREATE, PRODUCT_GROUP_DELETE, PRODUCT_GROUP_UPDATE, PRODUCT_GROUPS_GET, PRODUCT_S3, PRODUCT_SECTION_CREATE, PRODUCT_SECTION_DELETE, PRODUCT_SECTION_UPDATE, PRODUCT_STATUS_UPDATE, PRODUCT_UPDATE, PRODUCT_VIDEO_CREATE, PRODUCT_VIDEO_DELETE, PRODUCT_VIDEO_UPDATE, PRODUCT_VIDEOS_GET_MANY, PRODUCTS_GET_MANY, PRODUCTS_SECTIONS_GET_MANY, PRODUCT_FILES, RESTORE_PRODUCT, SECTION_VIDEOS_GET, SUB_SERVICES_REORDER, CLASSES_REORDER };