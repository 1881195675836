import React from 'react';
import { colors } from '@guryou/colors';
import { FlexColumn, FlexRow, Image, LargeText, MediumText } from '@guryou/html-components';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';

const ArticleCard = ({ article, width, description, cardWidth, imageHeight, categoryTextColor }) => {
  return (
    <FlexRow key={article?.id} xs={cardWidth} margin={0} marginTop={width < 1100 ? 5 : 0}>
      <Image
        src={article?.image?.url}
        xs={width < 650 ? 5.5 : 5}
        height={width < 650 ? '150px' : imageHeight}
        borderRadius="1rem"
        objectFit="cover"
      />
      <FlexColumn xs={width < 650 ? 6.5 : 7} alignItems="flex-start" paddingLeft={width < 650 ? 2 : 5} margin={0} paddingRight={description ? 5 : 0}>
        <FlexColumn alignItems="flex-start" margin={0}>
          <MediumText
            color={width < 1100 ? colors.mineShaft : categoryTextColor}
            fontSize={width < 400 ? '0.8rem' : width < 650 ? '0.9rem' : '1rem'}
            fontWeight={400}
          >
            {article?.category?.name} - {article?.date}
          </MediumText>
          <LargeText fontSize={width < 400 ? '1.1rem' : width < 650 ? '1.4rem' : description ? '2rem' : '1.4rem'}>{article?.title}</LargeText>
          {description && width > 650 ? (
            <MediumText fontSize="1.2rem" fontWeight={400} marginTop={1}>
              {description}
            </MediumText>
          ) : (
            ''
          )}
        </FlexColumn>
        {width < 650 ? '' : <ArrowForwardIcon fontSize="large" style={{ verticalAlign: 'middle', marginTop: '20px' }} />}
      </FlexColumn>
    </FlexRow>
  );
};

export default ArticleCard;
