import React from 'react';
import Arrows from './Arrows';
import SliderDots from './SliderDots';
import Trapezoids from './Trapezoids';

const Background = ({
  firstSlideVisible,
  secondSlideVisible,
  thirdSlideVisible,
  fourthSlideVisible,
  clickFirstDot,
  clickSecondDot,
  clickThirdDot,
  clickFourthDot,
}) => {
  return (
    <>
      <Trapezoids
        firstSlideVisible={firstSlideVisible}
        secondSlideVisible={secondSlideVisible}
        thirdSlideVisible={thirdSlideVisible}
        fourthSlideVisible={fourthSlideVisible}
      />
      <SliderDots
        clickFirstDot={clickFirstDot}
        clickSecondDot={clickSecondDot}
        clickThirdDot={clickThirdDot}
        clickFourthDot={clickFourthDot}
        firstSlideVisible={firstSlideVisible}
        secondSlideVisible={secondSlideVisible}
        thirdSlideVisible={thirdSlideVisible}
        fourthSlideVisible={fourthSlideVisible}
      />
      <Arrows
        clickFirstDot={clickFirstDot}
        clickSecondDot={clickSecondDot}
        clickThirdDot={clickThirdDot}
        clickFourthDot={clickFourthDot}
        firstSlideVisible={firstSlideVisible}
        secondSlideVisible={secondSlideVisible}
        thirdSlideVisible={thirdSlideVisible}
        fourthSlideVisible={fourthSlideVisible}
      />
    </>
  );
};

export default Background;
