import React, { useState } from 'react';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { colors } from '@guryou/colors';
import { Block, FlexColumn, FlexRow, SmallText } from '@guryou/html-components';
import MobileFreePlanCard from './MobilePlanCards/MobileFreePlanCard';
import MobileSpecialPlanCard from './MobilePlanCards/MobileSpecialPlanCard';

const free = ['Ricevi prenotazioni dalla app YouFeel', 'Gestisci l’agenda e i clienti', 'Gestisci il personale', 'Integra tutto con il tuo sito web'];
const grow = [
  'Ricevi pagamenti online',
  'Imposta il periodo di cancellazione gratuita',
  'Offri abbonamenti ai tuoi clienti',
  'Gestisci le liste d’attesa',
  'Gestisci personale e collaboratori',
  'App YouDeal per i tuoi collaboratori',
];
const video = [
  'Crea e organizza i tuoi video-corsi',
  'Pubblicali in streaming on demand',
  'Offri un trailer gratuito',
  'Assegna video-corsi mirati ai singoli clienti',
];
const allInOne = [
  'Crea l’App del tuo business',
  'Scaricabile da Apple Store e Google Play',
  'Personalizza l’App con loghi e colori del tuo brand',
  'Aggiornamenti gratuiti',
];

const defaultCard = [true, false, false];

const MobilePricingPlanCards = ({ width, toggle }) => {
  const [card, setCard] = useState(defaultCard);
  const location = useLocation();

  const isRiminiOrDeal = location.pathname.includes('rimini-wellness') || location.pathname.includes('youdeal');

  const handleCardChange = index => {
    if (card[index]) {
      return;
    } else {
      const previousState = [...card];
      for (let i = 0; i < previousState.length; i++) {
        if (index === i) {
          previousState[i] = true;
        } else {
          previousState[i] = false;
        }
      }
      setCard(previousState);
    }
  };
  return (
    <>
      <MobileFreePlanCard
        width={width}
        plan={'Free'}
        price={'FOREVER'}
        description={'Crea il tuo profilo GurYou'}
        buttonText={'Iscriviti'}
        listOfBenefits={free}
      />
      <FlexRow margin={0} marginBottom={5}>
        <FlexColumn xs={4} margin={0} alignItems="space-between" onClick={() => handleCardChange(0)}>
          <SmallText fontWeight={500} fontSize="1.2rem" color={card[0] === true ? colors.mandy : ''}>
            Grow
          </SmallText>
          <Block
            width="100%"
            marginLeft={0}
            marginRight={0}
            marginBottom={0}
            height={card[0] === true ? '4px' : '2px'}
            backgroundColor={card[0] === true ? colors.mandy : isRiminiOrDeal ? colors.rimini : 'rgba(0,0,0,1)'}
          />
        </FlexColumn>
        <FlexColumn xs={4} margin={0} alignItems="space-between" onClick={() => handleCardChange(1)}>
          <SmallText fontWeight={500} fontSize="1.2rem" color={card[1] === true ? colors.mandy : ''}>
            +Video
          </SmallText>
          <Block
            width="100%"
            marginRight={0}
            marginBottom={0}
            marginLeft={0}
            height={card[1] === true ? '4px' : '2px'}
            backgroundColor={card[1] === true ? colors.mandy : isRiminiOrDeal ? colors.rimini : 'rgba(0,0,0,1)'}
          />
        </FlexColumn>
        <FlexColumn xs={4} margin={0} onClick={() => handleCardChange(2)}>
          <SmallText fontWeight={500} fontSize="1.2rem" color={card[2] === true ? colors.mandy : ''}>
            All In One
          </SmallText>
          <Block
            width="100%"
            marginLeft={0}
            marginBottom={0}
            marginRight={0}
            height={card[2] === true ? '4px' : '2px'}
            backgroundColor={card[2] === true ? colors.mandy : isRiminiOrDeal ? colors.rimini : 'rgba(0,0,0,1)'}
          />
        </FlexColumn>
      </FlexRow>
      {card[0] === true ? (
        <MobileSpecialPlanCard
          width={width}
          plan={'Grow'}
          price={toggle ? '29' : '69'}
          currency={'€/MESE'}
          description={'Tutte le funzionalità FREE'}
          buttonText={'Acquista'}
          listOfBenefits={grow}
          badge={false}
        />
      ) : card[1] === true ? (
        <MobileSpecialPlanCard
          width={width}
          plan={'+Video'}
          price={toggle ? '59' : '99'}
          currency={'€/MESE'}
          description={'Tutte le funzionalità FREE e GROW'}
          buttonText={'Acquista'}
          listOfBenefits={video}
          badge={true}
        />
      ) : (
        <MobileSpecialPlanCard
          width={width}
          plan={'All In One'}
          price={toggle ? '159' : '199'}
          currency={'€/MESE'}
          description={'Tutte le funzionalità FREE, GROW e +VIDEO'}
          buttonText={'Acquista'}
          listOfBenefits={allInOne}
          badge={false}
        />
      )}
    </>
  );
};

export default MobilePricingPlanCards;
