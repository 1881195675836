import React from 'react';
import { Image } from '@guryou/html-components';
import { LogosInfo } from './LogosInfo';
const Content = () => {
  return (
    <>
      {LogosInfo.map(item => (
        <Image borderRadius="20px" margin={1} src={item.image} key={item.id} width="80px" />
      ))}
    </>
  );
};

export default Content;
