import React from 'react';
import { colors } from '@guryou/colors';
import { motion } from 'framer-motion';
import useStyles from '../../makeStyles/BackgroundStyle';
import useFirstSlideStyles from '../../makeStyles/FirstSlideStyle';
import useFourthSlideStyle from '../../makeStyles/FourthSlideStyle';
import useSecondSlideStyle from '../../makeStyles/SecondSlideStyle';
import useThirdSlideStyleStart from '../../makeStyles/ThirdSlideStyleStart';

const Trapezoids = ({ firstSlideVisible, secondSlideVisible, thirdSlideVisible, fourthSlideVisible }) => {
  const classes = useStyles();
  const firstSlideClasses = useFirstSlideStyles();
  const secondSlideClasses = useSecondSlideStyle();
  const thirdSlideClasses = useThirdSlideStyleStart();
  const fourthSlideClasses = useFourthSlideStyle();

  const topTrapezoidLeftAnimate = {
    borderTop:
      firstSlideVisible || thirdSlideVisible ? `90vh solid ${colors[firstSlideVisible ? 'rimini' : 'mineShaft']}` : `0vh solid ${colors.mineShaft}`,
  };
  const bottomTrapezoidLeftAnimate = {
    borderBottom:
      firstSlideVisible || thirdSlideVisible
        ? `10.1vh solid ${firstSlideVisible ? colors.mineShaft : colors.fruitSalad}`
        : `0vh solid ${colors.mandy}`,
  };
  const topTrapezoidRightAnimate = {
    borderTop: secondSlideVisible || fourthSlideVisible ? `90vh solid ${colors.mandy}` : `0vh solid ${colors.mandy}`,
  };

  const bottomTrapezoidRightAnimate = {
    borderBottom: secondSlideVisible || fourthSlideVisible ? `10.1vh solid ${colors.mineShaft}` : `0vh solid ${colors.mineShaft}`,
  };

  const firstSlideBackgroundImage = {
    opacity: firstSlideVisible ? 1 : 0,
  };
  const secondSlideBackgroundImage = {
    opacity: secondSlideVisible ? 1 : 0,
  };
  const thirdSlideBackgroundImage = {
    opacity: thirdSlideVisible ? 1 : 0,
  };
  const fourthSlideBackgroundImage = {
    opacity: fourthSlideVisible ? 1 : 0,
  };
  const trapezoidTransition = {
    ease: 'easeOut',
    duration: 0.4,
  };
  const backgroundImageTransition = {
    ease: 'easeOut',
    delay: firstSlideVisible || thirdSlideVisible ? 0.4 : 0,
    duration: firstSlideVisible || thirdSlideVisible ? 0.4 : 0.01,
  };
  const backgroundImageTransitionSecondSlide = {
    ease: 'easeOut',
    delay: secondSlideVisible ? 0.4 : 0,
    duration: secondSlideVisible ? 0.4 : 0.01,
  };
  const backgroundImageTransitionFourthSlide = {
    ease: 'easeOut',
    delay: fourthSlideVisible ? 0.4 : 0,
    duration: fourthSlideVisible ? 0.4 : 0.01,
  };
  return (
    <>
      <motion.div className={classes.topTrapezoidLeft} animate={topTrapezoidLeftAnimate} transition={trapezoidTransition} />
      <div className={classes.bottomTrapezoidRightWrapper}>
        <motion.div className={classes.bottomTrapezoidRight} animate={bottomTrapezoidRightAnimate} transition={trapezoidTransition} />
      </div>
      <div className={classes.bottomTrapezoidLeftWrapper}>
        <motion.div className={classes.bottomTrapezoidLeft} animate={bottomTrapezoidLeftAnimate} transition={trapezoidTransition} />
      </div>
      <motion.div className={fourthSlideClasses.background} animate={fourthSlideBackgroundImage} transition={backgroundImageTransitionFourthSlide} />
      <motion.div className={secondSlideClasses.background} animate={secondSlideBackgroundImage} transition={backgroundImageTransitionSecondSlide} />
      <motion.div className={firstSlideClasses.background} animate={firstSlideBackgroundImage} transition={backgroundImageTransition} />
      <motion.div className={thirdSlideClasses.background} animate={thirdSlideBackgroundImage} transition={backgroundImageTransition} />
      <motion.div className={classes.topTrapezoidRight} animate={topTrapezoidRightAnimate} transition={trapezoidTransition} />
    </>
  );
};

export default Trapezoids;
