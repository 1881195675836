import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { colors } from '@guryou/colors';
import { Button, FlexColumn, FlexRow, MediumText, SearchIcon } from '@guryou/html-components';
import { TextField } from '@material-ui/core';
import { ArticleCardWithProps } from './ArticleCardWithProps';

const ArticleSection = ({ articles, width }) => {
  const { t } = useTranslation();
  const [articlesNumber, setArticlesNumber] = useState(12);
  const [allArticles, setAllArticles] = useState(articles);

  const handleSearch = e => {
    setAllArticles(articles.filter(article => article.title?.toLowerCase().includes(e.target.value.toLowerCase())));
  };

  useEffect(() => {
    setAllArticles(articles);
  }, [articles]);

  return (
    <>
      <FlexRow margin="auto" xs={9} padding={3} justifyContent="center">
        {/* <MediumText fontSize="2rem" fontWeight={500}>
          {articlesNumber > allArticles.length ? allArticles.length : articlesNumber}
        </MediumText> */}
        <MediumText fontSize={width < 600 ? '1.7rem' : width < 900 ? '1.9rem' : '2.8rem'} fontWeight={700} marginLeft={1}>
          {t('GENERAL.articles')}
        </MediumText>
      </FlexRow>
      <FlexRow justifyContent="center" alignItems="center" margin="auto" marginBottom={8} xs={9}>
        <TextField
          InputProps={{ startAdornment: <SearchIcon style={{ marginRight: 3 }} /> }}
          placeholder={t('HOME_READ_PAGE.readSearchPlaceholder')}
          onChange={e => handleSearch(e)}
        />
      </FlexRow>
      <FlexRow justifyContent="center" xs={9} margin="auto">
        {allArticles.map((card, index) => index < articlesNumber && <ArticleCardWithProps key={index} card={card} width={width} />)}
      </FlexRow>
      <FlexColumn alignItems="center" justifyContent="center" margin={10}>
        <Button
          onClick={() => {
            setArticlesNumber(articlesNumber + 4);
          }}
          disabled={!allArticles || articlesNumber > allArticles.length}
          backgroundColor={!allArticles || articlesNumber > allArticles.length ? colors.grey : colors.mandy}
          color={colors.white}
          paddingTop={3}
          paddingBottom={3}
          paddingLeft={4}
          paddingRight={4}
          textTransform="none"
          fontSize="1.4em"
          fontWeight={400}
        >
          {t('HOME_READ_PAGE.seeMore')}
        </Button>
      </FlexColumn>
    </>
  );
};

export default ArticleSection;
