import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { colors } from '@guryou/colors';
import { FlexColumn, Image } from '@guryou/html-components';
import { handleAnalyticsClick } from 'analytics';
import HeroImageDeal from 'assets/img/newImages/1.png';
import FindOutBtn from './FindOutBtn';
import FirstSlideSubtitle from './FirstSlideSubtitle';
import FirstSlideTitle from './FirstSlideTitle';
import useFirstSlideStyles from '../../newHomepageStyles/FirstSlideStyle';

const FirstSlide = ({ width }) => {
  const { t, i18n } = useTranslation();
  const slideClasses = useFirstSlideStyles({ topColor: 'rimini', botColor: 'light1' });
  return (
    <>
      {width > 950 ? (
        <div className={slideClasses.container}>
          <div className={slideClasses.topTrapezoid} />
          <div className={slideClasses.background} />
          <div className={slideClasses.slide}>
            <div className={slideClasses.leftContent}>
              <FlexColumn
                margin={0}
                xs={12}
                md={width < 950 ? 12 : 6}
                lg={6}
                marginTop={width < 600 ? '0' : '2vh'}
                alignItems="start"
                justifyContent="center"
              >
                <FirstSlideTitle width={width} text={t('NEWHOME.firstSectionMainText')} color={colors.light1} />
                <FirstSlideSubtitle
                  width={width}
                  text={t('NEWHOME.firstSectionSubText1')}
                  redText={t('NEWHOME.firstSectionSubText2')}
                  text2={t('NEWHOME.firstSectionSubText3')}
                  color={colors.light1}
                />
                <Link
                  to={`/${i18n.language}/youdeal`}
                  margin={0}
                  onClick={() =>
                    handleAnalyticsClick(
                      'Newhome hero section',
                      'Button clicked!',
                      'Find out button',
                      'Lead',
                      'Find out button on newhome first section clicked!'
                    )
                  }
                >
                  <FindOutBtn text={t('GENERAL.findHow')} color={colors.light1} textColor={colors.rimini} width={width} />
                </Link>
              </FlexColumn>
            </div>
          </div>
        </div>
      ) : (
        <FlexColumn position="relative" xs={12} margin={0} height="auto" alignItems="start">
          <FlexColumn backgroundColor={colors.rimini} xs={12} margin={0} padding={10}>
            <FirstSlideTitle width={width} text={t('NEWHOME.firstSectionMainText')} color={colors.light1} />
            <FirstSlideSubtitle
              width={width}
              text={t('NEWHOME.firstSectionSubText1')}
              redText={t('NEWHOME.firstSectionSubText2')}
              text2={t('NEWHOME.firstSectionSubText3')}
              color={colors.light1}
            />
            <Link
              to={`/${i18n.language}/youdeal`}
              margin={0}
              onClick={() =>
                handleAnalyticsClick(
                  'Newhome hero section',
                  'Button clicked!',
                  'Find out button',
                  'Lead',
                  'Find out button on newhome first section clicked!'
                )
              }
            >
              <FindOutBtn text={t('GENERAL.findHow')} color={colors.light1} textColor={colors.rimini} width={width} />
            </Link>
          </FlexColumn>
          <FlexColumn xs={12} margin={0}>
            <Image src={HeroImageDeal} backgroundRepeat="no-repeat" backgroundSize="cover" backgroundPosition="right" />
          </FlexColumn>
        </FlexColumn>
      )}
    </>
  );
};

export default FirstSlide;
