import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { colors } from '@guryou/colors';
import { FlexColumn, FlexRow, Image, LinkButton } from '@guryou/html-components';
import AppStore from '@guryou/html-components/src/assets/app-store.png';
import GooglePlay from '@guryou/html-components/src/assets/google-play.png';
import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import YouTubeIcon from '@material-ui/icons/YouTube';
import BackgroundLandingFooter from './BackgroundLandingFooter';
import LandingFooterText from './LandingFooterText';
import MobileGuryouLinks from './MobileGuryouLinks';
import GYLogoBlack from '../../assets/img/guryouLogos/gyLogo08.png';
import GYLogoWhite from '../../assets/img/guryouLogos/gyLogo09.png';

const LandingFooter = props => {
  const { t, i18n } = useTranslation();
  const { width, tabletWidth, homepage, color, textColor, feel } = props;

  const location = useLocation();

  const isRouteBusinessDeal = location.pathname.includes('businessDeal') || location.pathname.includes('rimini-wellness');

  return width < tabletWidth ? (
    <FlexColumn position="relative" alignItems="start">
      <BackgroundLandingFooter width={width} color={color} mobileWidth={12} />
      <FlexRow
        xs={width < 450 ? 12 : width < 600 ? 11 : 8}
        justifyContent="space-around"
        paddingTop={10}
        paddingRight={5}
        paddingBottom={0}
        margin={0}
        height="97px"
      >
        <LinkButton to={process.env.REACT_APP_GURYOU_FACEBOOK} target="_blank" height="40px">
          <FacebookIcon style={{ width: '40px', height: '40px', color: textColor }} />
        </LinkButton>
        <LinkButton to={process.env.REACT_APP_GURYOU_INSTAGRAM} target="_blank" height="40px">
          <InstagramIcon style={{ width: '40px', height: '40px', color: textColor }} />
        </LinkButton>
        <LinkButton to={process.env.REACT_APP_GURYOU_LINKEDIN} target="_blank" height="40px">
          <LinkedInIcon style={{ width: '40px', height: '40px', color: textColor }} />
        </LinkButton>
        <LinkButton to={process.env.REACT_APP_GURRYOU_YOUTUBE} target="_blank" height="40px">
          <YouTubeIcon style={{ width: '40px', height: '40px', color: textColor }} />
        </LinkButton>
      </FlexRow>
      <FlexColumn backgroundColor={color} xs={12} paddingTop={10} paddingBottom={10} alignItems="start" margin={0}>
        <LinkButton to="/">
          <Image src={textColor ? GYLogoWhite : GYLogoBlack} width="200px" padding={4} />
        </LinkButton>
        <MobileGuryouLinks textColor={textColor} text="GURYOU DEAL" link={`/${i18n.language}/youdeal`} />
        <MobileGuryouLinks textColor={textColor} text="GURYOU FEEL" link={`/${i18n.language}/youfeel`} />
        <MobileGuryouLinks textColor={textColor} text="GURYOU" link={`/${i18n.language}`} />
        <FlexRow width="316px" justifyContent="space-between" margin="auto" marginTop={5} marginBottom={20}>
          <LinkButton
            to={
              color === colors.rimini || color === colors.mineShaft
                ? process.env.REACT_APP_APPLE_STORE_DEAL
                : color === colors.mandy
                ? process.env.REACT_APP_APPLE_STORE_FEEL
                : process.env.REACT_APP_APPLE_STORE_START
            }
          >
            <Image src={AppStore} width="150px" marginTop={5} />
          </LinkButton>
          <LinkButton
            to={
              color === colors.rimini || color === colors.mineShaft
                ? process.env.REACT_APP_ANDROID_STORE_DEAL
                : color === colors.mandy
                ? process.env.REACT_APP_ANDROID_STORE_FEEL
                : process.env.REACT_APP_ANDROID_STORE_START
            }
          >
            <Image src={GooglePlay} width="150px" marginTop={5} />
          </LinkButton>
        </FlexRow>
      </FlexColumn>
    </FlexColumn>
  ) : (
    <FlexColumn position="relative" height={isRouteBusinessDeal ? '185px' : '450px'} alignItems="start">
      <BackgroundLandingFooter width={width} color={color} homepage={homepage} />
      <FlexRow xs={width < 1250 ? 5 : 4} justifyContent="space-around" marginTop={10} marginLeft={10} marginRight={10}>
        <LinkButton to={process.env.REACT_APP_GURYOU_FACEBOOK} target="_blank">
          <FacebookIcon style={{ width: '40px', height: '40px', color: textColor }} />
        </LinkButton>
        <LinkButton to={process.env.REACT_APP_GURYOU_INSTAGRAM} target="_blank">
          <InstagramIcon style={{ width: '40px', height: '40px', color: textColor }} />
        </LinkButton>
        <LinkButton to={process.env.REACT_APP_GURYOU_LINKEDIN} target="_blank">
          <LinkedInIcon style={{ width: '40px', height: '40px', color: textColor }} />
        </LinkButton>
        <LinkButton to={process.env.REACT_APP_GURRYOU_YOUTUBE} target="_blank">
          <YouTubeIcon style={{ width: '40px', height: '40px', color: textColor }} />
        </LinkButton>
      </FlexRow>
      {isRouteBusinessDeal ? (
        <FlexRow
          padding={width < 1250 ? 15 : 22}
          paddingBottom={25}
          paddingTop={0}
          backgroundColor={color}
          margin={0}
          alignItems="start"
          justifyContent="flex-end"
        >
          <FlexRow xs={7} justifyContent="space-around" alignItems="center">
            <LinkButton
              to={
                color === colors.rimini || color === colors.mineShaft
                  ? process.env.REACT_APP_APPLE_STORE_DEAL
                  : color === colors.mandy
                  ? process.env.REACT_APP_APPLE_STORE_FEEL
                  : process.env.REACT_APP_APPLE_STORE_START
              }
            >
              <Image src={AppStore} width="150px" marginTop={5} />
            </LinkButton>
            <LinkButton
              to={
                color === colors.rimini || color === colors.mineShaft
                  ? process.env.REACT_APP_ANDROID_STORE_DEAL
                  : color === colors.mandy
                  ? process.env.REACT_APP_ANDROID_STORE_FEEL
                  : process.env.REACT_APP_ANDROID_STORE_START
              }
            >
              <Image src={GooglePlay} width="150px" marginTop={5} />
            </LinkButton>
            <LinkButton to="/">
              <Image marginTop={5} src={textColor ? GYLogoWhite : GYLogoBlack} width="200px" />
            </LinkButton>
          </FlexRow>
        </FlexRow>
      ) : (
        <FlexRow
          padding={width < 1250 ? 15 : 22}
          paddingBottom={22}
          paddingTop={0}
          backgroundColor={color}
          margin={0}
          position="absolute"
          top="96px"
          alignItems="start"
        >
          <FlexColumn xs={3} marginTop={20} alignItems="start">
            <LinkButton to="/">
              <Image src={textColor ? GYLogoWhite : GYLogoBlack} width="200px" />
            </LinkButton>
            <LinkButton
              to={
                color === colors.rimini || color === colors.mineShaft
                  ? process.env.REACT_APP_APPLE_STORE_DEAL
                  : color === colors.mandy
                  ? process.env.REACT_APP_APPLE_STORE_FEEL
                  : process.env.REACT_APP_APPLE_STORE_START
              }
            >
              <Image src={AppStore} width="150px" marginTop={5} />
            </LinkButton>
            <LinkButton
              to={
                color === colors.rimini || color === colors.mineShaft
                  ? process.env.REACT_APP_ANDROID_STORE_DEAL
                  : color === colors.mandy
                  ? process.env.REACT_APP_ANDROID_STORE_FEEL
                  : process.env.REACT_APP_ANDROID_STORE_START
              }
            >
              <Image src={GooglePlay} width="150px" marginTop={5} />
            </LinkButton>
          </FlexColumn>
          <LandingFooterText
            color={textColor}
            text1="GURYOU FEEL"
            link1={`/${i18n.language}/youfeel`}
            text2={t('PROVIDERS.blog')}
            link2={`/${i18n.language}/category/all`}
            paddingLeft={width < 1250 ? 8 : 0}
          />
          <LandingFooterText
            color={textColor}
            text1="GURYOU DEAL"
            link1={`/${i18n.language}/youdeal`}
            text2={t('NEWSLETTER.subscribe')}
            link2={`/${i18n.language}/register`}
            paddingLeft={width < 1250 ? 8 : 0}
          />
          <LandingFooterText
            color={textColor}
            text1="GURYOU"
            link="/"
            text2={t('FOOTER.about')}
            link2={`/${i18n.language}/about`}
            text3={t('FOOTER.contacts')}
            link3={`/${i18n.language}/contact`}
            text4={t('FOOTER.termsOfService')}
            link4={process.env.REACT_APP_TERMS_AND_CONDITIONS}
            text5={t('FOOTER.privacyPolicy')}
            link5={process.env.REACT_APP_PRIVACY_POLICY}
            text6={t('FOOTER.cookiePolicy')}
            link6={process.env.REACT_APP_PRIVACY_COOKIE_POLICY}
            paddingLeft={width < 1250 ? 8 : 0}
            feel={feel}
          />
        </FlexRow>
      )}
    </FlexColumn>
  );
};

export default LandingFooter;
