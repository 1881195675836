import React from 'react';
import { useTranslation } from 'react-i18next';
import { colors } from '@guryou/colors';
import { LargeText } from '@guryou/html-components';

const List = ({ width }) => {
  const { t } = useTranslation();
  return (
    <LargeText
      color={colors.white}
      textAlign={width < 600 ? 'center' : 'left'}
      fontSize={width < 600 ? '1.8rem' : width < 800 ? '2.2rem' : '2.3rem'}
      fontWeight={700}
      lineHeight="1.1"
      marginBottom={5}
    >
      {t('SLIDER.slide1Text')}
    </LargeText>
  );
};

export default List;
