import React from 'react';
import { useTranslation } from 'react-i18next';
import { colors } from '@guryou/colors';
import { Block, FlexColumn, FlexRow } from '@guryou/html-components';
import Dot from './Dot';
import DotText from './DotText';

const Lines = ({ width }) => {
  const { t } = useTranslation();
  return (
    <>
      {width < 1100 ? (
        <FlexRow xs={width < 610 ? 10 : 5} justifyContent={width < 610 ? 'center' : ''}>
          <FlexColumn marginTop={20}>
            <FlexColumn>
              <Dot />
              <Block backgroundColor={colors.black} height="115px" width="1px" margin={0} />
            </FlexColumn>
            <FlexColumn>
              <Dot />
              <Block backgroundColor={colors.black} height="115px" width="1px" margin={0} />
            </FlexColumn>
            <FlexColumn>
              <Dot />
              <Block backgroundColor={colors.black} height="115px" width="1px" margin={0} />
            </FlexColumn>
            <FlexColumn>
              <Dot />
            </FlexColumn>
          </FlexColumn>
          <FlexColumn marginLeft={width < 610 ? 0 : 'auto'}>
            <DotText marginTop={18} width={width} text={t('YS_DOWNLOAD.dot1Start')} />
            <DotText marginTop={22} width={width} text={t('YS_DOWNLOAD.dot2Start')} />
            <DotText marginTop={22} width={width} text={t('YS_DOWNLOAD.dot3Start')} />
            <DotText marginTop={22} width={width} text={t('YS_DOWNLOAD.dot4Start')} />
          </FlexColumn>
        </FlexRow>
      ) : (
        <FlexRow position="absolute" top="360px" left="0" alignItems="center" margin={0}>
          <Block position="absolute" top="10px" height="2px" backgroundColor={colors.black} xs={12} margin={0} />
          <FlexColumn left="5%" top="-25px" position="absolute">
            <DotText text={t('YS_DOWNLOAD.dot1Start')} />
            <Dot />
          </FlexColumn>
          <FlexColumn left="20%" top="1px" position="absolute">
            <Dot />
            <DotText text={t('YS_DOWNLOAD.dot2Start')} />
          </FlexColumn>
          <FlexColumn right="20%" top="-52px" position="absolute">
            <DotText text={t('YS_DOWNLOAD.dot3Start')} />
            <Dot />
          </FlexColumn>
          <FlexColumn right="5%" top="1px" position="absolute">
            <Dot />
            <DotText text={t('YS_DOWNLOAD.dot4Start')} />
          </FlexColumn>
        </FlexRow>
      )}
    </>
  );
};

export default Lines;
