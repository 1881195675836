import React from 'react';
import { Slide } from 'pure-react-carousel';
import CenterCard from './CenterCard';
import { CenterCardInfo } from './CenterCardInfo';
const CarouselSlide = ({ width }) => {
  return CenterCardInfo.map((card, index) => (
    <Slide index={card.id} key={index}>
      <CenterCard key={card.id} name={card.name} job={card.job} image={card.image} description={card.description} width={width} />
    </Slide>
  ));
};

export default CarouselSlide;
