import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { FlexColumn } from '@guryou/html-components';
import { handleAnalyticsClick } from 'analytics';
import SubTitle from './SubTitle';
import Title from './Title';
import FindOutBtn from '../FindOutBtn';

const SectionText = ({ rest }) => {
  const { t, i18n } = useTranslation();
  const { width, left, mainText, subText, number, textColor, color, btnTextColor, url } = rest;
  return (
    <FlexColumn
      xs={12}
      md={6}
      padding={10}
      paddingTop={width < 900 && 5}
      margin={0}
      marginTop={width < 600 ? '0' : '2vh'}
      alignItems={width > 900 ? (left ? 'start' : 'end') : 'start'}
      justifyContent="center"
    >
      <Title textAlign={width > 900 ? (!left ? 'right' : 'left') : 'left'} width={width} text={mainText} color={textColor} />
      <SubTitle textAlign={width > 900 ? (!left ? 'right' : 'left') : 'left'} width={width} text={subText} color={textColor} />

      <Link
        to={`/${i18n.language}/${url}`}
        onClick={() =>
          handleAnalyticsClick(
            `Newhome ${number} section`,
            'Button clicked!',
            'Find out button',
            'Lead',
            `Find out button on newhome ${number} section clicked!`
          )
        }
      >
        <FindOutBtn width={width} text={t('GENERAL.findHow')} color={color} textColor={btnTextColor} />
      </Link>
    </FlexColumn>
  );
};

export default SectionText;
