import React from 'react';
import HubspotForm from 'react-hubspot-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { colors } from '@guryou/colors';
import { FlexColumn, LargeText, MediumText, Spinner } from '@guryou/html-components';
import { handleAnalyticsClick } from 'analytics';
import LandingFooter from 'components/landingFooter';
import Articles from './sections/articles';
import Benefits from './sections/benefits';
import Centers from './sections/centers';
import Hero from './sections/hero';
import Phone from './sections/phone';

const tabletWidth = 900;
const laptopWidth = 1300;

const Start = () => {
  const { t } = useTranslation();

  const { width } = useSelector(state => state.windowSize);
  return (
    <>
      <Hero width={width} tabletWidth={tabletWidth} laptopWidth={laptopWidth} />
      <Centers width={width} />
      <Benefits width={width} tabletWidth={tabletWidth} />
      <Phone width={width} tabletWidth={tabletWidth} laptopWidth={laptopWidth} />
      <Articles width={width} tabletWidth={tabletWidth} />

      <FlexColumn marginTop={10} marginBottom={20}>
        <LargeText fontWeight={700} fontSize={width < 500 ? '1.6rem' : width < tabletWidth ? '1.9rem' : '2.8rem'} textAlign="center" marginBottom={2}>
          {t('CONTACT_US.doYouNeedInfo')}
        </LargeText>
        <MediumText fontSize={width < 500 ? '1.0rem' : '1.4rem'} fontWeight={400} textAlign={width < tabletWidth ? 'center' : ''} marginBottom={10}>
          {t('CONTACT_US.enterYourDetails')}
        </MediumText>
        {/* for the form below to work, https://github.com/escaladesports/react-hubspot-form/issues/18, manually adding jquery was used as solution */}
        <HubspotForm
          portalId={process.env.REACT_APP_HUBSPOT_FORMS_PORTAL_ID}
          formId={process.env.REACT_APP_HUBSPOT_FEEL_FORM_ID}
          onSubmit={() => handleAnalyticsClick('YouFeel', 'Form submited!', 'Info user form', 'Contact', 'Info about user on YouFeel submited!')}
          loading={<Spinner />}
        />
      </FlexColumn>
      <LandingFooter width={width} tabletWidth={tabletWidth} textColor={colors.white} color={colors.fruitSalad} feel={true} />
    </>
  );
};

export default Start;
