import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import { Spinner } from '@guryou/html-components';

const Query = ({ children, query, slug, start, limit, noLoader }) => {
  const { data, loading, error } = useQuery(query, {
    variables: { slug: slug, start: start, limit: limit },
  });

  if (loading) {
    return noLoader ? '' : <Spinner />;
  }
  if (error) {
    return <p>{error?.message}</p>;
  }
  return children({ data });
};

export { Query };
