import React from 'react';
import { useTranslation } from 'react-i18next';
import { colors } from '@guryou/colors';
import { FlexColumn, FlexRow, LargeText } from '@guryou/html-components';
import BenefitsBackground from 'components/BackgroundFeelDeal/BenefitsBackground';
import Content from './Content';
const AboutUs = ({ width, tabletWidth, laptopWidth, backgroundColor, textColor }) => {
  const { t } = useTranslation();

  return (
    <FlexColumn xs={12} position="relative" backgroundColor={backgroundColor}>
      <BenefitsBackground rectangleColor={colors.light1} trapezoidColorUp={colors.light1} trapezoidColorDown={colors.light1} />
      <LargeText
        fontWeight={900}
        fontSize={width < 600 ? '1.5rem' : width < tabletWidth ? '2rem' : width < laptopWidth ? '2.4rem' : '2.7rem'}
        textAlign="center"
        marginBottom={10}
        marginTop={15}
        color={textColor || colors.mineShaft}
      >
        {t('NEWHOME.aboutUs')}
      </LargeText>
      <FlexRow margin={0} marginTop={5} justifyContent="space-around" alignItems="start" paddingBottom={20}>
        <Content width={width} tabletWidth={tabletWidth} textColor={textColor || colors.mineShaft} />
      </FlexRow>
    </FlexColumn>
  );
};

export default AboutUs;
