import React from 'react';
import { Block } from '@guryou/html-components';

const BenefitsBackground = ({ rectangleColor, trapezoidColorUp, trapezoidColorDown, height, top }) => {
  return (
    <>
      <Block margin={0} top={top} backgroundColor={rectangleColor} position="absolute" height={height || '100%'} xs={12} zIndex="-1">
        <Block
          borderBottom={`100px solid ${trapezoidColorDown}`}
          borderLeft="0px solid transparent"
          borderRight="90px solid transparent"
          width="28vw"
          margin={0}
          height="0"
          position="absolute"
          bottom="0"
          left="0"
        />
        <Block
          borderTop={`100px solid ${trapezoidColorUp}`}
          borderLeft="90px solid transparent"
          borderRight="0px solid transparent"
          width="30vw"
          margin={0}
          height="0"
          position="absolute"
          top="0"
          right="0"
        />
      </Block>
    </>
  );
};

export default BenefitsBackground;
