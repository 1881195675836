import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { FlexColumn, FlexRow, SmallText } from '@guryou/html-components';
import ConnectedDots from './ConnectedDots';

const tabletWidth = 900;

const DotsWithText = () => {
  const { t } = useTranslation();
  const { width } = useSelector(state => state.windowSize);

  return (
    <Wrapper padding="10%" parent={true}>
      <ConnectedDots width={width} tabletWidth={tabletWidth} />
      <Wrapper width={width}>
        <SmallText
          xs={width < tabletWidth ? 12 : 3}
          margin={3}
          paddingLeft={2}
          paddingRight={2}
          fontWeight={400}
          fontSize="1.2rem"
          textAlign={width < tabletWidth && 'left'}
          marginTop={width < tabletWidth && 0}
        >
          <Text text={t('ABOUT_PAGE.dotText1')} />
        </SmallText>
        <SmallText
          xs={width < tabletWidth ? 12 : 3}
          margin={3}
          paddingLeft={2}
          paddingRight={2}
          fontWeight={400}
          fontSize="1.2rem"
          textAlign={width && 'left'}
        >
          <Text text={t('ABOUT_PAGE.dotText2')} />
        </SmallText>
        <SmallText
          xs={width < tabletWidth ? 12 : 3}
          margin={3}
          paddingLeft={2}
          paddingRight={2}
          fontWeight={400}
          fontSize="1.2rem"
          textAlign={width < tabletWidth && 'left'}
        >
          <Text text={t('ABOUT_PAGE.dotText3')} />
        </SmallText>
        <SmallText
          xs={width < tabletWidth ? 12 : 3}
          margin={3}
          paddingLeft={2}
          paddingRight={2}
          fontWeight={400}
          fontSize="1.2rem"
          textAlign={width < tabletWidth && 'left'}
        >
          <Text text={t('ABOUT_PAGE.dotText4')} />
        </SmallText>
      </Wrapper>
    </Wrapper>
  );
};

export default DotsWithText;

const Wrapper = ({ children, width, parent = false, ...rest }) =>
  width < tabletWidth ? (
    <FlexColumn xs={!parent && 9} {...rest}>
      {children}
    </FlexColumn>
  ) : (
    <FlexRow xs={!parent && 12} textAlign="center" alignItems="flex-start" {...rest}>
      {children}
    </FlexRow>
  );

const Text = ({ text }) => (
  <>
    <strong>GurYou</strong> {text}
  </>
);
