import React from 'react';
import { useTranslation } from 'react-i18next';
import { LargeText } from '@guryou/html-components';

const TitleBusinessDeal = ({ width, laptopWidth, color, isRimini, isGuryouDeal }) => {
  const { t } = useTranslation();

  return (
    <LargeText
      width={width < 600 ? '100%' : '55%'}
      color={color}
      fontSize={width < 600 ? '2rem' : width < laptopWidth ? '2.3rem' : '2.7rem'}
      fontWeight={900}
      lineHeight="1.1"
      marginTop={5}
      marginBottom={5}
    >
      {isGuryouDeal
        ? t('CONTENT.howAreYouPreparingForTheFuture')
        : isRimini
        ? t('CONTENT.areYouReadyForWellness')
        : t('CONTENT.launchAppInLessThan4Weeks')}
    </LargeText>
  );
};

export default TitleBusinessDeal;
