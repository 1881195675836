import React from 'react';
import { Button } from '@guryou/html-components';
import DoubleArrowIcon from '@material-ui/icons/DoubleArrow';
const FindOutBtn = ({ text, color, textColor, width }) => {
  return (
    <Button
      margin={0}
      width={width < 600 ? '170px' : width < 800 ? '180px' : '190px'}
      backgroundColor={color}
      textTransform="none"
      fontSize={width < 600 ? '0.8rem' : width < 800 ? '1rem' : '1.2rem'}
      fontWeight={700}
      paddingTop={width < 600 ? 2 : width < 800 ? 3 : 4}
      paddingBottom={width < 600 ? 2 : width < 800 ? 3 : 4.5}
      color={textColor}
      boxShadow="4px 4px 4px rgba(0, 0, 0, 0.3)"
      borderRadius="0.2em"
    >
      {text} <DoubleArrowIcon fontSize={width < 800 ? 'default' : 'large'} style={{ marginTop: '2px', marginLeft: '15px', color: textColor }} />
    </Button>
  );
};

export default FindOutBtn;
