import React from 'react';
import { FlexColumn } from '@guryou/html-components';
import { TextField } from '@material-ui/core';
import useStyles from '../makeStyles/RegisterStyle';

const CustomSelectOption = ({ text, name, required, error, errorMsg, children, handleChange, value }) => {
  const { textField, labelStyle } = useStyles();
  return (
    <FlexColumn xs={12} marginTop={2}>
      <TextField
        id="select"
        select
        value={value}
        InputProps={{
          className: textField,
        }}
        InputLabelProps={{
          className: labelStyle,
        }}
        label={text}
        name={name}
        required={required}
        onChange={handleChange}
        children={children}
        error={error}
        helperText={errorMsg}
      >
        {children}
      </TextField>
    </FlexColumn>
  );
};

export default CustomSelectOption;
