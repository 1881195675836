import React from 'react';
import { useTranslation } from 'react-i18next';
import { Block, FlexRow } from '@guryou/html-components';
import laptop from 'assets/img/CalendarioYouDeal.svg';
import MyBenefit from './MyBenefit';

const Benefits = ({ width, tabletWidth, laptopWidth }) => {
  const { t } = useTranslation();
  const handleWidth = () => {
    if (width < 380) {
      return '250px';
    }
    if (width < tabletWidth) {
      return '330px';
    }
    return '320px';
  };
  return (
    <>
      <FlexRow>
        {[1, 2].map(ind => (
          <MyBenefit
            key={ind}
            title={t(`YD_SECTION_TWO.sectionTwoTitle${ind}`)}
            text={t(`YD_SECTION_TWO.sectionTwoText${ind}`)}
            // Icon={<BusinessCenterIcon fontSize="large" htmlColor={colors.lightningYellow} />}
            width={width}
            tabletWidth={tabletWidth}
            laptopWidth={laptopWidth}
          />
        ))}

        <Block
          backgroundImage={`url(${laptop})`}
          backgroundSize="cover"
          backgroundPosition="50% 50%"
          xs={width < tabletWidth ? 12 : 5.7}
          paddingLeft={6}
          paddingRight={6}
          height={handleWidth()}
          marginTop={0}
        ></Block>

        {[3, 4, 5, 6, 7, 8, 9, 10].map(ind => (
          <MyBenefit
            key={ind}
            title={t(`YD_SECTION_TWO.sectionTwoTitle${ind}`)}
            text={t(`YD_SECTION_TWO.sectionTwoText${ind}`)}
            // Icon={<BusinessCenterIcon fontSize="large" htmlColor={colors.lightningYellow} />}
            width={width}
            tabletWidth={tabletWidth}
            laptopWidth={laptopWidth}
          />
        ))}
      </FlexRow>
      {/* <FlexRow margin={0} xs={width < tabletWidth ? 12 : 9}>
        <Benefit
          title={t('YD_PLATFORM.forYourBusiness')}
          text={t('YD_PLATFORM.aUnifiedAndFreePlatform')}
          Icon={<BusinessCenterIcon fontSize="large" htmlColor={colors.lightningYellow} />}
          width={width}
          tabletWidth={tabletWidth}
          laptopWidth={laptopWidth}
          position={'left'}
        />
        <Benefit
          title={t('YD_PLATFORM.forYourCustomers')}
          text={t('YD_PLATFORM.aTailorMade')}
          Icon={<GroupIcon fontSize="large" htmlColor={colors.lightningYellow} />}
          width={width}
          tabletWidth={tabletWidth}
          laptopWidth={laptopWidth}
          position={'center'}
        />
        <Block
          backgroundImage={`url(${laptop})`}
          backgroundSize="cover"
          backgroundPosition="50% 50%"
          xs={width < tabletWidth ? 12 : 3}
          height={handleWidth()}
          marginTop={width < tabletWidth ? 10 : 0}
        ></Block>
      </FlexRow>
      {/* <Benefit
        title={t('YD_PLATFORM.forYourCollaborators')}
        text={t('YD_PLATFORM.toManageCalendar')}
        Icon={<NotificationsIcon fontSize="large" htmlColor={colors.lightningYellow} />}
        width={width}
        tabletWidth={tabletWidth}
        laptopWidth={laptopWidth}
        position={'right'}
      /> */}
    </>
  );
};

export default Benefits;
