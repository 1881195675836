export var MODAL_OPEN = 'MODAL_OPEN';
export var MODAL_CLOSE = 'MODAL_CLOSE';
export var MODAL_RESET = 'MODAL_RESET';
export var MODAL_DEFAULT_OPENED = 'MODAL_DEFAULT_OPENED';
export var MODAL_DEFAULT_EXTENDED_OPENED = 'MODAL_DEFAULT_EXTENDED_OPENED';

var openModal = function openModal(id, options, title) {
  return function (dispatch) {
    return dispatch({
      type: MODAL_OPEN,
      payload: {
        id: id,
        options: options,
        title: title
      }
    });
  };
};

var closeModal = function closeModal(id) {
  return function (dispatch) {
    return dispatch({
      type: MODAL_CLOSE,
      payload: {
        id: id
      }
    });
  };
};

var resetModal = function resetModal() {
  return function (dispatch) {
    return dispatch({
      type: MODAL_RESET
    });
  };
};

export { openModal, closeModal, resetModal };