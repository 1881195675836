import { ActionStatus, buildActionType, StateStatus } from '../core';
import { PROVIDER_IN, PROVIDER_NOT_AUTHORIZED, PROVIDER_OUT } from '../types';

var providerAuth = function providerAuth() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {
    data: null,
    status: StateStatus.NOT_INITIALIZED,
    error: null
  };
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case buildActionType(PROVIDER_IN, ActionStatus.START):
      return {
        status: StateStatus.LOADING,
        data: null,
        error: null
      };

    case buildActionType(PROVIDER_IN, ActionStatus.DONE):
      return {
        status: StateStatus.LOADED,
        data: action.payload,
        error: null
      };

    case buildActionType(PROVIDER_IN, ActionStatus.FAILED):
      return {
        status: StateStatus.ERROR,
        error: action.payload
      };

    case PROVIDER_OUT:
      state = null;
      return {
        status: StateStatus.NOT_INITIALIZED,
        data: null,
        error: null
      };

    case PROVIDER_NOT_AUTHORIZED:
      state = null;
      return {
        status: StateStatus.NOT_INITIALIZED,
        data: null,
        error: PROVIDER_NOT_AUTHORIZED
      };

    default:
      return state;
  }
};

export { providerAuth };