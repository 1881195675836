import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import { FlexRow, ImageSmall, OutlinedButton } from '@guryou/html-components';
import English from 'assets/img/english.png';
import Italy from 'assets/img/italy.jpg';

const Languages = () => {
  const { i18n } = useTranslation();
  const [value, setValue] = useState(localStorage.getItem('i18nextLng') || 'it');
  const location = useLocation();
  const history = useHistory();

  const handleLanguageChange = language => {
    i18n.changeLanguage(language);
    const pathname = location.pathname.split('/');
    pathname[1] = language;
    const newUrl = pathname.join('/');
    setValue(language);
    history.push(newUrl);
  };

  useEffect(() => {
    if (
      !location?.pathname.split('/').includes('verify') &&
      !location?.pathname.split('/').includes('reset') &&
      !location?.pathname.split('/').includes('it') &&
      !location?.pathname.split('/').includes('en')
    ) {
      const pathname = location.pathname.split('/');
      pathname[0] = 'it';
      const newUrl = pathname.join('/');
      history.push(`/${newUrl}`);
    }
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    setValue(i18n.language);
  }, [i18n.language]);

  return (
    <FlexRow md={2} xs={4} justifyContent="flex-start" flexWrap="nowrap">
      <OutlinedButton borderColor={value === 'it' ? 'white' : 'transparent'} onClick={() => handleLanguageChange('it')}>
        <ImageSmall src={Italy} />
      </OutlinedButton>
      <OutlinedButton borderColor={value === 'en' ? 'white' : 'transparent'} onClick={() => handleLanguageChange('en')}>
        <ImageSmall src={English} />
      </OutlinedButton>
    </FlexRow>
  );
};

export default Languages;
