import React from 'react';
import { colors } from '@guryou/colors';
import { FormControl, OutlinedInput } from '@material-ui/core';

const SearchField = ({ startAndor, placeholder, marginLeft, marginBottom, width, onChange, inputKey, name, inputRef }) => {
  return (
    <FormControl
      style={{ marginLeft: marginLeft, marginBottom: marginBottom, width: width > 899 && width < 1180 ? '280px' : '45%' }}
      variant="outlined"
    >
      <OutlinedInput
        key={inputKey}
        name={name}
        style={{ backgroundColor: colors.white, borderRadius: '0.5em', boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.3)' }}
        placeholder={placeholder}
        startAdornment={startAndor}
        labelWidth={0}
        onChange={onChange}
        inputRef={inputRef}
      />
    </FormControl>
  );
};

export default SearchField;
