import React from 'react';
import { Button } from '@guryou/html-components';
import DoubleArrowIcon from '@material-ui/icons/DoubleArrow';
const FindOutBtn = ({ text, color, textColor, width }) => {
  return (
    <Button
      margin={0}
      width={width < 1350 ? '200px' : '240px'}
      backgroundColor={color}
      textTransform="none"
      fontSize={width < 1350 ? '1rem' : '1.2rem'}
      fontWeight={700}
      paddingLeft={2}
      paddingRight={2}
      paddingTop={4.5}
      paddingBottom={4.5}
      color={textColor}
      boxShadow="4px 4px 4px rgba(0, 0, 0, 0.3)"
      borderRadius={width < 1350 ? '0.2em' : '0.2em'}
    >
      {text} <DoubleArrowIcon fontSize="large" style={{ marginTop: '2px', marginLeft: '15px', color: textColor }} />
    </Button>
  );
};

export default FindOutBtn;
