"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.PromoCodeDuration = exports.StripePlans = exports.PaymentTypes = exports.PaymentSettings = void 0;
var PaymentSettings = {
  NoPayments: 'off',
  OnlineOnly: 'on',
  Optional: 'on,off',
  Membership: 'membership'
};
exports.PaymentSettings = PaymentSettings;
var PaymentTypes = {
  Membership: 'membership',
  Card: 'card'
};
exports.PaymentTypes = PaymentTypes;
var StripePlans = {
  Free: 0,
  Grow: 1,
  Video: 2,
  AllInOne: 3
};
exports.StripePlans = StripePlans;
var PromoCodeDuration = {
  forever: 'forever',
  repeating: 'repeating',
  once: 'once'
};
exports.PromoCodeDuration = PromoCodeDuration;