import React from 'react';
import { usePlacesWidget } from 'react-google-autocomplete';
import { useTranslation } from 'react-i18next';
import { colors } from '@guryou/colors';
import { CustomButton, FlexColumn, FlexRow, MediumText } from '@guryou/html-components';
import RoomOutlinedIcon from '@material-ui/icons/RoomOutlined';
import SearchIcon from '@material-ui/icons/Search';
import SearchField from './SearchField';
import ToggleSwitch from './toggleSwitch';

const Filters = ({ width, tabletWidth, onChange, switchValue, handleKeywordChange, handleAddressChange, onSubmit }) => {
  const { t } = useTranslation();

  const { ref } = usePlacesWidget({
    apiKey: process.env.REACT_APP_GOOGLE_MAPS_API_TOKEN,
    onPlaceSelected: place => handleAddressChange(place),
  });

  return (
    <FlexColumn alignItems="center" xs={12} marginTop={2}>
      <MediumText fontSize={width < 450 ? '1.1rem' : '2rem'} marginTop={1} marginBottom={width < tabletWidth ? 2 : 0}>
        {t('YF_SEARCH.whatAreYouLookingFor')}
      </MediumText>
      <FlexRow justifyContent="center">
        <ToggleSwitch width={width} tabletWidth={tabletWidth} onChange={onChange} switchValue={switchValue} />
      </FlexRow>
      <Wrapper width={width} alignItems="start" marginTop={width < tabletWidth ? 7 : 10} tabletWidth={tabletWidth} up={false}>
        <SearchField
          name="keyword"
          inputKey="keyword"
          placeholder={t('CONTENT.keyword')}
          marginBottom={width < tabletWidth ? 20 : 0}
          startAndor={<SearchIcon style={{ marginRight: '10px' }} />}
          width={width}
          onChange={handleKeywordChange}
        />
        <SearchField
          inputKey="address"
          name="address"
          placeholder={t('CONTENT.nearby')}
          startAndor={<RoomOutlinedIcon style={{ marginRight: '10px' }} />}
          marginLeft={width < tabletWidth ? '0' : '20px'}
          marginBottom={width < tabletWidth ? 20 : 0}
          width={width}
          inputRef={ref}
        />
        <CustomButton
          margin={0}
          marginLeft={width < 650 ? 'auto' : 5}
          marginRight={width < 650 && 'auto'}
          marginTop={width < 900 && '30px'}
          backgroundColor={colors.mineShaft}
          width="140px"
          fontSize="0.9rem"
          fontWeight={600}
          paddingTop={5}
          paddingBottom={5}
          boxShadow="0px 0px 10px rgba(0, 0, 0, 0.3)"
          color={colors.white}
          onClick={onSubmit}
        >
          {t('YF_SEARCH.searchFor')}
        </CustomButton>
      </Wrapper>
    </FlexColumn>
  );
};

const Wrapper = ({ children, width, parent = false, tabletWidth, up, ...rest }) => {
  let mobileSizeUp;

  let mobileSizeDown;

  if (width < 1500) {
    mobileSizeUp = 3.1;
    mobileSizeDown = 11;
  }

  if (width < 1200) {
    mobileSizeUp = 3.5;
    mobileSizeDown = 11;
  }

  if (width > 1500) {
    mobileSizeUp = 2.1;
    mobileSizeDown = 11;
  }

  if (width < 650) {
    mobileSizeUp = 9.2;
    mobileSizeDown = 11;
  }

  return width < tabletWidth ? (
    <FlexColumn xs={!parent && 12} {...rest}>
      {children}
    </FlexColumn>
  ) : (
    <FlexRow
      xs={!parent && up ? mobileSizeUp : mobileSizeDown}
      textAlign="center"
      alignItems="center"
      justifyContent={up ? 'flex-start' : 'center'}
      {...rest}
    >
      {children}
    </FlexRow>
  );
};

export default Filters;
