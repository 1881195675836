import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Block, FlexColumn, Image, MediumText, SmallText } from '@guryou/html-components';
import { PROVIDER_GET_GENERAL_INFO, PROVIDER_LATEST_WIDGET, resetState } from '@guryou/redux';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { motion } from 'framer-motion';
import './CenterCard.css';

const CenterCard = ({ id, title, address, image, width, description }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { i18n } = useTranslation();

  const [isHoverOn, setHoverOn] = useState(false);

  const handleProviderClick = e => {
    e.preventDefault();
    dispatch(resetState(PROVIDER_GET_GENERAL_INFO));
    dispatch(resetState(PROVIDER_LATEST_WIDGET));
    return history.push(`/${i18n.language}/provider/${id}`);
  };

  return (
    <motion.div onHoverEnd={() => setHoverOn(false)}>
      <FlexColumn
        xs={2}
        marginTop={5}
        minHeight="390px"
        minWidth={width < 400 ? '290px' : '300px'}
        boxShadow="0px 0px 20px rgba(0, 0, 0, 0.3)"
        borderRadius="0.5rem"
      >
        <a href="/" onClick={handleProviderClick}>
          <Block height={isHoverOn ? '0px' : '160px'} opacity={isHoverOn ? 0 : 1} transition="all .4s ease-in-out" margin={0}>
            <Image objectFit="cover" src={image} xs={12} clipPath="polygon(0 0, 100% 0, 100% 170px, 0 140px)" />
          </Block>
        </a>
        <FlexColumn
          margin={0}
          xs={12}
          padding={5}
          paddingBottom={2}
          height={isHoverOn ? '390px' : '210px'}
          transition="all .4s ease-in-out"
          alignItems="flex-start"
        >
          <MediumText fontSize="1rem" fontWeight={700}>
            {title}
          </MediumText>
          <SmallText fontSize="0.8rem" fontWeight={400} fontStyle="italic">
            {address}
          </SmallText>
          <SmallText
            fontSize="0.8rem"
            paddingRight={5}
            fontWeight={400}
            overflowY={isHoverOn ? 'scroll' : 'hidden'}
            transition="all .4s ease-in-out"
            marginTop={3}
            className="scrollable"
          >
            {description}
          </SmallText>
        </FlexColumn>
        <motion.div
          style={{ marginTop: '5px' }}
          onHoverStart={() => setHoverOn(true)}
          animate={isHoverOn ? { rotate: 180 } : { rotate: 0 }}
          onClick={width < 900 ? () => setHoverOn(!isHoverOn) : () => ''}
        >
          <ExpandMoreIcon />
        </motion.div>
      </FlexColumn>
    </motion.div>
  );
};

export default CenterCard;
