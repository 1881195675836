import React from 'react';
import { MediumText } from '@guryou/html-components';
const SubTitle = ({ width, text, color, textAlign }) => {
  return (
    <MediumText
      width="100%"
      color={color}
      fontSize={width < 600 ? '1.2rem' : width < 1200 ? '1.4rem' : '2.4rem'}
      fontWeight={500}
      textAlign={textAlign ? textAlign : 'left'}
      lineHeight="1.1"
      marginBottom={5}
    >
      {text}
    </MediumText>
  );
};

export default SubTitle;
