import { colors } from '@guryou/colors';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  textField: {
    color: colors.mineShaft,
    borderBottom: `1px solid ${colors.mandy}`,
    '&:hover:before': {
      borderBottom: `2px solid ${colors.mandy} !important`,
    },
  },
  labelStyle: {
    color: colors.mineShaft,
  },
  contentDiv: {
    opacity: 0,
  },
}));

export default useStyles;
