import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, withRouter } from 'react-router-dom';
import { colors } from '@guryou/colors';
import { UserTypes } from '@guryou/core';
import { useEffectOnce } from '@guryou/hooks';
import {
  Button,
  ContainedButton,
  FlexColumn,
  FlexRow,
  Form,
  FormActions,
  LargeText,
  MediumText,
  PaperBlock,
  PasswordBox,
  SmallText,
  Spinner,
} from '@guryou/html-components';
// import AppStore from '@guryou/html-components/src/assets/app-store.png';
// import GooglePlay from '@guryou/html-components/src/assets/google-play.png';
import { cognitoFirstLoginSetPassword, cognitoVerifyUser, stateIsLoaded, stateIsLoading } from '@guryou/redux';
import { cognitoPasswordRequirements } from '@guryou/utils';
import { Box, Paper } from '@material-ui/core';
import { persistor } from 'store';
// import FeelLogo from '../../assets/img/guryouIcons/gyIcon43.png';

const defaultState = {
  password: '',
  confirmPassword: '',
};

const VerifyPassword = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [ls, setLs] = useState(defaultState);
  const firstLogin = useSelector(state => state.cognito.firstLogin);
  // const { width } = useSelector(state => state.windowSize);
  const { email, code } = useParams();

  useEffectOnce(() => {
    cognitoVerifyUser(email, code).then(user => setLs(o => ({ ...o, user })));
  });

  const handleInputsChange = (value, e, input) => {
    e.persist();
    setLs(prevState => ({ ...prevState, [input]: value }));
  };

  const handleSubmit = async data => {
    const { newPassword } = data;
    dispatch(cognitoFirstLoginSetPassword({ newPassword }, ls.user, persistor));
  };

  const passwordStrength = cognitoPasswordRequirements(ls.password);
  const checkIfPasswordMatch = ls.password === ls.confirmPassword;

  const successFields = (
    <FlexColumn alignItems="center">
      {firstLogin?.data?.userRole === UserTypes.owner || firstLogin?.data?.userRole === UserTypes.collaborator ? (
        <>
          <LargeText color={colors.white} fontSize="50px">
            {t('WELCOME.titlePlural')}
          </LargeText>
          <Button
            onClick={() => (window.location = `${process.env.REACT_APP_DEAL_URL}/login`)}
            xs={4}
            text={t('MODALS.login')}
            backgroundColor={colors.white}
            paddingTop={4}
            paddingRight={3}
            paddingLeft={3}
            paddingBottom={4}
            marginTop={3}
            fontSize="1.5rem"
          />
        </>
      ) : (
        <Paper component={Box} marginBottom={4} padding={2} textAlign="center">
          <LargeText marginTop="25px" marginBottom="15px" color={colors.mineShaft}>
            {t('WELCOME.titlePlural')}
          </LargeText>
          {/* <MediumText marginTop={4} color={colors.mandy} display="flex" fontSize="2.2rem" alignItems="center" justifyContent="center">
            <Image width="60px" height="60px" src={FeelLogo} />
            YouFeel
          </MediumText>
          <Wrapper size={width < 900 ? true : false}>
            <a style={{ margin: '5px' }} rel="noopener noreferrer" target="_blank" href={process.env.REACT_APP_APPLE_STORE_FEEL}>
              <Image marginTop={2} width="175px" src={AppStore} />
            </a>
            <a style={{ margin: '5px' }} rel="noopener noreferrer" target="_blank" href={process.env.REACT_APP_ANDROID_STORE_FEEL}>
              <Image marginTop={2} width="175px" src={GooglePlay} />
            </a>
          </Wrapper> */}
        </Paper>
      )}
    </FlexColumn>
  );

  const verifyForm = (
    <PaperBlock xs={11} md={4} lg={4} margin="0 auto" textAlign="center">
      {stateIsLoading(firstLogin) ? (
        <Spinner />
      ) : (
        <Form className="login" onSubmit={handleSubmit} actions={false}>
          <MediumText textAlign="center" paddingTop={2}>
            {t('CONFIRMATIONS.enterYourNewPassword')}
          </MediumText>
          <FlexColumn alignItems="center">
            <PasswordBox
              onChange={(value, e) => handleInputsChange(value, e, 'password')}
              name="newPassword"
              label={t('GENERAL.password')}
              autoComplete="current-password"
              required
            />
            <PasswordBox
              onChange={(value, e) => handleInputsChange(value, e, 'confirmPassword')}
              name="confirmPassword"
              label={t('PROFILE.confirmPass')}
              autoComplete="current-password"
              required
            />
          </FlexColumn>
          <FormActions>
            <ContainedButton disabled={!!passwordStrength || !checkIfPasswordMatch} color="primary" type="submit" text={t('GENERAL.save')} />
          </FormActions>
          <SmallText padding={2} color="red">
            {passwordStrength && passwordStrength}
          </SmallText>
          <SmallText padding={2} color="red">
            {ls.password.length >= 8 && ls.confirmPassword && !checkIfPasswordMatch && t('ACCOUNT.newAndConfirmPassDontMatch')}
          </SmallText>
          <SmallText padding={2} color="red">
            {firstLogin.error && firstLogin.error.message}
          </SmallText>
        </Form>
      )}
    </PaperBlock>
  );

  return (
    <FlexRow xs={12} alignItems="flex-start" height="108vh" paddingTop="8vh" paddingBotto="8vh" backgroundColor={colors.mineShaft} margin={0}>
      {stateIsLoaded(firstLogin) ? successFields : verifyForm}
    </FlexRow>
  );
};

// const Wrapper = ({ children, size }) =>
//   size ? (
//     <FlexColumn xs={12}>{children}</FlexColumn>
//   ) : (
//     <FlexRow marginTop={5} xs={12} justifyContent="space-around" alignItems="center">
//       {children}
//     </FlexRow>
//   );

export default withRouter(VerifyPassword);
