import React from 'react';
import { useTranslation } from 'react-i18next';
import Thing from './Thing';
import DealLogo from '../../../../../../assets/img/guryouIcons/gyIcon37.png';
import FeelLogo from '../../../../../../assets/img/guryouIcons/gyIcon43.png';
import StartLogo from '../../../../../../assets/img/youstartLogo.png';

const generateThings = t => {
  return [
    {
      title: t(`YD_SECTION_THREE.sectionThreeTitle1`),
      text: t(`YD_SECTION_THREE.sectionThreeText1`),
      icon: DealLogo,
    },
    {
      title: t(`YD_SECTION_THREE.sectionThreeTitle2`),
      text: t(`YD_SECTION_THREE.sectionThreeText2`),
      icon: DealLogo,
    },
    {
      title: t(`YD_SECTION_THREE.sectionThreeTitle3`),
      text: t(`YD_SECTION_THREE.sectionThreeText3`),
      icon: FeelLogo,
    },
    {
      title: t(`YD_SECTION_THREE.sectionThreeTitle4`),
      text: t(`YD_SECTION_THREE.sectionThreeText4`),
      icon: StartLogo,
    },
  ];
};

const Content = ({ width, tabletWidth }) => {
  const { t } = useTranslation();

  return (
    <>
      {generateThings(t).map(thing => (
        <Thing icon={thing.icon} key={thing.text} title={thing.title} text={thing.text} width={width} tabletWidth={tabletWidth} />
      ))}
      {/* <Thing
        icon={<ImageSearchIcon fontSize="large" htmlColor={colors.lightningYellow} />}
        title={t('YD_FEATURES.serviceCatalog')}
        text={t('YD_FEATURES.makeKnownTheWellness')}
        width={width}
        tabletWidth={tabletWidth}
      />
      <Thing
        icon={<CalendarTodayIcon fontSize="large" htmlColor={colors.lightningYellow} />}
        title={t('YD_FEATURES.bookingCalendar')}
        text={t('YD_FEATURES.manageYourApointment')}
        width={width}
        tabletWidth={tabletWidth}
      />
      <Thing
        icon={<PaymentIcon fontSize="large" htmlColor={colors.lightningYellow} />}
        title={t('YD_FEATURES.onlinePayments')}
        text={t('YD_FEATURES.recievePayments')}
        width={width}
        tabletWidth={tabletWidth}
      />
      <Thing
        icon={<CardMembershipIcon fontSize="large" htmlColor={colors.lightningYellow} />}
        title={t('YD_FEATURES.periodicSubs')}
        text={t('YD_FEATURES.yourCustomersCanPurchase')}
        width={width}
        tabletWidth={tabletWidth}
      />
      <Thing
        icon={<TheatersIcon fontSize="large" htmlColor={colors.lightningYellow} />}
        title={t('YD_FEATURES.videoCourses')}
        text={t('YD_FEATURES.aVideoStreaming')}
        width={width}
        tabletWidth={tabletWidth}
      />
      <Thing
        icon={<PhotoLibraryIcon fontSize="large" htmlColor={colors.lightningYellow} />}
        title={t('YD_FEATURES.photoGallery')}
        text={t('YD_FEATURES.addPhotos')}
        width={width}
        tabletWidth={tabletWidth}
      /> */}
    </>
  );
};

export default Content;
