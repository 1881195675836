import React from 'react';
import { useTranslation } from 'react-i18next';
import { colors } from '@guryou/colors';
import { MediumText } from '@guryou/html-components';
const Description = ({ width, laptopWidth }) => {
  const { t } = useTranslation();

  const w = width <= 1500 ? (width < 500 ? '90%' : '55%') : '55%';

  return (
    <MediumText
      color={colors.white}
      fontSize={width < 600 ? '1.2rem' : width < laptopWidth ? '1.3rem' : '1.7rem'}
      fontWeight={600}
      marginBottom={10}
      width={w}
    >
      {t('HERO.dealSubHeader')}
    </MediumText>
  );
};

export default Description;
