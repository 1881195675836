"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.defineLinearGradientFromArrayOfGroups = exports.reorderArray = exports.returnNullIfEmpty = exports.removeDuplicateObjects = exports.arrayGetDistinctValues = void 0;

var _slicedToArray2 = _interopRequireDefault(require("@babel/runtime/helpers/slicedToArray"));

var _toConsumableArray2 = _interopRequireDefault(require("@babel/runtime/helpers/toConsumableArray"));

function _createForOfIteratorHelper(o, allowArrayLike) {
  var it;

  if (typeof Symbol === "undefined" || o[Symbol.iterator] == null) {
    if (Array.isArray(o) || (it = _unsupportedIterableToArray(o)) || allowArrayLike && o && typeof o.length === "number") {
      if (it) o = it;
      var i = 0;

      var F = function F() {};

      return {
        s: F,
        n: function n() {
          if (i >= o.length) return {
            done: true
          };
          return {
            done: false,
            value: o[i++]
          };
        },
        e: function e(_e) {
          throw _e;
        },
        f: F
      };
    }

    throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method.");
  }

  var normalCompletion = true,
      didErr = false,
      err;
  return {
    s: function s() {
      it = o[Symbol.iterator]();
    },
    n: function n() {
      var step = it.next();
      normalCompletion = step.done;
      return step;
    },
    e: function e(_e2) {
      didErr = true;
      err = _e2;
    },
    f: function f() {
      try {
        if (!normalCompletion && it["return"] != null) it["return"]();
      } finally {
        if (didErr) throw err;
      }
    }
  };
}

function _unsupportedIterableToArray(o, minLen) {
  if (!o) return;
  if (typeof o === "string") return _arrayLikeToArray(o, minLen);
  var n = Object.prototype.toString.call(o).slice(8, -1);
  if (n === "Object" && o.constructor) n = o.constructor.name;
  if (n === "Map" || n === "Set") return Array.from(o);
  if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen);
}

function _arrayLikeToArray(arr, len) {
  if (len == null || len > arr.length) len = arr.length;

  for (var i = 0, arr2 = new Array(len); i < len; i++) {
    arr2[i] = arr[i];
  }

  return arr2;
}

var arrayGetDistinctValues = function arrayGetDistinctValues(arr) {
  return arr && (0, _toConsumableArray2["default"])(new Set(arr));
};

exports.arrayGetDistinctValues = arrayGetDistinctValues;

var removeDuplicateObjects = function removeDuplicateObjects(arr) {
  return arr && arr.map(function (y) {
    return JSON.stringify(y);
  }).filter(function (x, i, arr) {
    return arr.indexOf(x) === i;
  }).map(function (o) {
    return JSON.parse(o);
  });
};

exports.removeDuplicateObjects = removeDuplicateObjects;

var returnNullIfEmpty = function returnNullIfEmpty(arr) {
  if (Array.isArray(arr) && arr.length > 0) {
    return arr;
  }

  return null;
};

exports.returnNullIfEmpty = returnNullIfEmpty;

var reorderArray = function reorderArray(arr, fromIndex, toIndex) {
  if (!arr || !arr.length) {
    return arr;
  }

  var newArr = (0, _toConsumableArray2["default"])(arr);
  var element = arr[fromIndex];
  newArr.splice(fromIndex, 1);
  newArr.splice(toIndex, 0, element);
  return newArr;
};

exports.reorderArray = reorderArray;

var defineLinearGradientFromArrayOfGroups = function defineLinearGradientFromArrayOfGroups(groups) {
  if (!groups || !groups.length) {
    return "#ffffff";
  }

  if (groups && groups.length === 1) {
    return "#".concat(groups[0].color);
  }

  var delimetar = 100 / groups.length;
  var css = 'linear-gradient(';

  var _iterator = _createForOfIteratorHelper(groups.entries()),
      _step;

  try {
    for (_iterator.s(); !(_step = _iterator.n()).done;) {
      var _step$value = (0, _slicedToArray2["default"])(_step.value, 2),
          i = _step$value[0],
          g = _step$value[1];

      if (i === 0 || groups.length === i + 1) {
        css += "#".concat(g.color, " ").concat(delimetar, "%");
      }

      if (i !== 0 && groups.length !== i + 1) {
        css += "#".concat(g.color, " ").concat(delimetar, "% ").concat(delimetar + delimetar, "%");
      }

      if (groups.length !== i + 1) {
        css += ', ';
      }
    }
  } catch (err) {
    _iterator.e(err);
  } finally {
    _iterator.f();
  }

  css += ')';
  return css;
};

exports.defineLinearGradientFromArrayOfGroups = defineLinearGradientFromArrayOfGroups;