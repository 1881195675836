import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { colors } from '@guryou/colors';
import LandingFooter from 'components/landingFooter';
import AnalyticsDivider from './analyticsDivider';
import ContactForm from './ContactForm';
import FirstSlide from './FirstSlide';
import LogosDivider from './logosDivider';
import NewSection from './newSection';
import ReviewsDivider from './reviewsDivider';
import TitleDivider from './TitleDivider';
import imageDeal from '../../assets/img/gyNewHeroImage.jpg';
import imageFeel from '../../assets/img/newImages/2.png';
import imageStart from '../../assets/img/newImages/3.png';
const tabletWidth = 900;
const laptopWidth = 1300;

const Home = () => {
  const { width } = useSelector(state => state.windowSize);
  const { t } = useTranslation();
  return (
    <>
      <FirstSlide width={width} />
      <TitleDivider title={t('NEWHOME.forYourBusiness')} marginTop={5} textColor={colors.mineShaft} width={width} />
      <NewSection
        left={true}
        width={width}
        image={imageDeal}
        mainText={t(`NEWHOME.secondSectionMainText`)}
        subText={t(`NEWHOME.secondSectionSubText`)}
        number="second"
        textColor={colors.mineShaft}
        url="youdeal"
        btnTextColor={colors.light1}
        color={colors.mineShaft}
      />
      <TitleDivider title={t('NEWHOME.forEveryone')} left="true" textColor={colors.mineShaft} width={width} />
      <NewSection
        left={false}
        width={width}
        image={imageFeel}
        mainText={t(`NEWHOME.thirdSectionMainText`)}
        subText={t(`NEWHOME.thirdSectionSubText`)}
        number="third"
        textColor={colors.mineShaft}
        titleColor={colors.mineShaft}
        url="youfeel"
        btnTextColor={colors.light1}
        color={colors.mineShaft}
      />

      <NewSection
        left={true}
        width={width}
        image={imageStart}
        mainText={t(`NEWHOME.fourthSectionMainText`)}
        subText={t(`NEWHOME.fourthSectionSubText`)}
        number="fourth"
        textColor={colors.mineShaft}
        titleColor={colors.mineShaft}
        url="youstart"
        btnTextColor={colors.light1}
        color={colors.mineShaft}
      />
      <LogosDivider width={width} tabletWidth={tabletWidth} laptopWidth={laptopWidth} />
      <ReviewsDivider
        width={width}
        tabletWidth={tabletWidth}
        laptopWidth={laptopWidth}
        backgroundColor={colors.light1}
        textColor={colors.mineShaft}
      />
      <AnalyticsDivider width={width} tabletWidth={tabletWidth} laptopWidth={laptopWidth} />
      <ContactForm />
      <LandingFooter width={width} tabletWidth={tabletWidth} textColor={colors.white} color={colors.rimini} />
    </>
  );
};

export default Home;
