import React from 'react';
import { Block } from '@guryou/html-components';
import { Slide } from 'pure-react-carousel';

const CarouselSlide = ({ images }) => {
  return images.map(
    (image, index) =>
      image?.medium && (
        <Slide index={index + 1} key={index + 1}>
          <Block
            xs={12}
            boxShadow="0px 0px 20px rgba(0, 0, 0, 0.3)"
            borderRadius="0.5rem"
            height="200px"
            transition="all .4s ease-in-out"
            margin={0}
            marginLeft={1}
            marginRight={1}
            objectFit="cover"
            backgroundImage={`url('${image?.medium}')`}
            backgroundRepeat="no-repeact"
            backgroundPosition="50% 50%"
          />
        </Slide>
      )
  );
};

export default CarouselSlide;
