import React from 'react';
import { Loading } from '@guryou/html-components';
import Header from './header';
import SubHeader from './SubHeader';

const TopNavigation = () => (
  <>
    <Loading />
    <Header />
    <SubHeader />
  </>
);

export default TopNavigation;
