import React from 'react';
import { useTranslation } from 'react-i18next';
import { FlexRow } from '@guryou/html-components';
import PlanCard from './PlanCards/PlanCard';
import SpecialPlanCard from './PlanCards/SpecialPlanCard';

const size4 = [1, 2, 3, 4];
const size6 = [1, 2, 3, 4, 5, 6];

const PricingPlanCards = ({ toggle }) => {
  const { t } = useTranslation();
  return (
    <>
      <FlexRow xs={12} margin={0} justifyContent="space-around" alignItems="center">
        <PlanCard t={t} plan={'Free'} price={'FOREVER'} description={'Crea il tuo profilo GurYou'} buttonText={'Iscriviti'} listOfBenefits={size4} />
        <PlanCard
          t={t}
          plan={'Grow'}
          price={toggle ? '29' : '69'}
          currency={'€/MESE'}
          description={'Tutte le funzionalità FREE'}
          buttonText={'Acquista'}
          listOfBenefits={size6}
        />
        <SpecialPlanCard
          t={t}
          plan={'+Video'}
          price={toggle ? '59' : '99'}
          currency={'€/MESE'}
          description={'Tutte le funzionalità FREE e GROW'}
          buttonText={'Acquista'}
          listOfBenefits={size4}
        />
        <PlanCard
          t={t}
          plan={'All In One'}
          price={toggle ? '159' : '199'}
          currency={'€/MESE'}
          description={'Tutte le funzionalità FREE, GROW e +VIDEO'}
          buttonText={'Acquista'}
          listOfBenefits={size4}
        />
      </FlexRow>
      {/* <FlexRow xs={6} margin={0}></FlexRow> */}
    </>
  );
};

export default PricingPlanCards;
