import React from 'react';
import { FlexColumn, Image } from '@guryou/html-components';
import { handleAnalyticsClick } from 'analytics';
const ContactWidget = ({ image, link, width, height, marginLeft, color, GALabel, FBStandardEvent, FBCustomProperty }) => {
  return (
    <a href={`${link}`} onClick={() => handleAnalyticsClick('Contact', 'Button clicked!', GALabel, FBStandardEvent, FBCustomProperty)}>
      <FlexColumn
        width={width}
        height={height}
        backgroundColor={color}
        borderRadius="50%"
        marginLeft={marginLeft}
        justifyContent="center"
        boxShadow="rgb(0 0 0 / 10%) 0px 1px 6px, rgb(0 0 0 / 20%) 0px 2px 24px"
        hover={true}
        transition="all .2s ease-in-out;"
        cursor="pointer"
        hoverEffect={{ 'box-shadow': 'rgb(0 0 0 / 20%) 0px 2px 10px, rgb(0 0 0 / 30%) 0px 4px 28px' }}
      >
        <Image src={image} xs={7} />
      </FlexColumn>
    </a>
  );
};

export default ContactWidget;
