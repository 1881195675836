import React from 'react';
import HubspotForm from 'react-hubspot-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { colors } from '@guryou/colors';
import { FlexColumn, LargeText, MediumText, Spinner } from '@guryou/html-components';
import { Box, Paper } from '@material-ui/core';
import { handleAnalyticsClick } from 'analytics';

const tabletWidth = 900;

const UserInfo = () => {
  const { t } = useTranslation();

  const { width } = useSelector(state => state.windowSize);

  return (
    <FlexColumn xs={12} height="100vh" paddingTop="8vh" backgroundColor={colors.mineShaft}>
      <Paper component={Box} textAlign="center" padding={4}>
        <LargeText fontWeight={700} fontSize={width < 500 ? '1.6rem' : width < tabletWidth ? '1.9rem' : '2.8rem'} textAlign="center" marginBottom={2}>
          {t('CONTACT_US.doYouNeedInfo')}
        </LargeText>
        <MediumText fontSize={width < 500 ? '1.0rem' : '1.4rem'} fontWeight={400} textAlign={width < tabletWidth ? 'center' : ''} marginBottom={10}>
          {t('CONTACT_US.enterYourDetails')}
        </MediumText>
        {/* for the form below to work, https://github.com/escaladesports/react-hubspot-form/issues/18, manually adding jquery was used as solution */}
        <HubspotForm
          portalId={process.env.REACT_APP_HUBSPOT_FORMS_PORTAL_ID}
          formId={process.env.REACT_APP_HUBSPOT_DEAL_FORM_ID}
          onSubmit={() => handleAnalyticsClick('YouDeal', 'Form submited!', 'Info user form', 'Contact', 'Info about user on YouDeal submited!')}
          loading={<Spinner />}
        />
      </Paper>
    </FlexColumn>
  );
};

export default UserInfo;
