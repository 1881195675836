import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { colors } from '@guryou/colors';
import { FlexColumn, FlexRow, LinkButton, MediumText, OutlinedButton } from '@guryou/html-components';
import CheckRoundedIcon from '@material-ui/icons/CheckRounded';

const MobileFreePlanCard = ({ width, plan, price, description, listOfBenefits, buttonText, currency }) => {
  const { t, i18n } = useTranslation();
  const [seeAllFeatures, isSeeAllFeaturesClicked] = useState(false);

  const location = useLocation();

  const isRouteBusinessDeal = location.pathname.includes('businessDeal') || location.pathname.includes('rimini-wellness');

  const handleRowClick = () => {
    isSeeAllFeaturesClicked(!seeAllFeatures);
  };
  return (
    <FlexColumn width="375px" paddingTop={9} paddingLeft={7} paddingRight={7} marginBottom={10} paddingBottom={5}>
      <FlexColumn boxShadow="3px 3px 10px rgba(0, 0, 0, 0.1)" border="1px solid #000" borderRadius="0.5rem">
        <FlexColumn alignItems="start" padding={5} paddingBottom={1} minWidth={width < 350 ? '250px' : '300px'}>
          <FlexColumn margin={0} xs={12} alignItems="start">
            <MediumText fontSize="1.4rem" fontWeight={700} color={colors.mandy}>
              {plan}
            </MediumText>
            <FlexRow margin={0} alignItems="baseline">
              <MediumText fontWeight={700}>{price}</MediumText>
              <MediumText marginLeft={2} fontSize="1rem" fontWeight={700}>
                {currency}
              </MediumText>
            </FlexRow>
            <MediumText marginTop={1} marginBottom={4} fontSize="1rem" fontWeight={500}>
              {description}
            </MediumText>
            {!isRouteBusinessDeal && (
              <FlexRow margin={0} justifyContent="start">
                <LinkButton to={`/${i18n.language}/register`} xs={12}>
                  <OutlinedButton
                    xs={12}
                    marginTop={0}
                    marginBottom={0}
                    marginLeft={0}
                    marginRight={0}
                    paddingTop={4}
                    paddingBottom={4}
                    textTransform="none"
                    fontWeight={700}
                    fontSize="1.1rem"
                  >
                    {buttonText}
                  </OutlinedButton>
                </LinkButton>
              </FlexRow>
            )}
          </FlexColumn>
          <FlexColumn marginTop={3} marginBottom={3} margin={0}>
            {seeAllFeatures === true
              ? listOfBenefits.map((benefit, index) => (
                  <FlexRow key={index} margin={0} marginTop={1} marginBottom={1} alignItems="center">
                    <FlexRow margin={0} xs={1}>
                      <CheckRoundedIcon />
                    </FlexRow>
                    <MediumText xs={11} fontSize="1rem" fontWeight={400} marginLeft={2}>
                      {benefit}
                    </MediumText>
                  </FlexRow>
                ))
              : ''}
          </FlexColumn>
        </FlexColumn>
        <FlexRow
          onClick={handleRowClick}
          xs={12}
          height="60px"
          justifyContent="center"
          alignItems="center"
          margin={0}
          borderTop="1px solid rgba(0,0,0,0.1)"
        >
          <MediumText fontWeight={700} fontSize="1.1rem">
            {seeAllFeatures === true ? t('CONTENT.closeFeatures') : t('CONTENT.discoverFuncs')}
          </MediumText>
        </FlexRow>
      </FlexColumn>
    </FlexColumn>
  );
};

export default MobileFreePlanCard;
