import React from 'react';
import { colors } from '@guryou/colors';
import { Block, FlexColumn, FlexRow, LittleCircle } from '@guryou/html-components';
import { borderDefinition } from '@guryou/html-theme';

const ConnectedDots = ({ width, tabletWidth }) => {
  const size = width < tabletWidth;
  const heightSize = width > 700 ? '5.5em' : width > 607 ? '6em' : width > 530 ? '8em' : width > 403 ? '10em' : '11em';
  return (
    <Wrapper size={size}>
      <LittleCircle backgroundColor={colors.mandy} width="25px" height="25px" border={borderDefinition(colors.mandy, '2px')} />
      <Block xs={!size && 3} width={size && '2px'} height={size ? heightSize : 1} marginRight={size && 3} backgroundColor={colors.mandy} margin={0} />
      <LittleCircle backgroundColor={colors.mandy} width="25px" height="25px" border={borderDefinition(colors.mandy, '2px')} />
      <Block xs={!size && 3} width={size && '2px'} height={size ? heightSize : 1} marginRight={size && 3} backgroundColor={colors.mandy} margin={0} />
      <LittleCircle backgroundColor={colors.mandy} width="25px" height="25px" border={borderDefinition(colors.mandy, '2px')} />
      <Block xs={!size && 3} width={size && '2px'} height={size ? heightSize : 1} marginRight={size && 3} backgroundColor={colors.mandy} margin={0} />
      <LittleCircle backgroundColor={colors.mandy} width="25px" height="25px" border={borderDefinition(colors.mandy, '2px')} />
    </Wrapper>
  );
};

const Wrapper = ({ children, size }) =>
  size ? (
    <FlexColumn xs={3} alignItems="flex-end">
      {children}
    </FlexColumn>
  ) : (
    <FlexRow xs={12} justifyContent="center" alignItems="center">
      {children}
    </FlexRow>
  );

export default ConnectedDots;
