var ActionStatus = {
  START: '_START',
  DONE: '_DONE',
  FAILED: '_FAILED',
  RESET: '_RESET',
  REFRESH: '_REFRESH',
  UPDATE: '_UPDATE',
  DELETE: '_DELETE',
  INCREASE: '_INCREASE',
  DECREASE: '_DECREASE'
};
export { ActionStatus };