import React from 'react';
import { FlexColumn, FlexRow, Image, MediumText, SmallText } from '@guryou/html-components';

const Category = ({ image, title, content, width, tabletWidth }) => {
  return (
    <FlexColumn xs={width < 600 ? 12 : width < tabletWidth ? 6 : 4} paddingRight={width < tabletWidth ? 0 : 5} marginTop={3} alignItems="start">
      <FlexRow alignItems="flex-end">
        <Image src={image} width="45px" />
        <MediumText marginLeft={3} fontSize={width < 1400 ? '1.4rem' : '1.6rem'} fontWeight={700}>
          {title}
        </MediumText>
      </FlexRow>
      <SmallText padding={2} fontSize={width < 1400 ? '1.1rem' : '1.3rem'} fontWeight={400}>
        {content}
      </SmallText>
    </FlexColumn>
  );
};

export default Category;
