import React from 'react';
import { motion } from 'framer-motion';
import useStyles from '../../makeStyles/BackgroundStyle';
const SliderDots = ({
  clickFirstDot,
  clickSecondDot,
  clickThirdDot,
  clickFourthDot,
  firstSlideVisible,
  secondSlideVisible,
  thirdSlideVisible,
  fourthSlideVisible,
}) => {
  const classes = useStyles();
  const hover = {
    scale: 1.4,
    transition: { ease: 'easeOut' },
  };
  return (
    <div className={classes.sliderDots}>
      <motion.div onClick={clickFirstDot} className={firstSlideVisible ? classes.circleFilled : classes.circleEmpty} whileHover={hover} />
      <motion.div onClick={clickSecondDot} className={secondSlideVisible ? classes.circleFilled : classes.circleEmpty} whileHover={hover} />
      <motion.div onClick={clickThirdDot} className={thirdSlideVisible ? classes.circleFilled : classes.circleEmpty} whileHover={hover} />
      <motion.div onClick={clickFourthDot} className={fourthSlideVisible ? classes.circleFilled : classes.circleEmpty} whileHover={hover} />
    </div>
  );
};

export default SliderDots;
