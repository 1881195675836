import React from 'react';
import { FlexRow, LargeText } from '@guryou/html-components';

const TitleDivider = ({ title, textColor, left, width, marginTop }) => {
  return (
    <FlexRow
      alignItems="center"
      // justifyContent={left ? 'start' : 'end'}
      justifyContent="center"
      textAlign="center"
      padding={10}
      paddingRight={width > 900 ? !left && 17 : 0}
      paddingLeft={width > 900 ? left && 14 : 0}
      paddingBottom={width < 900 && 0}
      xs={12}
      margin={0}
      marginTop={marginTop ? marginTop : 0}
    >
      <LargeText color={textColor} textAlign="center" fontWeight={600} fontSize={width < 600 ? '1.6rem' : width < 900 ? '2.2rem' : '3rem'}>
        {title}
      </LargeText>
    </FlexRow>
  );
};

export default TitleDivider;
