import React from 'react';
import { FlexRow } from '@guryou/html-components';
import SectionImage from './SectionImage';
import SectionText from './SectionText';

const NewSection = props => {
  const { image, ...rest } = props;
  return (
    <FlexRow xs={12} height="auto" margin={0} padding={0} alignItems="start" justifyContent="center">
      {props.left || props.width < 900 ? (
        <>
          <SectionImage image={image} />
          <SectionText rest={rest} />
        </>
      ) : (
        <>
          <SectionText rest={rest} />
          <SectionImage image={image} />
        </>
      )}
    </FlexRow>
  );
};
export default NewSection;
