import React from 'react';
import { useTranslation } from 'react-i18next';
import { colors } from '@guryou/colors';
import { DefaultText, FlexColumn, FlexRow, LargeText } from '@guryou/html-components';
import TrendingDownIcon from '@material-ui/icons/TrendingDown';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';

const StatisticsCard = ({ trending, title, number, percentNumber }) => {
  const Icon = trending ? <TrendingUpIcon htmlColor={colors.fruitSalad} /> : <TrendingDownIcon htmlColor={colors.mandy} />;
  const { t } = useTranslation();
  return (
    <FlexColumn
      textAlign="center"
      width="20rem"
      height="100%"
      justifyContent="center"
      marginBottom={15}
      borderRadius="3%"
      border="1px solid lightgray"
      padding="16px 8px"
      backgroundColor={colors.light1}
    >
      <DefaultText color={colors.mineShaft} fontWeight="bold">
        {title}
      </DefaultText>
      <LargeText color={colors.mineShaft} fontSize="3rem">
        {number}
      </LargeText>
      <FlexRow justifyContent="center" alignItems="center">
        {Icon}
        <DefaultText
          width="70px"
          fontWeight="bold"
          textAlign="center"
          color={trending ? colors.fruitSalad2 : colors.mandy}
          padding="0.2rem 0.5rem"
          borderRadius="20px"
          // border={`1px solid ${trending ? colors.fruitSalad2 : colors.mandy}`}
          backgroundColor={trending ? '#d5f5ea' : colors.carouselPink}
          marginLeft="10px"
          marginRight="30.57px"
        >
          {percentNumber}
        </DefaultText>
      </FlexRow>
      <DefaultText color={colors.mineShaft} fontWeight="bold">
        {t('GENERAL.lastMonth')}
      </DefaultText>
    </FlexColumn>
  );
};

export default StatisticsCard;
