import React from 'react';
// import { useTranslation } from 'react-i18next';
import { FlexColumn, LargeText, MediumText } from '@guryou/html-components';
import Categories from './Categories';

const ReadSection = ({ width }) => {
  // const { t } = useTranslation();
  return (
    <FlexColumn xs={10} margin="auto" marginTop={15} marginBottom={10}>
      <LargeText marginBottom={2} fontSize="3rem" fontWeight={700}>
        Read
      </LargeText>
      <MediumText textAlign="center" marginBottom={5} fontSize="1.4rem" fontWeight={400}>
        Blog: tutti i consigli per il tuo wellbeing
      </MediumText>
      <Categories width={width} />
    </FlexColumn>
  );
};

export default ReadSection;
