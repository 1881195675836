import React from 'react';
import ArticleCard from './ArticleCard';

const ArticleCardWithProps = ({ card, width }) => {
  return (
    <ArticleCard
      key={card?.id}
      slug={card?.Slug}
      author={card?.author?.display_name}
      category={card?.category?.name}
      categoryColor={card?.category?.color}
      image={card?.image?.url}
      title={card?.title}
      description={card?.description}
      date={card?.date}
      authorSlug={card?.author?.Slug}
      width={width}
    />
  );
};

export { ArticleCardWithProps };
