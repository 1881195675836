import { useRef } from 'react';

var useFirstMountState = function useFirstMountState() {
  var isFirst = useRef(true);

  if (isFirst.current) {
    isFirst.current = false;
    return true;
  }

  return isFirst.current;
};

export { useFirstMountState };