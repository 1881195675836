import React from 'react';
import { useTranslation } from 'react-i18next';
import { colors } from '@guryou/colors';
import { LargeText } from '@guryou/html-components';

const List = ({ width }) => {
  const { t } = useTranslation();
  return (
    <LargeText
      width="100%"
      color={colors.fruitSalad}
      fontSize={width < 1350 ? '2.4rem' : '3.4rem'}
      fontWeight={900}
      lineHeight="1.1"
      marginBottom={10}
    >
      {t('SLIDER.slide3Text')}
    </LargeText>
  );
};

export default List;
