import React, { useState } from 'react';
import { Block, FlexColumn, Image, MediumText, SmallText } from '@guryou/html-components';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { motion } from 'framer-motion';
import './CenterCard.css';

const CenterCard = ({ title, address, image, width, description }) => {
  const [isHoverOn, setHoverOn] = useState(false);
  return (
    <motion.div
      onClick={width < 900 ? () => setHoverOn(!isHoverOn) : () => ''}
      onHoverStart={() => setHoverOn(true)}
      onHoverEnd={() => setHoverOn(false)}
    >
      <FlexColumn
        xs={2}
        marginTop={5}
        minHeight="390px"
        minWidth={width < 400 ? '290px' : '300px'}
        boxShadow="0px 0px 20px rgba(0, 0, 0, 0.3)"
        borderRadius="0.5rem"
      >
        <Block height={isHoverOn ? '0px' : '160px'} opacity={isHoverOn ? 0 : 1} transition="all .4s ease-in-out" margin={0}>
          <Image objectFit="cover" src={image} xs={12} clipPath="polygon(0 0, 100% 0, 100% 160px, 0 140px)" />
        </Block>
        <FlexColumn
          margin={0}
          xs={12}
          padding={5}
          paddingBottom={2}
          height={isHoverOn ? '390px' : '210px'}
          transition="all .4s ease-in-out"
          alignItems="flex-start"
        >
          <MediumText fontSize="1rem" fontWeight={700}>
            {title}
          </MediumText>
          <SmallText fontSize="0.8rem" fontWeight={400} fontStyle="italic">
            {address}
          </SmallText>
          <SmallText
            fontSize="0.8rem"
            paddingRight={5}
            fontWeight={400}
            overflowY={isHoverOn ? 'scroll' : 'hidden'}
            transition="all .4s ease-in-out"
            marginTop={3}
            className="scrollable"
          >
            {description}
          </SmallText>
        </FlexColumn>
        <motion.div style={{ marginTop: '5px' }} animate={isHoverOn ? { rotate: 180 } : { rotate: 0 }}>
          <ExpandMoreIcon />
        </motion.div>
      </FlexColumn>
    </motion.div>
  );
};

export default CenterCard;
