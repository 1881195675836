import { useEffect } from 'react';
import { useFirstMountState } from './useFirstMountState';

var useUpdateEffect = function useUpdateEffect(effect, deps) {
  var isFirstMount = useFirstMountState();
  useEffect(function () {
    if (!isFirstMount) {
      effect();
    } // eslint-disable-next-line

  }, deps);
};

export default useUpdateEffect;