import { dealThemePalette } from '@guryou/colors';
var switchOverrides = {
  MuiSwitch: {
    root: {
      padding: 2,
      width: 48,
      height: 28,
      '& .Mui-checked + .MuiSwitch-track': {
        backgroundColor: "".concat(dealThemePalette.primary.dark, " !important"),
        opacity: "1 !important"
      }
    },
    track: {
      width: '100%',
      height: '100%',
      borderRadius: 0,
      backgroundColor: "".concat(dealThemePalette.secondary.dark, " !important"),
      opacity: 1,
      '&.notDefined': {
        backgroundColor: "".concat(dealThemePalette.secondary.contrastText, " !important")
      }
    },
    thumb: {
      borderRadius: "0 !important"
    },
    switchBase: {
      padding: 4,
      color: "".concat(dealThemePalette.primary.contrastText, " !important")
    }
  }
};
export { switchOverrides };