import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { colors } from '@guryou/colors';
import { FlexColumn, FlexRow, LinkButton } from '@guryou/html-components';
import CallToActionButton from 'components/CallToActionButton';
import Title from '../../hero/Title';
import Benefits from './serviceClassEventContent/Benefits/Benefits';
import Text from './serviceClassEventContent/Text';

const ServiceClassEvent = ({ width, tabletWidth, laptopWidth }) => {
  const { t, i18n } = useTranslation();

  const location = useLocation();

  const isRouteBusinessDeal = location.pathname.includes('businessDeal') || location.pathname.includes('rimini-wellness');
  const isRimini = location.pathname.includes('rimini-wellness');

  return (
    <FlexColumn marginTop={width < 650 ? 30 : 10} xs={11.5} paddingLeft={width < tabletWidth ? 0 : 5}>
      <FlexRow margin={0} marginBottom={20} justifyContent={width < tabletWidth ? 'center' : ''} alignItems="center">
        {isRouteBusinessDeal && <Title width={width} laptopWidth={laptopWidth} color={colors.mineShaft} />}
        <Text width={width} tabletWidth={tabletWidth} laptopWidth={laptopWidth} />
      </FlexRow>
      <FlexRow margin={0}>
        <Benefits width={width} tabletWidth={tabletWidth} laptopWidth={laptopWidth} />
      </FlexRow>
      <FlexColumn marginTop={20}>
        <LinkButton to={`/${i18n.language}/info`}>
          <CallToActionButton text={t('YD_PLATFORM.requestMoreInfo').toUpperCase()} isRimini={isRimini} />
        </LinkButton>
      </FlexColumn>
    </FlexColumn>
  );
};

export default ServiceClassEvent;
