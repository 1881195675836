import React from 'react';
import { colors } from '@guryou/colors';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { motion } from 'framer-motion';
import useStyles from '../../makeStyles/BackgroundStyle';

const Arrows = ({
  clickFirstDot,
  clickSecondDot,
  clickThirdDot,
  clickFourthDot,
  firstSlideVisible,
  secondSlideVisible,
  thirdSlideVisible,
  fourthSlideVisible,
}) => {
  const classes = useStyles();
  const handleArrowSlideRight = () => {
    if (firstSlideVisible) {
      clickSecondDot();
    } else if (secondSlideVisible) {
      clickThirdDot();
    } else if (thirdSlideVisible) {
      clickFourthDot();
    } else if (fourthSlideVisible) {
      clickFirstDot();
    }
  };

  const handleArrowSlideLeft = () => {
    if (firstSlideVisible) {
      clickFourthDot();
    } else if (secondSlideVisible) {
      clickFirstDot();
    } else if (thirdSlideVisible) {
      clickSecondDot();
    } else if (fourthSlideVisible) {
      clickThirdDot();
    }
  };
  return (
    <>
      <motion.div onClick={handleArrowSlideLeft} className={classes.arrowLeftDiv}>
        {firstSlideVisible ? (
          <NavigateBeforeIcon style={{ width: '75px', height: '75px', color: colors.white }} />
        ) : (
          <NavigateBeforeIcon style={{ width: '75px', height: '75px', color: colors.white }} />
        )}
      </motion.div>
      <motion.div onClick={handleArrowSlideRight} className={classes.arrowRightDiv}>
        {secondSlideVisible ? (
          <NavigateNextIcon style={{ width: '75px', height: '75px', color: colors.white }} />
        ) : (
          <NavigateNextIcon style={{ width: '75px', height: '75px', color: colors.white }} />
        )}
      </motion.div>
    </>
  );
};

export default Arrows;
