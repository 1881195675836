import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import ReactMarkdown from 'react-markdown';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { colors } from '@guryou/colors';
import { Block, FlexColumn, FlexRow, Image, MediumText, Spinner } from '@guryou/html-components';
import { GET_ARTICLE_BY_SLUG, getArticleBySlug, resetState, stateIsLoaded } from '@guryou/redux';
import { printDate } from '@guryou/utils/src/dateTimeUtils';
import LandingFooter from 'components/landingFooter';
import { ArticleCardWithProps } from '../articleSection/ArticleCardWithProps';
import './index.css';

const tabletWidth = 900;

const Article = () => {
  const { t, i18n } = useTranslation();
  const { width } = useSelector(state => state.windowSize);

  const { slug } = useParams();
  const dispatch = useDispatch();
  const article = useSelector(state => state.articles.articleBySlug);

  useEffect(() => {
    dispatch(getArticleBySlug({ slug }));
  }, [dispatch, slug]);

  useEffect(() => {
    return () => dispatch(resetState(GET_ARTICLE_BY_SLUG));
    //eslint-disable-next-line
  }, []);

  if (!stateIsLoaded(article)) {
    return <Spinner />;
  }
  return (
    <>
      <Block height={200} backgroundColor={colors.mandy} margin={0} position="absolute" zIndex={-1}></Block>
      <Block xs={width < tabletWidth ? 11 : 8} margin="auto" height={150}>
        <MediumText
          fontSize={width < tabletWidth ? '1.6rem' : '2.2rem'}
          fontWeight={700}
          color={colors.white}
          marginTop={12}
          textShadow="3px 3px 3px rgba(0,0,0,0.2)"
        >
          {article.data?.title}
        </MediumText>
      </Block>
      <FlexColumn xs={width < tabletWidth ? 11 : 8} boxShadow="0 0 10px rgba(0,0,0,0.3)" position="relative" marginBottom={15} marginTop={5}>
        <Block maxHeight={700} margin={0}>
          <Image height="auto" xs={12} src={article.data?.image.url} verticalAlign="middle" objectFit="cover" />
        </Block>
        <FlexRow justifyContent="center">
          {t('GENERAL.date')}: {printDate(article.data?.date)}
          <Link to={`/${i18n.language}/author/${article.data?.author?.Slug}`} style={{ marginLeft: '10px' }}>
            {t('HOME_READ_PAGE.author')}: {article.data?.author?.display_name}
          </Link>
        </FlexRow>
        <ReactMarkdown className="markdown" source={article.data?.content} />
        <MediumText fontWeight={400}>Latest Posts</MediumText>
        <FlexRow justifyContent="center" xs={12} marginBottom={10}>
          {article.data?.author?.articles?.map(card => (
            <ArticleCardWithProps key={card.id} card={card} width={width} />
          ))}
        </FlexRow>
      </FlexColumn>
      <LandingFooter width={width} tabletWidth={tabletWidth} color={colors.mandy} />
    </>
  );
};

export default Article;
