import React, { useState } from 'react';
import { colors } from '@guryou/colors';
import { Block, FlexColumn, Image, MediumText, SmallText } from '@guryou/html-components';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import quote from 'assets/img/quote.svg';
import { motion } from 'framer-motion';
const CenterCard = ({ name, image, description, job, width }) => {
  const [isHoverOn, setHoverOn] = useState(false);
  return (
    <motion.div
      onClick={width < 900 ? () => setHoverOn(!isHoverOn) : () => ''}
      onHoverStart={() => setHoverOn(true)}
      onHoverEnd={() => setHoverOn(false)}
    >
      <FlexColumn
        xs={2}
        marginTop={5}
        minHeight="390px"
        minWidth={width < 400 ? '290px' : '300px'}
        boxShadow="3px 3px 10px rgba(0, 0, 0, 0.1)"
        borderRadius="0.5rem"
        position="relative"
        backgroundColor={colors.light1}
      >
        <Block height={isHoverOn ? '0px' : '150px'} opacity={isHoverOn ? 0 : 1} transition="all .4s ease-in-out;" margin={0}>
          <Image objectFit="cover" xs={12} src={image} />
        </Block>
        <FlexColumn
          alignItems="start"
          margin={0}
          paddingLeft={7}
          paddingRight={7}
          paddingBottom={7}
          xs={12}
          height={isHoverOn ? '390px' : '210px'}
          transition="all .4s ease-in-out;"
        >
          <MediumText fontSize="1rem" fontWeight={700} marginTop={4}>
            {name}
          </MediumText>
          <MediumText fontSize="1rem" fontStyle="italic" fontWeight={500} marginTop={2}>
            {job}
          </MediumText>
          <SmallText marginTop={3} fontSize="1rem" fontWeight={400} fontStyle="italic">
            {description}
          </SmallText>
        </FlexColumn>
        <motion.div style={{ marginTop: '5px' }} animate={isHoverOn ? { rotate: 180 } : { rotate: 0 }}>
          <ExpandMoreIcon />
        </motion.div>
        <Image src={quote} position="absolute" bottom="20px" right="20px" width="50px" height="50px" />
      </FlexColumn>
    </motion.div>
  );
};

export default CenterCard;
