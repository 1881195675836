import React from 'react';
import { colors } from '@guryou/colors';
import { Button } from '@guryou/html-components';
import DoubleArrowIcon from '@material-ui/icons/DoubleArrow';
const HeroButton = ({ text, width, tabletWidth, handleClickOpen }) => {
  return (
    <Button
      margin={0}
      marginBottom={5}
      xs={2}
      minWidth={width < tabletWidth ? '200px' : '240px'}
      backgroundColor={colors.white}
      textTransform="none"
      fontSize={width < tabletWidth ? '0.8rem' : '1rem'}
      fontWeight={700}
      paddingTop={4}
      paddingRight={3}
      paddingLeft={3}
      paddingBottom={4}
      boxShadow="0 0 20px rgba(255,255,255,0.3)"
      justifyContent="space-between"
      onClick={handleClickOpen}
    >
      {text} <DoubleArrowIcon fontSize="default" style={{ marginLeft: '15px' }} />
    </Button>
  );
};

export default HeroButton;
