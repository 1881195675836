import React from 'react';
import { useTranslation } from 'react-i18next';
import { FlexColumn, FlexRow, Image, LargeText, MediumText } from '@guryou/html-components';
import GYPhone from 'assets/img/GYPhone.png';
import Lines from './Lines';

const Phone = ({ width, tabletWidth }) => {
  const { t } = useTranslation();
  return (
    <FlexColumn marginTop={15} position="relative">
      <LargeText fontWeight={900} fontSize={width < tabletWidth ? '2rem' : '2.7rem'} textAlign={width < tabletWidth ? 'center' : ''} marginBottom={5}>
        {t('YF_DOWNLOAD.downloadAndInstallGY')}
      </LargeText>
      {width < tabletWidth ? (
        ''
      ) : (
        <MediumText fontSize="1.4rem" fontWeight={400}>
          {t('YF_DOWNLOAD.doEvenMore')}
        </MediumText>
      )}

      {width < 1100 ? (
        <FlexRow xs={width < 670 ? 12 : 11} justifyContent="center">
          <Image src={GYPhone} width={'350px'} height="622px" zIndex="1" />
          <Lines width={width} />
        </FlexRow>
      ) : (
        <>
          <Image src={GYPhone} width="350px" zIndex="1" />
          <Lines width={width} />
        </>
      )}
    </FlexColumn>
  );
};

export default Phone;
