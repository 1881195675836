import React from 'react';
import { useTranslation } from 'react-i18next';
import { colors } from '@guryou/colors';
import { FlexColumn, FlexRow, LargeText } from '@guryou/html-components';
import BenefitsBackground from 'components/BackgroundFeelDeal/BenefitsBackground';
import Card from './Card';
import { CardInfo } from './CardInfo';
import Category from './Category';
import { CategoryInfo } from './CategoryInfo';

const Benefits = ({ width, tabletWidth }) => {
  const { t } = useTranslation();

  return (
    <>
      <FlexColumn
        position="relative"
        marginTop={width < 650 ? 20 : 5}
        paddingLeft={width < tabletWidth ? 3 : 15}
        paddingRight={width < tabletWidth ? 3 : 15}
        xs={12}
      >
        <BenefitsBackground
          rectangleColor={colors.carouselPink}
          trapezoidColorUp={colors.light1}
          trapezoidColorDown={colors.light1}
          top={width < tabletWidth ? '10%' : '25%'}
          height={width < tabletWidth ? '90%' : '75%'}
        />
        <LargeText
          fontWeight={900}
          fontSize={width < tabletWidth ? '2rem' : '2.7rem'}
          textAlign={width < tabletWidth ? 'center' : ''}
          marginBottom={5}
        >
          {t('YF_SEARCH.whatYouCanDoWithGY')}
        </LargeText>
        <FlexRow
          margin={0}
          justifyContent="space-between"
          marginTop={5}
          paddingLeft={width < 650 ? 0 : 5}
          paddingRight={width < 650 ? 0 : width < tabletWidth ? 5 : 0}
        >
          {CardInfo.map(card => (
            <Card key={card.id} title={card.title} content={card.content} width={width} tabletWidth={tabletWidth} />
          ))}
        </FlexRow>
        <FlexRow paddingLeft={width < 650 ? 0 : 5} margin={0} justifyContent="space-between">
          <LargeText
            xs={width < 1400 ? 12 : 2.5}
            marginTop={13}
            fontSize={width < 1400 ? '1.5rem' : '2.3rem'}
            fontWeight={800}
            textAlign={width > 900 && width < 1400 ? 'center' : ''}
          >
            {t('CONTENT.guryouIsOrganized')}
          </LargeText>
          <FlexRow
            xs={width < 1400 ? 12 : 9}
            marginTop={width < 650 ? 0 : 3}
            margin={0}
            paddingLeft={0}
            paddingRight={width < tabletWidth ? 0 : 7}
            padding={7}
          >
            {CategoryInfo.map(category => (
              <Category
                key={category.id}
                image={category.image}
                title={category.title}
                content={category.content}
                width={width}
                tabletWidth={tabletWidth}
              />
            ))}
          </FlexRow>
        </FlexRow>
      </FlexColumn>
    </>
  );
};

export default Benefits;
