import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { colors } from '@guryou/colors';
import { FlexColumn, LargeText } from '@guryou/html-components';
import { handleAnalyticsClick } from 'analytics';
import gyNewHeroImage from '../../../../assets/img/gyNewHeroImage.jpg';
import Background from '../../components/Background';
import FindOutBtn from '../deal/content/FindOutBtn';

const Blog = ({ width }) => {
  const { t, i18n } = useTranslation();
  return (
    <FlexColumn position="relative" height={width < 600 ? '600px' : '850px'} xs={12}>
      <Background backgroundPosition="center" width={width} color={colors.mineShaft} image={gyNewHeroImage} />
      <FlexColumn alignItems={width < 600 ? 'center' : 'start'} xs={12} marginLeft={width < 600 ? 0 : 5}>
        <LargeText
          color={colors.mandy}
          fontSize={width < 600 ? '2rem' : width < 800 ? '2.2rem' : '2.5rem'}
          fontWeight={700}
          lineHeight="1.1"
          textAlign={width < 600 ? 'center' : 'left'}
          marginBottom={10}
          marginTop={15}
        >
          {t('SLIDER.slide4Text')}
        </LargeText>
        <Link
          to={`/${i18n.language}/category/all`}
          onClick={() =>
            handleAnalyticsClick(
              'Third slide',
              'Button clicked!',
              'Read more button on third slide',
              'Lead',
              'Read more button on third slide clicked!'
            )
          }
        >
          <FindOutBtn text={t('GENERAL.findHow')} color={colors.mandy} textColor={colors.mineShaft} width={width} />
        </Link>
      </FlexColumn>
    </FlexColumn>
  );
};

export default Blog;
