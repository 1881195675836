import React from 'react';
import { useTranslation } from 'react-i18next';
import { FlexColumn, Image, LargeText, LinkButton, MediumText, SmallText } from '@guryou/html-components';
import GuryouLogo from 'assets/img/guryouIcons/gyIcon38.png';

const NotFound = () => {
  const { t } = useTranslation();

  return (
    <FlexColumn xs={11} marginBottom={20}>
      <Image src={GuryouLogo} width="200px" marginTop={30} />
      <LargeText fontSize="4.5rem">404</LargeText>
      <MediumText marginBottom={4}>Page not found</MediumText>
      <SmallText textAlign="center" fontSize="1rem" fontWeight={400}>
        {t('CONTENT.pageNotFound')}
      </SmallText>
      <SmallText textAlign="center" fontSize="1rem" fontWeight={400}>
        {t('CONTENT.goBacckOrHeadOver')}{' '}
        <LinkButton to="/" textDecoration="underline">
          guryou.com
        </LinkButton>{' '}
        {t('CONTENT.toChooseDirection')}
      </SmallText>
    </FlexColumn>
  );
};

export default NotFound;
