import React from 'react';
import { colors } from '@guryou/colors';
import { Button } from '@guryou/html-components';
const CallToActionButton = ({ text, handleClickOpen, isRimini }) => {
  return (
    <Button
      backgroundColor={isRimini ? colors.rimini : colors.mineShaft}
      color={colors.white}
      textTransform="none"
      fontSize="1rem"
      fontWeight={700}
      paddingTop={4}
      minWidth="200px"
      paddingRight={3}
      paddingLeft={3}
      paddingBottom={4}
      marginTop={0}
      marginBottom={0}
      marginRight={0}
      marginLeft={0}
      onClick={handleClickOpen}
    >
      {text}
    </Button>
  );
};

export default CallToActionButton;
