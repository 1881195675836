"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getServicesForThisUserCollaborator = exports.checkIfCollaborator = exports.checkIfDirector = exports.checkIfManager = exports.checkIfOwner = exports.checkIfAdmin = exports.checkIfBusiness = void 0;

var _core = require("@guryou/core");

var _ = require(".");

function _createForOfIteratorHelper(o, allowArrayLike) {
  var it;

  if (typeof Symbol === "undefined" || o[Symbol.iterator] == null) {
    if (Array.isArray(o) || (it = _unsupportedIterableToArray(o)) || allowArrayLike && o && typeof o.length === "number") {
      if (it) o = it;
      var i = 0;

      var F = function F() {};

      return {
        s: F,
        n: function n() {
          if (i >= o.length) return {
            done: true
          };
          return {
            done: false,
            value: o[i++]
          };
        },
        e: function e(_e) {
          throw _e;
        },
        f: F
      };
    }

    throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method.");
  }

  var normalCompletion = true,
      didErr = false,
      err;
  return {
    s: function s() {
      it = o[Symbol.iterator]();
    },
    n: function n() {
      var step = it.next();
      normalCompletion = step.done;
      return step;
    },
    e: function e(_e2) {
      didErr = true;
      err = _e2;
    },
    f: function f() {
      try {
        if (!normalCompletion && it["return"] != null) it["return"]();
      } finally {
        if (didErr) throw err;
      }
    }
  };
}

function _unsupportedIterableToArray(o, minLen) {
  if (!o) return;
  if (typeof o === "string") return _arrayLikeToArray(o, minLen);
  var n = Object.prototype.toString.call(o).slice(8, -1);
  if (n === "Object" && o.constructor) n = o.constructor.name;
  if (n === "Map" || n === "Set") return Array.from(o);
  if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen);
}

function _arrayLikeToArray(arr, len) {
  if (len == null || len > arr.length) len = arr.length;

  for (var i = 0, arr2 = new Array(len); i < len; i++) {
    arr2[i] = arr[i];
  }

  return arr2;
}

var checkIfBusiness = function checkIfBusiness() {
  return (_.authService === null || _.authService === void 0 ? void 0 : _.authService.userRole) === _core.UserTypes.admin || (_.authService === null || _.authService === void 0 ? void 0 : _.authService.userRole) === _core.UserTypes.owner || (_.authService === null || _.authService === void 0 ? void 0 : _.authService.userRole) === _core.UserTypes.director || (_.authService === null || _.authService === void 0 ? void 0 : _.authService.userRole) === _core.UserTypes.manager;
};

exports.checkIfBusiness = checkIfBusiness;

var checkIfAdmin = function checkIfAdmin() {
  return (_.authService === null || _.authService === void 0 ? void 0 : _.authService.userRole) === _core.UserTypes.admin;
};

exports.checkIfAdmin = checkIfAdmin;

var checkIfOwner = function checkIfOwner() {
  return (_.authService === null || _.authService === void 0 ? void 0 : _.authService.userRole) === _core.UserTypes.owner;
};

exports.checkIfOwner = checkIfOwner;

var checkIfManager = function checkIfManager() {
  return (_.authService === null || _.authService === void 0 ? void 0 : _.authService.userRole) === _core.UserTypes.manager;
};

exports.checkIfManager = checkIfManager;

var checkIfDirector = function checkIfDirector() {
  return (_.authService === null || _.authService === void 0 ? void 0 : _.authService.userRole) === _core.UserTypes.director;
};

exports.checkIfDirector = checkIfDirector;

var checkIfCollaborator = function checkIfCollaborator() {
  return (_.authService === null || _.authService === void 0 ? void 0 : _.authService.userRole) === _core.UserTypes.collaborator;
};

exports.checkIfCollaborator = checkIfCollaborator;

var getServicesForThisUserCollaborator = function getServicesForThisUserCollaborator(services) {
  var _authService$user;

  var userId = +(_.authService === null || _.authService === void 0 ? void 0 : (_authService$user = _.authService.user) === null || _authService$user === void 0 ? void 0 : _authService$user.userId);
  var data = [];

  var _iterator = _createForOfIteratorHelper(services),
      _step;

  try {
    for (_iterator.s(); !(_step = _iterator.n()).done;) {
      var _s$resources;

      var s = _step.value;
      var ids = s === null || s === void 0 ? void 0 : (_s$resources = s.resources) === null || _s$resources === void 0 ? void 0 : _s$resources.map(function (x) {
        return +x.userId;
      });

      if (ids.includes(userId)) {
        data.push(s);
      }
    }
  } catch (err) {
    _iterator.e(err);
  } finally {
    _iterator.f();
  }

  return data;
};

exports.getServicesForThisUserCollaborator = getServicesForThisUserCollaborator;