"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.AppOs = void 0;
var AppOs = {
  web: 'web',
  android: 'android',
  ios: 'ios'
};
exports.AppOs = AppOs;