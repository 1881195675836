import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { colors } from '@guryou/colors';
import { Block, FlexColumn, Image, MediumText, SmallText } from '@guryou/html-components';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { motion } from 'framer-motion';

const ArticleCard = ({ width, category, slug, image, title, description, author, date, categoryColor }) => {
  const history = useHistory();
  const [isHoverOn, setHoverOn] = useState(false);
  const { i18n } = useTranslation();

  const handleClick = e => {
    e.preventDefault();
    if (width > 800) {
      return history.push(`/${i18n.language}/post/${slug}`);
    }
  };

  const handleMobileClick = e => {
    e.preventDefault();
    if (width < 800) {
      return history.push(`/${i18n.language}/post/${slug}`);
    }
  };

  if (description && description.length > 270) {
    description = description.substring(0, 270) + '...';
  }

  return (
    <motion.div onHoverEnd={() => setHoverOn(false)} style={{ width: '300px', height: '420px', margin: 10 }}>
      <a href="/" onClick={handleClick}>
        <FlexColumn
          xs={2}
          marginTop={5}
          minHeight="390px"
          minWidth={width < 400 ? '290px' : '300px'}
          boxShadow="3px 3px 10px rgba(0, 0, 0, 0.1)"
          borderRadius="0.5rem"
          position="relative"
          backgroundColor={colors.light1}
        >
          <a href="/" onClick={handleMobileClick}>
            <Block height={isHoverOn ? '0px' : '150px'} opacity={isHoverOn ? 0 : 1} transition="all .4s ease-in-out;" margin={0}>
              <Image objectFit="cover" xs={12} src={image} />
            </Block>
          </a>
          <FlexColumn
            alignItems="start"
            margin={0}
            paddingLeft={7}
            paddingRight={7}
            paddingBottom={7}
            xs={12}
            height={isHoverOn ? '390px' : '210px'}
            transition="all .4s ease-in-out;"
          >
            <MediumText fontSize="1rem" fontStyle="italic" color={categoryColor} fontWeight={500} marginTop={2}>
              {category}
            </MediumText>
            <MediumText fontSize="1rem" fontWeight={700} marginTop={4} onClick={handleMobileClick}>
              {title}
            </MediumText>
            <motion.div onHoverStart={() => setHoverOn(true)} onClick={width < 900 ? () => setHoverOn(!isHoverOn) : () => ''}>
              <SmallText marginTop={3} fontSize="1rem" fontWeight={400} fontStyle="italic">
                {description}
              </SmallText>
              <SmallText marginTop={7} fontSize="1rem" fontWeight={500}>
                {author}
              </SmallText>
              <SmallText fontSize=".8rem" fontWeight={400}>
                {date}
              </SmallText>
            </motion.div>
          </FlexColumn>
          <motion.div
            style={{ marginTop: '5px', width: '290px', textAlign: 'center' }}
            onHoverStart={() => setHoverOn(true)}
            onClick={width < 900 ? () => setHoverOn(!isHoverOn) : () => ''}
            animate={isHoverOn ? { rotate: 180 } : { rotate: 0 }}
          >
            <ExpandMoreIcon />
          </motion.div>
        </FlexColumn>
      </a>
    </motion.div>
  );
};

export default ArticleCard;
