import _objectSpread from "/Users/petarbozhinovski/projects/guryou-mono/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/objectSpread2";
import { colors, dealThemePalette } from '@guryou/colors';
import typographyOverrides from './typography';
import { sizes, spacer } from '../constants';
var modalOverrides = {
  MuiDialogTitle: {
    root: _objectSpread(_objectSpread({}, typographyOverrides.MuiTypography.h3), {}, {
      textAlign: 'left',
      color: dealThemePalette.primary.contrastText,
      backgroundColor: dealThemePalette.secondary.main,
      '& .MuiIconButton-root': {
        color: colors.white
      },
      padding: "".concat(spacer.spacer1, "px 0 0 ").concat(spacer.spacer2, "px")
    })
  },
  MuiDialog: {
    paperWidthSm: {
      maxWidth: sizes.small + spacer.spacer3,
      '& form button': {
        margin: 'auto !important'
      }
    },
    paperWidthMd: {
      maxWidth: 2 * sizes.small + spacer.spacer3
    },
    paperWidthLg: {
      maxWidth: 3 * sizes.small + spacer.spacer3
    }
  },
  MuiDialogContent: {
    root: {
      padding: '8px 16px',
      backgroundColor: dealThemePalette.primary.main
    }
  }
};
export default modalOverrides;