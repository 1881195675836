import React from 'react';
import { LargeText } from '@guryou/html-components';
import { Slide } from 'pure-react-carousel';
import CenterCard from './CenterCard';
import defaultImage from '../../../../assets/default-image.png';

const CarouselSlide = ({ width, data, type, noResult }) => {
  const { centers, professionals } = data;

  const dataToBeRendered = [...(centers || []), ...(professionals || [])];

  if (!dataToBeRendered.length && noResult) {
    return <LargeText textAlign="center">{noResult}</LargeText>;
  }

  return dataToBeRendered.map(
    (card, index) =>
      (type !== null || index < 10) &&
      card.images && (
        <Slide index={card.id} key={index}>
          <CenterCard
            key={card.id}
            id={card.id}
            title={card.name}
            address={card.address}
            image={card.images.length ? card.images[0].medium : defaultImage}
            description={card.description}
            width={width}
          />
        </Slide>
      )
  );
};

export default CarouselSlide;
