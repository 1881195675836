import HealthImage from 'assets/img/health.png';
import LifestyleImage from 'assets/img/lifestyle.png';
import MindsetImage from 'assets/img/mindset.png';
import MovesImage from 'assets/img/moves.png';
import WellbeingImage from 'assets/img/wellbeing.png';
import WellnessImage from 'assets/img/wellness.png';

export const CategoryInfo = [
  {
    id: 0,
    image: HealthImage,
    title: 'Health',
    content: 'Le migliori abitudini da adottare in fatto di alimentazione, azioni preventive, meditazione.',
  },
  {
    id: 1,
    image: MindsetImage,
    title: 'Mindset',
    content: "Rigenera la mente e ritrova l'energia per affrontare tutte le sfide della routine quotidiana.",
  },
  {
    id: 2,
    image: MovesImage,
    title: 'Moves',
    content: 'GurYou è un contenitore di informazioni e consigli preziosi su un ampio ventaglio di discipline sportive.',
  },
  {
    id: 3,
    image: WellbeingImage,
    title: 'Wellbeing',
    content: 'Trova il tuo equilibrio tra benessere interiore e benessere esteriore',
  },
  {
    id: 4,
    image: WellnessImage,
    title: 'Wellness@Work',
    content: 'Focalizza le tue energie sugli obiettivi più importanti e sul miglioramento delle soft skills.',
  },
  {
    id: 5,
    image: LifestyleImage,
    title: 'LifeStyle',
    content: 'Scopri tutte le esperienze wellness e fitness vicino e lontano da casa.',
  },
];
