import React from 'react';
import { Link } from 'react-scroll';
import { FlexColumn, SmallText } from '@guryou/html-components';
import Text from './Text';

const LandingFooterText = ({ text1, text2, text3, text4, text5, text6, link1, link2, link3, link4, link5, link6, color, paddingLeft, feel }) => {
  return (
    <FlexColumn marginTop={20} xs={3} alignItems="start" paddingLeft={paddingLeft}>
      <Text marginTop={0} fontWeight={700} fontSize="1.4rem" text={text1} link={link1} color={color} />
      <Text marginTop={3} fontWeight={400} fontSize="1.2rem" text={text2} link={link2} color={color} />
      {feel === true ? (
        <Link smooth={true} to={link3} offset={-70} style={{ cursor: 'pointer' }}>
          <SmallText fontSize="1.2rem" marginTop={3} fontWeight={400} color={color}>
            {text3}
          </SmallText>
        </Link>
      ) : (
        ''
      )}
      <Text marginTop={3} fontWeight={400} fontSize="1.2rem" text={text4} link={link4} color={color} />
      <Text marginTop={3} fontWeight={400} fontSize="1.2rem" text={text5} link={link5} color={color} />
      <Text marginTop={3} fontWeight={400} fontSize="1.2rem" text={text6} link={link6} color={color} />
    </FlexColumn>
  );
};

export default LandingFooterText;
