"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getObjectValue = exports.deleteValueByPath = exports.getValueByPath = exports.setValueByPath = void 0;

var _toConsumableArray2 = _interopRequireDefault(require("@babel/runtime/helpers/toConsumableArray"));

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _typeof2 = _interopRequireDefault(require("@babel/runtime/helpers/typeof"));

function ownKeys(object, enumerableOnly) {
  var keys = Object.keys(object);

  if (Object.getOwnPropertySymbols) {
    var symbols = Object.getOwnPropertySymbols(object);
    if (enumerableOnly) symbols = symbols.filter(function (sym) {
      return Object.getOwnPropertyDescriptor(object, sym).enumerable;
    });
    keys.push.apply(keys, symbols);
  }

  return keys;
}

function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = arguments[i] != null ? arguments[i] : {};

    if (i % 2) {
      ownKeys(Object(source), true).forEach(function (key) {
        (0, _defineProperty2["default"])(target, key, source[key]);
      });
    } else if (Object.getOwnPropertyDescriptors) {
      Object.defineProperties(target, Object.getOwnPropertyDescriptors(source));
    } else {
      ownKeys(Object(source)).forEach(function (key) {
        Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
      });
    }
  }

  return target;
}

var setValueByPath = function setValueByPath(obj, propertyPath, value) {
  var delimiter = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : '.';
  var mergeObjects = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : true;

  if (!propertyPath) {
    return true;
  }

  var properties = Array.isArray(propertyPath) ? propertyPath : propertyPath.split(delimiter);
  properties = properties.filter(function (x) {
    return x;
  });
  var key = properties.shift();

  if (properties.length === 0) {
    if (key) {
      if (obj[key] && (0, _typeof2["default"])(obj[key]) === 'object' && (0, _typeof2["default"])(value) === 'object' && mergeObjects && !Array.isArray(value)) {
        obj[key] = _objectSpread(_objectSpread({}, obj[key]), value);
      } else {
        obj[key] = value;
      }
    }

    return true; // this is the end
  }

  if (key.includes('[')) {
    var tokens = key.replace(/(\[|\])/g, '.').split(delimiter);
    key = tokens.shift();
    properties = [].concat((0, _toConsumableArray2["default"])(tokens), (0, _toConsumableArray2["default"])(properties));
    obj[key] = obj[key] || [];
  } else if (!obj[key]) {
    obj[key] = {};
  }

  return setValueByPath(obj[key], properties, value, delimiter);
};

exports.setValueByPath = setValueByPath;

var getValueByPath = function getValueByPath(obj, propertyPath) {
  var delimiter = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : '.';

  if (!obj || !propertyPath) {
    return null;
  }

  var properties = Array.isArray(propertyPath) ? propertyPath : propertyPath.replace(/(\[|\])/g, '.').split(delimiter);
  properties = properties.filter(function (x) {
    return x;
  });
  var key = properties.shift();
  return properties.length === 0 ? obj[key] : getValueByPath(obj[key], properties, delimiter);
};

exports.getValueByPath = getValueByPath;

var deleteValueByPath = function deleteValueByPath(obj, propertyPath) {
  var delimiter = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : '.';

  if (!obj || !propertyPath) {
    return null;
  }

  var properties = Array.isArray(propertyPath) ? propertyPath : propertyPath.replace(/(\[|\])/g, '.').split(delimiter);
  properties = properties.filter(function (x) {
    return x;
  });
  var key = properties.shift();

  if (properties.length === 0) {
    var oldValue = obj[key];
    delete obj[key];
    return oldValue;
  }

  return deleteValueByPath(obj[key], properties, delimiter);
};

exports.deleteValueByPath = deleteValueByPath;

var getObjectValue = function getObjectValue(obj, key) {
  var origKey = Object.keys(obj).find(function (x) {
    return x.toLowerCase() === key.toLowerCase();
  });
  return obj[origKey];
};

exports.getObjectValue = getObjectValue;