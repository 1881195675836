import { makeStyles } from '@material-ui/core';
import HeroImageFeel from '../assets/img/feel-hero-image.jpg';

const useThirdSlideStyleStart = makeStyles(() => ({
  slide: {
    opacity: '0',
    position: 'absolute',
    width: '95%',
    paddingLeft: '60px',
    top: '111px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  leftContent: {
    width: '100%',
  },
  background: {
    position: 'absolute',
    top: '7vh',
    right: '0',
    zIndex: '-2',
    opacity: '0',
    backgroundImage: `url('${HeroImageFeel}')`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'right top',
    height: '93vh',
    width: '70%',
  },
  // logoDeal: {
  //   width: '80%',
  // },
}));

export default useThirdSlideStyleStart;
