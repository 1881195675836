import React from 'react';
import { FlexColumn } from '@guryou/html-components';
import { TextField } from '@material-ui/core';
import useStyles from '../makeStyles/RegisterStyle';

const CustomTextField = ({ text, name, required, type, handleChange }) => {
  const { textField, labelStyle } = useStyles();
  return (
    <FlexColumn xs={12} marginTop={2}>
      <TextField
        type={type || 'text'}
        InputProps={{
          className: textField,
        }}
        InputLabelProps={{
          className: labelStyle,
        }}
        label={text}
        name={name}
        required={required}
        onChange={handleChange}
      />
    </FlexColumn>
  );
};

export default CustomTextField;
