import React, { useEffect } from 'react';
import ReactPixel from 'react-facebook-pixel';
import ReactGA from 'react-ga';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Switch, useLocation } from 'react-router-dom';
import { resizeWindow } from '@guryou/redux';
import Deal from 'modules/deal';
import Feel from 'modules/feel';
import Home from 'modules/homepage';
import MobileHome from 'modules/mobileHomepage';
import NewHome from 'modules/newHomepage';
import Register from 'modules/register';
import RegisterWithProvider from 'modules/registerWithProvider';
import Start from 'modules/start';
import NotFound from 'components/NotFound';
import About from './modules/about';
import BlogArticles from './modules/blogArticles';
import Article2 from './modules/blogArticles/article';
import Author2 from './modules/blogArticles/author';
import Demo from './modules/demo';
import Provider from './modules/provider';
import UserInfo from './modules/userInfo';
import getLangugePrefix from './utils/getLangugePrefix';

// initialize Google Analytics Tracker
ReactGA.initialize('UA-190133147-1');

// initialize Facebook Pixel tracker
ReactPixel.init('903551057075167');

const languagePrefix = getLangugePrefix();

const Routes = () => {
  const dispatch = useDispatch();
  const { width } = useSelector(state => state.windowSize);
  const location = useLocation();
  useEffect(() => {
    // Google Analytics PageView on every route change
    ReactGA.pageview(location.pathname + location.search);
    ReactPixel.pageView();
  }, [location]);

  useEffect(() => {
    const handleResize = () =>
      dispatch(
        resizeWindow({
          width: window.innerWidth,
          height: window.innerHeight,
        })
      );

    window.addEventListener('resize', handleResize);

    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, [dispatch]);

  return (
    <Switch>
      <Route exact path={`${languagePrefix}`} component={NewHome} />
      <Route path={`${languagePrefix}/slider`} component={width < 1050 ? MobileHome : Home} />
      <Route path={`${languagePrefix}/about`} component={About} />
      <Route path={`${languagePrefix}/category/:slug`} component={BlogArticles} />
      <Route path={`${languagePrefix}/provider/:id`} component={props => <Provider {...props} key={window.location.pathname} />} />
      <Route path={`${languagePrefix}/post/:slug`} component={Article2} />
      <Route path={`${languagePrefix}/author/:slug`} component={Author2} />
      <Route path={`${languagePrefix}/youdeal`} component={Deal} />
      <Route path={`${languagePrefix}/youfeel`} component={Feel} />
      <Route path={`${languagePrefix}/youstart`} component={Start} />
      <Route path={`${languagePrefix}/register`} component={RegisterWithProvider} />
      <Route path={`${languagePrefix}/registerBusiness`} component={Register} />
      <Route path={`${languagePrefix}/info`} component={UserInfo} />
      <Route path={`${languagePrefix}/demo`} component={Demo} />
      <Route path={`${languagePrefix}/businessDeal`} component={Deal} sensitive />
      <Route path={`${languagePrefix}/rimini-wellness`} component={Deal} sensitive />
      <Route path={`${languagePrefix}/guryoudeal`} component={Deal} sensitive />
      <Route component={NotFound} />
    </Switch>
  );
};

export default Routes;
