import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import { colors } from '@guryou/colors';
import { FlexRow, TextButton } from '@guryou/html-components';
import { handleAnalyticsClick } from 'analytics';
import Languages from './Languages';
import DownloadTheAppModal from '../../DownloadTheAppModal';

const Toolbar = () => {
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const [open, setOpen] = useState(false);
  const location = useLocation();

  const isRouteBusinessDeal =
    location.pathname.includes('businessDeal') || location.pathname.includes('info') || location.pathname.includes('rimini-wellness');

  if (isRouteBusinessDeal) {
    return null;
  }

  const handleClickOpen = () => {
    handleAnalyticsClick('Download the App', 'Button clicked!', 'Download the App', 'Lead', 'Download the App clicked!');
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleBlogClick = () => {
    history.push(`/${i18n.language}/category/all`);
    handleAnalyticsClick('Blog', 'Button clicked!', 'Blog', 'Lead', 'Blog button clicked!');
  };

  return (
    <FlexRow justifyContent="space-between" minHeight="40px" padding={0}>
      <Languages />
      <FlexRow md={4} xs={8} justifyContent="flex-end" flexWrap="nowrap">
        <TextButton
          color={colors.white}
          textDecoration="underline"
          onClick={() => handleBlogClick()}
          text="BLOG"
          fontSize="0.9rem"
          fontWeight={600}
        />
        <TextButton
          color={colors.white}
          textDecoration="underline"
          onClick={handleClickOpen}
          text={t('NAVBAR.downloadApp')}
          fontSize="0.9rem"
          fontWeight={600}
        />
      </FlexRow>
      <DownloadTheAppModal onClose={handleClose} open={open} />
    </FlexRow>
  );
};

export default Toolbar;
