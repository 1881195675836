import _objectSpread from "/Users/petarbozhinovski/projects/guryou-mono/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/objectSpread2";
import { ActionStatus, buildActionType, StateStatus } from '../core';
import { LOGIN, LOGOUT, NOT_AUTHORIZED, STORAGE_PERSIST } from '../types';

var auth = function auth() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {
    data: null,
    status: StateStatus.NOT_INITIALIZED,
    error: null,
    persisted: false
  };
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case buildActionType(LOGIN, ActionStatus.START):
      return {
        status: StateStatus.LOADING,
        data: null,
        error: null,
        persisted: false
      };
    // TODO: replace REFRESHING with loading

    case buildActionType(LOGIN, ActionStatus.REFRESH):
      return _objectSpread(_objectSpread({}, state), {}, {
        persisted: false,
        status: StateStatus.REFRESHING
      });

    case buildActionType(LOGIN, ActionStatus.DONE):
      var user = state.data && state.data.user;
      return {
        status: StateStatus.LOADED,
        data: _objectSpread({
          user: user
        }, action.payload),
        persisted: false,
        error: null,
        version: +new Date()
      };

    case buildActionType(STORAGE_PERSIST, ActionStatus.DONE):
      return _objectSpread(_objectSpread({}, state), {}, {
        persisted: true,
        version: +new Date()
      });

    case buildActionType(LOGIN, ActionStatus.FAILED):
      return {
        status: StateStatus.ERROR,
        error: action.payload
      };

    case LOGOUT:
      state = null;
      return {
        status: StateStatus.ERROR,
        data: null,
        error: null,
        persisted: false
      };

    case NOT_AUTHORIZED:
      state = null;
      return {
        status: StateStatus.NOT_INITIALIZED,
        data: null,
        error: NOT_AUTHORIZED,
        persisted: false
      };

    default:
      return state;
  }
};

export { auth };