import React from 'react';
import { colors } from '@guryou/colors';
import { FlexRow, LinkButton, SmallText } from '@guryou/html-components';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';

const MobileGuryouLinks = ({ textColor, text, link }) => {
  return (
    <LinkButton to={link} xs={12}>
      <FlexRow
        margin={0}
        xs={12}
        justifyContent="space-between"
        alignItems="center"
        borderBottom={`1px solid ${textColor ? 'rgba(255, 255, 255, 0.5)' : 'rgba(0, 0, 0, 0.2)'}`}
        paddingRight={3}
      >
        <SmallText padding={5} color={textColor} fontWeight={600} fontSize="1.2rem">
          {text}
        </SmallText>
        <ArrowForwardIosIcon htmlColor={textColor ? colors.white : ''} />
      </FlexRow>
    </LinkButton>
  );
};

export default MobileGuryouLinks;
