import React from 'react';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { FlexColumn, FlexRow, FullWidthImage } from '@guryou/html-components';
import Phones from 'assets/img/phones.svg';
import Background from './Background';
import Content from './Content';

const CreateYourAppBrand = ({ width, tabletWidth, laptopWidth }) => {
  const location = useLocation();

  const isRiminiOrDeal = location.pathname.includes('rimini-wellness') || location.pathname.includes('youdeal');

  return (
    <FlexColumn position="relative" margin={0} paddingLeft={width < 600 ? 5 : 13} paddingRight={width < 600 ? 5 : 13}>
      <Background width={width} tabletWidth={tabletWidth} isRiminiOrDeal={isRiminiOrDeal} />
      <FlexRow margin={0} marginTop={15} marginBottom={15} justifyContent="space-between" alignItems="center">
        {width < tabletWidth ? (
          <>
            <FlexColumn xs={width < 600 ? 12 : 10} margin={0} marginBottom={10}>
              <Content width={width} tabletWidth={tabletWidth} laptopWidth={laptopWidth} />
            </FlexColumn>
            <FlexRow xs={width < tabletWidth ? 12 : 5} margin={0} justifyContent={width < 650 ? 'space-around' : 'space-between'}>
              <FullWidthImage src={Phones} />
            </FlexRow>
          </>
        ) : (
          <>
            <FlexRow xs={width < tabletWidth ? 12 : 5} justifyContent={width < 957 ? 'center' : 'space-between'}>
              <FullWidthImage src={Phones} />
            </FlexRow>
            <FlexColumn xs={width < 600 ? 12 : 5} margin={0}>
              <Content width={width} tabletWidth={tabletWidth} laptopWidth={laptopWidth} />
            </FlexColumn>
          </>
        )}
      </FlexRow>
    </FlexColumn>
  );
};

export default CreateYourAppBrand;
