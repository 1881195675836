import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { FlexColumn, LargeText } from '@guryou/html-components';

const Text = ({ width, tabletWidth, laptopWidth }) => {
  const { t } = useTranslation();
  const location = useLocation();

  const isRouteBusinessDeal = location.pathname.includes('businessDeal') || location.pathname.includes('rimini-wellness');

  return (
    <FlexColumn margin={0} xs={width < tabletWidth ? 12 : 12} alignItems="start" textAlign="center">
      <LargeText
        marginBottom={10}
        fontSize={
          width < 600 ? '1.5rem' : width < tabletWidth ? '1.7rem' : width < laptopWidth ? '2.3rem' : `${isRouteBusinessDeal ? '2.7rem' : '2.7rem'}`
        }
        fontWeight={900}
      >
        {t('YD_PLATFORM.manageServices')} {t('YD_PLATFORM.inComplete')}
      </LargeText>

      {/* {width < tabletWidth ? (
        <FlexRow margin={0}>
          <TextPiece1 width={width} />
        </FlexRow>
      ) : (
        <FlexRow margin={0}>
          <TextPiece2 width={width} tabletWidth={tabletWidth} laptopWidth={laptopWidth} />
        </FlexRow>
      )} */}
    </FlexColumn>
  );
};

export default Text;
