import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { colors } from '@guryou/colors';
import { Block, FlexColumn, FlexRow, Image, MediumText, Spinner } from '@guryou/html-components';
import { GET_AUTHOR_BY_SLUG, getAuthorBySlug, resetState, stateIsLoaded } from '@guryou/redux';
import LandingFooter from 'components/landingFooter';
import { ArticleCardWithProps } from '../articleSection/ArticleCardWithProps';

const tabletWidth = 900;

const Author = () => {
  const { slug } = useParams();
  const { width } = useSelector(state => state.windowSize);
  const dispatch = useDispatch();
  const author = useSelector(state => state.articles.authorBySlug);

  useEffect(() => {
    dispatch(getAuthorBySlug({ slug }));
  }, [dispatch, slug]);

  useEffect(() => {
    return () => dispatch(resetState(GET_AUTHOR_BY_SLUG));
    //eslint-disable-next-line
  }, []);

  if (!stateIsLoaded(author)) {
    return <Spinner />;
  }
  return (
    <>
      <FlexColumn>
        <>
          <Block height="20vh" backgroundColor={colors.mandy} margin={0} position="absolute" zIndex={-1}></Block>
          <FlexColumn xs={width < 650 ? 12 : 8} boxShadow="0 0 10px rgba(0,0,0,0.3)" marginTop={20} marginBottom={10} padding={10} textAlign="center">
            <Image src={author.data?.image?.url} height="250px" width="250px" borderRadius="1em" objectFit="cover" />
            <MediumText fontSize="2rem" fontWeight={600} marginBottom={4} marginTop={4}>
              {author.data?.display_name}
            </MediumText>
            <MediumText fontSize="1.6rem" fontWeight={400} marginBottom={4}>
              {author.data?.job_title}
            </MediumText>
            <MediumText fontSize="1.4rem" fontWeight={300} textAlign="left">
              {author.data?.biographyInfo}
            </MediumText>
          </FlexColumn>
          <MediumText fontWeight={400}>Latest Posts</MediumText>
          <FlexRow justifyContent="center" xs={9} marginBottom={10}>
            {author.data?.articles?.map(card => (
              <ArticleCardWithProps key={card.id} card={card} width={width} />
            ))}
          </FlexRow>
        </>
      </FlexColumn>
      <LandingFooter width={width} tabletWidth={tabletWidth} color={colors.mandy} />
    </>
  );
};

export default Author;
