"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.parseGoogleMapPlace = exports.parseGoogleMapLocation = void 0;

var parseGoogleMapLocation = function parseGoogleMapLocation(loc) {
  var _loc$position, _loc$position2, _loc$address, _loc$address$address_, _loc$address$address_2, _loc$address2, _loc$address2$address, _loc$address2$address2, _loc$address3, _loc$address3$address, _loc$address3$address2, _loc$address4, _loc$address5, _loc$address5$address;

  var result = {
    latitude: (_loc$position = loc.position) === null || _loc$position === void 0 ? void 0 : _loc$position.lat(),
    longitude: (_loc$position2 = loc.position) === null || _loc$position2 === void 0 ? void 0 : _loc$position2.lng(),
    city: ((_loc$address = loc.address) === null || _loc$address === void 0 ? void 0 : (_loc$address$address_ = _loc$address.address_components) === null || _loc$address$address_ === void 0 ? void 0 : (_loc$address$address_2 = _loc$address$address_.find(function (x) {
      return x.types.includes('locality');
    })) === null || _loc$address$address_2 === void 0 ? void 0 : _loc$address$address_2.long_name) || ((_loc$address2 = loc.address) === null || _loc$address2 === void 0 ? void 0 : (_loc$address2$address = _loc$address2.address_components) === null || _loc$address2$address === void 0 ? void 0 : (_loc$address2$address2 = _loc$address2$address.find(function (x) {
      return x.types.includes('political');
    })) === null || _loc$address2$address2 === void 0 ? void 0 : _loc$address2$address2.long_name),
    postcode: (_loc$address3 = loc.address) === null || _loc$address3 === void 0 ? void 0 : (_loc$address3$address = _loc$address3.address_components) === null || _loc$address3$address === void 0 ? void 0 : (_loc$address3$address2 = _loc$address3$address.find(function (x) {
      return x.types.includes('postal_code');
    })) === null || _loc$address3$address2 === void 0 ? void 0 : _loc$address3$address2.long_name,
    address: (_loc$address4 = loc.address) === null || _loc$address4 === void 0 ? void 0 : _loc$address4.formatted_address
  };
  var countryComponent = (_loc$address5 = loc.address) === null || _loc$address5 === void 0 ? void 0 : (_loc$address5$address = _loc$address5.address_components) === null || _loc$address5$address === void 0 ? void 0 : _loc$address5$address.find(function (x) {
    return x.types.includes('country');
  });

  if (countryComponent) {
    result.country = countryComponent.long_name;
    result.countryCode = countryComponent.short_name;
  }

  return result;
};

exports.parseGoogleMapLocation = parseGoogleMapLocation;

var parseGoogleMapPlace = function parseGoogleMapPlace(place) {
  var _place$geometry, _place$geometry$locat, _place$geometry2, _place$geometry2$loca, _place$address_compon, _place$address_compon2, _place$address_compon3, _place$address_compon4, _place$address_compon5, _place$address_compon6, _place$address_compon7;

  var result = {
    latitude: (_place$geometry = place.geometry) === null || _place$geometry === void 0 ? void 0 : (_place$geometry$locat = _place$geometry.location) === null || _place$geometry$locat === void 0 ? void 0 : _place$geometry$locat.lat(),
    longitude: (_place$geometry2 = place.geometry) === null || _place$geometry2 === void 0 ? void 0 : (_place$geometry2$loca = _place$geometry2.location) === null || _place$geometry2$loca === void 0 ? void 0 : _place$geometry2$loca.lng(),
    city: ((_place$address_compon = place.address_components) === null || _place$address_compon === void 0 ? void 0 : (_place$address_compon2 = _place$address_compon.find(function (x) {
      return x.types.includes('locality');
    })) === null || _place$address_compon2 === void 0 ? void 0 : _place$address_compon2.long_name) || ((_place$address_compon3 = place.address_components) === null || _place$address_compon3 === void 0 ? void 0 : (_place$address_compon4 = _place$address_compon3.find(function (x) {
      return x.types.includes('political');
    })) === null || _place$address_compon4 === void 0 ? void 0 : _place$address_compon4.long_name),
    postcode: (_place$address_compon5 = place.address_components) === null || _place$address_compon5 === void 0 ? void 0 : (_place$address_compon6 = _place$address_compon5.find(function (x) {
      return x.types.includes('postal_code');
    })) === null || _place$address_compon6 === void 0 ? void 0 : _place$address_compon6.long_name,
    address: place.formatted_address
  };
  var countryComponent = (_place$address_compon7 = place.address_components) === null || _place$address_compon7 === void 0 ? void 0 : _place$address_compon7.find(function (x) {
    return x.types.includes('country');
  });

  if (countryComponent) {
    result.country = countryComponent.long_name;
    result.countryCode = countryComponent.short_name;
  }

  return result;
};

exports.parseGoogleMapPlace = parseGoogleMapPlace;