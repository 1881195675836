import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { colors } from '@guryou/colors';
import { FlexColumn, FlexRow, LargeText, MediumText } from '@guryou/html-components';
import BenefitsBackground from 'components/BackgroundFeelDeal/BenefitsBackground';
import Content from './thingsToDoWithGuryouContent/Content';
const ThingsToDoWithGuryou = ({ width, tabletWidth, laptopWidth }) => {
  const { t } = useTranslation();
  const location = useLocation();

  const isRiminiOrDeal = location.pathname.includes('rimini-wellness') || location.pathname.includes('youdeal');

  return (
    <>
      <FlexColumn xs={12} marginTop={15} position="relative">
        <LargeText
          fontWeight={900}
          fontSize={width < 600 ? '1.5rem' : width < tabletWidth ? '2rem' : width < laptopWidth ? '2.4rem' : '2.7rem'}
          textAlign="center"
          marginBottom={5}
          marginTop={15}
        >
          {t('YF_SEARCH.whatYouCanDoWithGY')}
        </LargeText>
        <BenefitsBackground
          rectangleColor={isRiminiOrDeal ? colors.riminiLight : colors.darkgrey}
          trapezoidColorUp={colors.light1}
          trapezoidColorDown={isRiminiOrDeal ? colors.rimini : colors.mineShaft}
        />
        <FlexRow margin={0} marginTop={5} paddingBottom={10} paddingTop={5}>
          <Content width={width} tabletWidth={tabletWidth} />
        </FlexRow>
        <FlexRow margin={0} justifyContent={width < 500 ? 'center' : 'flex-end'} marginTop={15} paddingBottom={10}>
          <FlexRow xs={width < 500 ? 11 : 4} justifyContent={width < 500 ? 'flex-end' : 'center'} margin={0}>
            <MediumText fontSize="1.4rem" fontWeight={700}></MediumText>
          </FlexRow>
        </FlexRow>
      </FlexColumn>
    </>
  );
};

export default ThingsToDoWithGuryou;
