var DEFAULT_SCALE = 4;
var TABLE_ROW_HEIGHT = 54;
var spacer = {
  spacer1: 4,
  spacer2: 8,
  spacer3: 16,
  spacer4: 24,
  spacer5: 32,
  spacer6: 40,
  spacer7: 80
};
var sizes = {
  xSmall: 246,
  small: 300,
  xsmedium: 410,
  medium: 640,
  large: 1080,
  extraLarge: 1550,
  borderRadius: '5px',
  drawerWidth: 260,
  appBarHeight: 75,
  footerBarHeight: 48,
  mobileSubHeaderHeight: 50
};

var borderDefinition = function borderDefinition() {
  var color = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 'rgba(0, 0, 0, 0.12)';
  var borderSize = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '1px';
  return "".concat(borderSize, " solid ").concat(color);
};

var boxShadowDefinition = function boxShadowDefinition() {
  var size = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '0px 0px 0px 0px';
  var color = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'rgba(0, 0, 0, 0.2)';
  return "".concat(size, " ").concat(color);
};

var boxShadow = '10px 2px 5px rgba(0, 0, 0, 0.12)';
var dropShadow = '0 2px 3px rgba(0, 0, 0, 0.3)';
var softShadow = '0 5px 10px rgba(0,0,0,.05)';
var boxShadowLight = '0 0 4px 2px rgba(0, 0, 0, 0.03)';
var extraLargeBorderRadius = '50px';
var largeBorderRadius = '25px';
var mediumBorderRadius = '15px';
var smallBorderRadius = '5px';
export { DEFAULT_SCALE, TABLE_ROW_HEIGHT, spacer, sizes, borderDefinition, boxShadowDefinition, boxShadow, boxShadowLight, dropShadow, softShadow, extraLargeBorderRadius, largeBorderRadius, smallBorderRadius, mediumBorderRadius };