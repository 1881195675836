import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { LargeText } from '@guryou/html-components';

const List = ({ width, laptopWidth, color }) => {
  const { t } = useTranslation();

  const location = useLocation();

  const isRouteBusinessDeal = location.pathname.includes('businessDeal') || location.pathname.includes('rimini-wellness');

  return (
    <LargeText
      width={width < 600 ? '100%' : `${isRouteBusinessDeal ? '100%' : '50%'}`}
      color={color}
      fontSize={width < 600 ? '2rem' : width < laptopWidth ? '2.3rem' : '2.7rem'}
      fontWeight={900}
      lineHeight="1.1"
      marginBottom={5}
    >
      {t('HERO.dealHeader')}
    </LargeText>
  );
};

export default List;
