import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { colors } from '@guryou/colors';
import { FlexColumn } from '@guryou/html-components';
import { handleAnalyticsClick } from 'analytics';
import { motion } from 'framer-motion';
import useThirdSlideStyleStart from '../../makeStyles/ThirdSlideStyleStart';
import FindOutBtn from './thirdSlideContent/FindOutBtn';
import Title from './thirdSlideContent/Title';

const ThirdSlide = ({ thirdSlideVisible, width, laptopWidth }) => {
  const { t, i18n } = useTranslation();

  const classes = useThirdSlideStyleStart();
  const slideAnimation = {
    opacity: thirdSlideVisible ? 1 : 0,
  };
  const slideTransition = {
    duration: 0.8,
  };
  const contentAnimation = {
    display: thirdSlideVisible ? 'block' : 'none',
  };
  return (
    <>
      <motion.div className={classes.slide} animate={slideAnimation} transition={slideTransition}>
        <motion.div className={classes.leftContent} animate={contentAnimation}>
          <FlexColumn margin={0} width="50%" marginTop="21vh" alignItems={width < 1200 ? 'center' : 'start'}>
            <Title width={width} laptopWidth={laptopWidth} />
            <Link
              style={{ margin: '0 auto', marginLeft: '15rem' }}
              to={`/${i18n.language}/youstart`}
              onClick={() =>
                handleAnalyticsClick('Third slide', 'Button clicked!', 'Find out button', 'Lead', 'Find out button on first slide clicked!')
              }
            >
              <FindOutBtn text={t('GENERAL.findHow')} color={colors.fruitSalad} textColor={colors.mineShaft} width={width} />
            </Link>
          </FlexColumn>
        </motion.div>
      </motion.div>
    </>
  );
};

export default ThirdSlide;
