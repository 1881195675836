import _toConsumableArray from "/Users/petarbozhinovski/projects/guryou-mono/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/toConsumableArray";
import _objectSpread from "/Users/petarbozhinovski/projects/guryou-mono/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/objectSpread2";
import { ActionStatus, buildActionType, StateStatus } from '../core';
var initialState = {
  data: null,
  errors: null,
  status: StateStatus.NOT_INITIALIZED,
  version: 0,
  report: null
};

var classes = function classes(actionType) {
  return function () {
    var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
    var action = arguments.length > 1 ? arguments[1] : undefined;

    switch (action.type) {
      case buildActionType(actionType, ActionStatus.START):
        return _objectSpread(_objectSpread({}, initialState), {}, {
          status: StateStatus.LOADING,
          version: state.version,
          entity: actionType.entity
        });

      case buildActionType(actionType, ActionStatus.DONE):
        var payload = action.payload.payload || action.payload;
        var data = 'data' in payload ? payload.data : payload;
        return {
          status: StateStatus.LOADED,
          data: data,
          entity: actionType.entity,
          version: +new Date(),
          report: action.report
        };

      case buildActionType(actionType, ActionStatus.FAILED):
        return {
          status: StateStatus.ERROR,
          errors: action.payload,
          entity: actionType.entity
        };

      case buildActionType(actionType, ActionStatus.RESET):
        return initialState;

      case buildActionType(actionType, ActionStatus.UPDATE):
        return _objectSpread(_objectSpread({}, state), {}, {
          data: _toConsumableArray(action.newArr)
        });

      case buildActionType(actionType, ActionStatus.INCREASE):
        return _objectSpread(_objectSpread({}, state), {}, {
          data: [].concat(_toConsumableArray(state.data.slice(0, action.index)), [_objectSpread(_objectSpread({}, state.data[action.index]), {}, {
            related: parseInt(state.data[action.index].related) + 1
          })], _toConsumableArray(state.data.slice(action.index + 1)))
        });

      case buildActionType(actionType, ActionStatus.DECREASE):
        return _objectSpread(_objectSpread({}, state), {}, {
          data: [].concat(_toConsumableArray(state.data.slice(0, action.index)), [_objectSpread(_objectSpread({}, state.data[action.index]), {}, {
            related: parseInt(state.data[action.index].related) - 1
          })], _toConsumableArray(state.data.slice(action.index + 1)))
        });

      default:
        return state;
    }
  };
};

export { classes };