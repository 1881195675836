import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { ARTICLES_QUERY } from '@guryou/api-client-gql';
import { colors } from '@guryou/colors';
import { FlexColumn, FlexRow, LargeText, LinkButton, SmallText } from '@guryou/html-components';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import ArticlesBackground from 'components/BackgroundFeelDeal/ArticlesBackground';
import ArticleCard from './ArticleCard';
import { Query } from '../../../../Query';

const Articles = ({ width, tabletWidth }) => {
  const { t, i18n } = useTranslation();

  return (
    <FlexColumn marginTop={width < 1100 ? 10 : 20} marginBottom={width < 650 ? 5 : 20} paddingBottom={width < 650 ? 35 : 15} position="relative">
      <ArticlesBackground width={width} smallWidth={true} />
      <FlexRow xs={11} justifyContent={width < 650 ? 'center' : 'space-between'} marginBottom={3} alignItems="center" marginTop={7}>
        <LargeText fontSize={width < 1400 ? '2rem' : '2.7rem'} fontWeight={700}>
          {t('CONTENT.recentArticles')}
        </LargeText>
        {width < 650 ? (
          ''
        ) : (
          <LinkButton to={`/${i18n.language}/category/all`}>
            {' '}
            <SmallText fontSize={width < 1400 ? '1rem' : '1.5rem'} marginTop={5}>
              {t('CONTENT.readThemAll')} <ArrowForwardIcon style={{ verticalAlign: 'middle' }} />
            </SmallText>
          </LinkButton>
        )}
      </FlexRow>
      <FlexRow xs={11.5}>
        <Query query={ARTICLES_QUERY} start={0} limit={3}>
          {({ data: { articles } }) => {
            return width < 1100 ? (
              <FlexColumn xs={12}>
                <Link to={`/${i18n.language}/post/${articles[0]?.Slug}`}>
                  <ArticleCard
                    article={articles[0]}
                    description={articles[0]?.description}
                    width={width}
                    cardWidth={12}
                    imageHeight="420px"
                    categoryTextColor={colors.grey}
                  />
                </Link>
                <FlexRow>
                  <FlexColumn xs={width < tabletWidth ? 12 : 6}>
                    <Link to={`/${i18n.language}/post/${articles[1]?.Slug}`}>
                      <ArticleCard
                        article={articles[1]}
                        imageHeight="205px"
                        textHeight="205px"
                        width={width}
                        cardWidth={12}
                        categoryTextColor={colors.light1}
                      />
                    </Link>
                  </FlexColumn>
                  <FlexColumn xs={width < tabletWidth ? 12 : 6}>
                    <Link to={`/${i18n.language}/post/${articles[2]?.Slug}`}>
                      <ArticleCard
                        article={articles[2]}
                        imageHeight="205px"
                        textHeight="205px"
                        width={width}
                        cardWidth={12}
                        categoryTextColor={colors.light1}
                      />
                    </Link>
                  </FlexColumn>
                </FlexRow>
              </FlexColumn>
            ) : (
              <>
                <FlexColumn xs={7}>
                  <Link to={`/${i18n.language}/post/${articles[0]?.Slug}`}>
                    <ArticleCard
                      article={articles[0]}
                      description={articles[0]?.description}
                      width={width}
                      cardWidth={12}
                      imageHeight="420px"
                      categoryTextColor={colors.grey}
                    />
                  </Link>
                </FlexColumn>
                <FlexColumn xs={5}>
                  <Link to={`/${i18n.language}/post/${articles[1]?.Slug}`}>
                    <ArticleCard article={articles[1]} imageHeight="205px" textHeight="205px" categoryTextColor={colors.light1} />
                  </Link>
                  <Link to={`/${i18n.language}/post/${articles[2]?.Slug}`} style={{ marginTop: '10px' }}>
                    <ArticleCard article={articles[2]} imageHeight="205px" textHeight="205px" categoryTextColor={colors.light1} />
                  </Link>
                </FlexColumn>
              </>
            );
          }}
        </Query>
      </FlexRow>
    </FlexColumn>
  );
};

export default Articles;
