import { TableCellBody } from './Components';

var tableRowToColumns = function tableRowToColumns(tableRow) {
  var columns = tableRow.props.children.filter(function (x) {
    return x.type.name === TableCellBody.name;
  }).map(function (x) {
    var _x$props = x.props,
        name = _x$props.name,
        label = _x$props.label,
        render = _x$props.render,
        display = _x$props.display,
        size = _x$props.size,
        textPosition = _x$props.textPosition;
    var result = {
      name: name,
      label: label,
      options: {
        sort: false
      }
    };

    if (display !== undefined) {
      result.options.display = display;
    }

    if (render) {
      result.options.customBodyRender = render;
      result.options.isSelectable = false;
    }

    if (size) {
      result.options.setCellProps = function () {
        if (size) {
          return {
            className: size
          };
        }
      };
    }

    if (textPosition) {
      result.options.setCellProps = function () {
        if (textPosition) {
          return {
            className: "".concat(textPosition, " ").concat(size)
          };
        }
      };
    }

    return result;
  });
  return columns;
};

export { tableRowToColumns };