import React from 'react';
import { colors } from '@guryou/colors';
import { Block, FlexColumn, MediumText, SmallText } from '@guryou/html-components';
import { smallBorderRadius } from '@guryou/html-theme';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';

const Card = ({ title, content, width, tabletWidth }) => {
  return (
    <FlexColumn
      backgroundColor={colors.white}
      xs={width < tabletWidth ? 11 : 4}
      marginBottom={width < tabletWidth ? 5 : 0}
      boxShadow="0px 0px 10px rgba(0, 0, 0, 0.3)"
      padding={9}
      marginRight={width < tabletWidth ? 'auto' : 9}
      alignItems="start"
      justifyContent="space-between"
      borderRadius={smallBorderRadius}
      hover={true}
      transition="all .2s ease-in-out;"
      cursor="pointer"
      hoverEffect={{ transform: 'scale(1.05)' }}
    >
      <FlexColumn alignItems="start" xs={12}>
        <MediumText fontSize="1.5rem" fontWeight={700} margin={0}>
          {title}
        </MediumText>
        <Block width={`${title.length * 2}%`} backgroundColor={colors.mandy} height="4px" margin={0} />
      </FlexColumn>
      <SmallText marginTop={4} fontSize="1.1rem" fontWeight={400}>
        {content}
      </SmallText>
      <ArrowForwardIcon style={{ marginTop: '15px' }} />
    </FlexColumn>
  );
};

export default Card;
