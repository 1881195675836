import { WINDOW_RESIZE } from '../types';

var resizeWindow = function resizeWindow(sizes) {
  return function (dispatch) {
    return dispatch({
      type: WINDOW_RESIZE,
      payload: sizes
    });
  };
};

export { resizeWindow };