import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { colors } from '@guryou/colors';
import { Block } from '@guryou/html-components';

const ArticlesBackground = ({ width, trapezoidWidth, smallWidth }) => {
  const location = useLocation();
  const { i18n } = useTranslation();
  let appBarColor = colors.mandy;

  if (location?.pathname === `/${i18n.language}/businessDeal`) {
    appBarColor = colors.mineShaft;
  }
  if (location?.pathname === `/${i18n.language}/youstart`) {
    appBarColor = colors.fruitSalad;
  }
  if (
    location?.pathname === `/${i18n.language}/rimini-wellness` ||
    location?.pathname === `/${i18n.language}/youdeal` ||
    location?.pathname === `/${i18n.language}/guryoudeal`
  ) {
    appBarColor = colors.rimini;
  }

  return (
    <>
      <Block
        borderTop={width < 1100 ? '' : `600px solid ${colors.light1}`}
        borderRight={width < 1100 ? '' : '300px solid transparent'}
        width={smallWidth && width >= 1100 ? '68%' : trapezoidWidth < 1100 ? '50%' : width < 1100 ? '100%' : width < 1440 ? '60%' : '63%'}
        background={
          width < 1100
            ? `linear-gradient(-160deg, ${appBarColor}, ${appBarColor} ${width < 650 ? '68%' : '60%'}, transparent calc(${
                width < 650 ? '68%' : '60%'
              } + 1px), transparent)`
            : ''
        }
        position="absolute"
        height={width < 650 ? '800px' : width < 900 ? '1200px' : width < 1100 ? '800px' : '0'}
        top="0"
        left="0"
        zIndex="-1"
        margin={0}
      />
      <Block
        borderBottom={width < 1100 ? 'none' : `220px solid ${colors.light1}`}
        borderRight={trapezoidWidth < 900 ? '30vw solid transparent' : '10vw solid transparent'}
        width={smallWidth && width >= 1100 ? '60vw' : trapezoidWidth < 1100 ? '25vw' : width < 1440 ? '50vw' : '44vw'}
        margin={0}
        height="0"
        position="absolute"
        bottom="0"
        left="0"
        zIndex="-1"
        marginTop="-1px"
      />
      <Block
        position="absolute"
        top={width < 1100 ? '10px' : '20px'}
        right="0"
        width="57.8%"
        zIndex="-2"
        backgroundColor={width < 1100 ? colors.light1 : appBarColor}
        height={width < 650 ? '800px' : '650px'}
        margin={0}
      />
    </>
  );
};

export default ArticlesBackground;
