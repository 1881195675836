import logo1 from 'assets/img/newHomepageLogos/292x0w (1).webp';
import logo2 from 'assets/img/newHomepageLogos/292x0w (2).webp';
import logo3 from 'assets/img/newHomepageLogos/292x0w (3).webp';
import logo4 from 'assets/img/newHomepageLogos/292x0w (4).webp';
import logo5 from 'assets/img/newHomepageLogos/292x0w (5).webp';
import logo6 from 'assets/img/newHomepageLogos/292x0w (6).webp';
import logo7 from 'assets/img/newHomepageLogos/292x0w (7).webp';
import logo8 from 'assets/img/newHomepageLogos/292x0w.webp';

export const LogosInfo = [
  {
    id: 0,
    image: logo1,
  },
  {
    id: 1,
    image: logo2,
  },
  {
    id: 2,
    image: logo3,
  },
  {
    id: 3,
    image: logo4,
  },
  {
    id: 4,
    image: logo5,
  },
  {
    id: 5,
    image: logo6,
  },
  {
    id: 6,
    image: logo7,
  },
  {
    id: 7,
    image: logo8,
  },
];
