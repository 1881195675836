"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ExerciseWeightMode = exports.ExerciseDisplayUnits = exports.ExerciseExecutionMode = exports.CircuitTypes = exports.WorkoutLevels = void 0;
var WorkoutLevels = {
  allLevels: 'all_levels',
  beginner: 'beginner',
  intermediate: 'intermediate',
  expert: 'expert',
  athlete: 'athlete'
};
exports.WorkoutLevels = WorkoutLevels;
var CircuitTypes = {
  warmUp: 'warm_up',
  training: 'training',
  cooldown: 'cooldown'
};
exports.CircuitTypes = CircuitTypes;
var ExerciseExecutionMode = {
  repetition: 'repetition',
  repetitionPerSide: 'repetitionPerSide',
  duration: 'duration',
  durationPerSide: 'durationPerSide',
  distance: 'distance',
  laps: 'laps'
};
exports.ExerciseExecutionMode = ExerciseExecutionMode;
var ExerciseDisplayUnits = {
  times: 'times',
  min: 'min',
  sec: 'sec',
  meters: 'meters',
  km: 'km',
  kg: 'kg',
  pounds: 'pounds',
  percent: '%',
  kcal: 'kcal',
  hours: 'hours'
};
exports.ExerciseDisplayUnits = ExerciseDisplayUnits;
var ExerciseWeightMode = {
  kg: 'KG',
  kgPerSide: 'KG per side',
  rpe: 'RPE',
  oneRm: '1-RM',
  buffer: 'Buffer'
};
exports.ExerciseWeightMode = ExerciseWeightMode;