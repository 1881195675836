"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.s3Actions = void 0;
var s3Actions = {
  putObject: 'putObject',
  getObject: 'getObject'
};
exports.s3Actions = s3Actions;