import React, { Suspense } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';
import MomentUtils from '@date-io/moment';
import { DynamicThemeProvider, FlexRow, FullScreenSpinner, Loading, Snackbar } from '@guryou/html-components';
import { feelTheme } from '@guryou/html-theme';
import CssBaseline from '@material-ui/core/CssBaseline';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
// import SkypeIcon from 'assets/img/skypeIcon.svg';
import WhatsAppIcon from 'assets/img/whatsAppIcon.svg';
import ResetPassword from 'containers/authComponents/ResetPassword';
import moment from 'moment-timezone';
import ScrollTop from 'ScrollTop';
import ContactWidget from 'components/ContactWidget';
import TopNavigation from 'components/navigation';
import Routes from './Routes';
import VerifyPassword from './modules/verify';

const App = () => {
  const { t } = useTranslation();
  const { width } = useSelector(state => state.windowSize);

  const isItMobile = width < 600;
  return (
    <Suspense fallback={<FullScreenSpinner />}>
      <DynamicThemeProvider theme={feelTheme}>
        <CssBaseline />
        <Snackbar maxSnack={2}>
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <Loading />
            <Router>
              <ScrollTop>
                <TopNavigation />
                <FlexRow
                  position="fixed"
                  bottom="0"
                  margin={0}
                  zIndex={100}
                  paddingBottom={isItMobile ? '10px' : '16px'}
                  marginLeft={isItMobile ? '25px' : '15px'}
                  width="160px"
                  alignItems="center"
                >
                  <ContactWidget
                    width={isItMobile ? '60px' : '85px'}
                    height={isItMobile ? '60px' : '85px'}
                    link={process.env.REACT_APP_WATSUP_CONTACT}
                    image={WhatsAppIcon}
                    color="#25D366"
                    GALabel={t('GENERAL.watsUpWidget')}
                    FBStandardEvent={'Contact'}
                    FBCustomProperty={'WhatsApp widget clicked!'}
                  />
                  {/* <ContactWidget
                  width="60px"
                  height="60px"
                  marginLeft="16px"
                  link="skype:live:.cid.b9b99936458d5c96"
                  image={SkypeIcon}
                  color="#00AAE8"
                  GALabel={'Skype Widget'}
                  FBStandardEvent={'Contact'}
                  FBCustomProperty={'Skype widget clicked!'}
                /> */}
                </FlexRow>
                <Switch>
                  <Route exact path={`/verify/:email/:code`} component={VerifyPassword} />
                  <Route exact path={`/reset/:email/:code`} component={ResetPassword} libInstance={moment} />
                  <Route exact path="/" render={() => <Redirect to="/it" />} />
                  <Route path="/:lang" component={Routes} />
                </Switch>
              </ScrollTop>
            </Router>
          </MuiPickersUtilsProvider>
        </Snackbar>
      </DynamicThemeProvider>
    </Suspense>
  );
};
export default App;
