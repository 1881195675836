import React from 'react';
import { LinkButton, SmallText } from '@guryou/html-components';

const Text = ({ text, link, fontSize, color, marginTop, fontWeight }) => {
  return text ? (
    <LinkButton to={link || '/'}>
      <SmallText fontSize={fontSize} marginTop={marginTop} fontWeight={fontWeight} color={color}>
        {text}
      </SmallText>
    </LinkButton>
  ) : (
    ''
  );
};

export default Text;
