import _toConsumableArray from "/Users/petarbozhinovski/projects/guryou-mono/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/toConsumableArray";
import { useDispatch, useSelector } from 'react-redux';
import { useUpdateEffect } from '@guryou/hooks';
import { StateStatus } from '../core/StateStatus';

var defaultSelector = function defaultSelector() {
  return {
    status: StateStatus.NOT_INITIALIZED
  };
};

var useEffectOnError = function useEffectOnError(stateSelector, effect) {
  var deps = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : [];
  var dispatch = useDispatch();

  var _useSelector = useSelector(function (state) {
    if (Array.isArray(stateSelector)) {
      var values = stateSelector.map(function (fn) {
        return fn(state);
      });
      return values.sort(function (a, b) {
        return a.version - b.version;
      }).pop();
    }

    if (stateSelector) {
      return stateSelector(state);
    }

    return defaultSelector(state);
  }),
      data = _useSelector.data,
      status = _useSelector.status,
      version = _useSelector.version,
      errors = _useSelector.errors,
      entity = _useSelector.entity;

  useUpdateEffect(function () {
    if (status === StateStatus.ERROR) {
      effect(errors);
    }
  }, [dispatch, status].concat(_toConsumableArray(deps)));
  return {
    data: data || [],
    status: status,
    version: version,
    entity: entity,
    errors: errors,
    isLoading: status === StateStatus.LOADING
  };
};

export default useEffectOnError;