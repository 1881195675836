var Action = {
  CREATE: '_CREATE',
  UPDATE: '_UPDATE',
  GET: '_GET',
  GET_MANY: '_GET_MANY',
  GET_ONE: '_GET_ONE',
  DELETE: '_DELETE',
  RESET: '_RESET',
  VALIDATE: '_VALIDATE',
  HANDLE: '_HANDLE',
  ARCHIVE: '_ARCHIVE',
  SET: '_SET',
  INSERT: '_INSERT',
  SET_KEYS: '_SET_KEYS',
  SET_OPTIONS: '_SET_OPTIONS',
  REORDER: '_REORDER'
};
export { Action };