import React from 'react';
import { useTranslation } from 'react-i18next';
import { FlexColumn, FlexRow, LargeText, MediumText, SmallText } from '@guryou/html-components';
import Stars from './Stars';

const AboutUsCard = ({ width, tabletWidth, title, subTitle, text, textColor, rating }) => {
  const { t } = useTranslation();
  return (
    <FlexColumn
      marginBottom={10}
      xs={width < 600 ? 11 : 5}
      alignItems={width < tabletWidth ? 'center' : 'start'}
      padding={5}
      textAlign={width < tabletWidth ? 'center' : ''}
    >
      <LargeText color={textColor} marginTop={5} fontSize="2.2rem">
        {title}
      </LargeText>
      <MediumText color={textColor} marginBottom={5} fontStyle="italic" fontSize="1.3rem" fontWeight={500}>
        {subTitle}
      </MediumText>
      <FlexRow margin={0} justifyContent={width < tabletWidth ? 'center' : ''}>
        <MediumText color={textColor} fontWeight={400} fontSize="1rem" marginRight={1}>
          {t('GENERAL.rating')}:
        </MediumText>
        <Stars rating={rating} />
      </FlexRow>
      <SmallText color={textColor} marginTop={3} marginBottom={2} fontSize="1.2rem" fontStyle="italic" fontWeight={400}>
        {text}
      </SmallText>
    </FlexColumn>
  );
};

export default AboutUsCard;
