"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ErrorCodes = void 0;
var ErrorCodes = {
  ApplicationNeedsUpdate: 'Not supported Application!',
  CircuitConfigExistsForUser: 'circuitConfigExistsForUser',
  CustomerDoesNotExist: 'UserNotFoundException',
  InvalidCredentials: 'InvalidCredentials',
  InvalidCustomer: 'invalidCustomer',
  InvalidDates: 'invalidDates',
  InvalidStartDateBeforeEndDate: 'invalidStartDateBeforeEndDate',
  InvalidPastDate: 'invalidPastDate',
  MissingSharingAgreement: 'missingSharingAgreement',
  MissingStripeAccount: 'missingStripeAccount',
  NotAuthorized: 'NotAuthorizedException',
  OwnerHasCanceledWhiteLabelApp: 'ownerHasCanceledWhiteLabelApp',
  ReservationInvalidBreak: 'reservationInvalidBreak',
  ReservationInvalidCapacity: 'reservationInvalidCapacity',
  ReservationInvalidClass: 'reservationInvalidClass',
  ReservationInvalidPause: 'reservationInvalidPause',
  ReservationInvalidReschedule: 'reservationInvalidReschedule',
  ReservationInvalidResources: 'reservationInvalidResources',
  ReservationInvalidSchedule: 'reservationInvalidSchedule',
  ReservationInvalidService: 'reservationInvalidService',
  SectionsInProduct: 'sectionsInProduct',
  SectionInvalidArchive: 'sectionInvalidArchive',
  SubscriptionCannotCancelTotalClassSession: 'subscriptionCannotCancelTotalClassSession',
  SubscriptionInvalidCapacity: 'subscriptionInvalidCapacity',
  SubscriptionInvalidMembership: 'subscriptionInvalidMembership',
  SubscriptionInvalidPastSessions: 'subscriptionInvalidPastSessions',
  SubscriptionInvalidSessionsLimit: 'subscriptionInvalidSessionsLimit',
  SubscriptionInvalidSessionsStatus: 'subscriptionInvalidSessionsStatus',
  ProductInvalidArchive: 'productInvalidArchive',
  UserDisabled: 'User is disabled.',
  UserVideoAlreadyAssigned: 'userVideoAlreadyAssigned'
};
exports.ErrorCodes = ErrorCodes;