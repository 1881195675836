import React from 'react';
import { useTranslation } from 'react-i18next';
import { DefaultText, FlexColumn, LargeText } from '@guryou/html-components';
import ArticlesBackground from 'components/BackgroundFeelDeal/ArticlesBackground';
import CardCarousel from 'components/carousel';
import CarouselSlide from './CarouselSlide';

const AboutUs = ({ width }) => {
  const { t } = useTranslation();

  return (
    <>
      <FlexColumn xs={11}>
        <LargeText fontSize={width < 768 ? '1.5rem' : width < 1400 ? '2.2rem' : '2.8rem'} marginTop={10} fontWeight={700}>
          {t('YD_TESTIMONIALS.aboutUsTitle')}
        </LargeText>
        <DefaultText fontSize={width < 768 ? '1.2rem' : width < 1400 ? '1.6rem' : '1.8rem'} marginTop={5} fontWeight={500} textAlign="center">
          {t('YD_TESTIMONIALS.aboutUsSubTitle')}
        </DefaultText>
      </FlexColumn>
      <FlexColumn marginBottom={15} paddingTop={20} marginTop={10} position="relative">
        <ArticlesBackground trapezoidWidth={width} />

        <CardCarousel CarouselSlide={<CarouselSlide width={width} />} width={width} totalSlides={5} />
      </FlexColumn>
    </>
  );
};

export default AboutUs;
