import React from 'react';
import { useTranslation } from 'react-i18next';
import { colors } from '@guryou/colors';
import { SmallText } from '@guryou/html-components';
import './index.css';

const ToggleSwitch = ({ width, tabletWidth, toggle, setToggle, isRiminiOrDeal }) => {
  const { t } = useTranslation();

  return (
    <label className="toggle" style={{ left: width < tabletWidth ? '0px' : '20px', cursor: width < 900 ? 'none' : 'pointer' }}>
      <input type="checkbox" defaultChecked={toggle} onChange={() => setToggle(!toggle)} />
      <span className={`slider-deal round ${isRiminiOrDeal ? 'rimini' : ''}`}>
        <SmallText fontSize="1rem" className="centers" color={colors.mineShaft}>
          {t('GENERAL.centers')}
        </SmallText>
        <SmallText fontSize="1rem" className="professionals" color={toggle ? colors.mineShaft : colors.white}>
          {t('GENERAL.professionals')}
        </SmallText>
      </span>
    </label>
  );
};

export default ToggleSwitch;
