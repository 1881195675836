import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Background from 'components/Background';
import FirstSlide from './FirstSlide';
import FourthSlide from './FourthSlide';
import SecondSlide from './SecondSlide';
import ThirdSlide from './ThirdSlideStart';

const defaultSlide = [true, false, false, false];
const tabletWidth = 900;
const laptopWidth = 1300;
const MILISECONDS_TO_REPEAT = 5000;

const startInterval = (slide, setSlide) => {
  const currentSlide = slide.findIndex(x => !!x);

  if (currentSlide === 3) {
    setSlide(defaultSlide);
    return;
  }
  const newArr = [...slide];
  newArr[currentSlide] = !newArr[currentSlide];
  newArr[currentSlide + 1] = !newArr[currentSlide + 1];
  setSlide(newArr);
};

const Home = () => {
  const [slide, setSlide] = useState(defaultSlide);
  const { width } = useSelector(state => state.windowSize);

  useEffect(() => {
    const timeoutId = setInterval(() => startInterval(slide, setSlide), MILISECONDS_TO_REPEAT);
    return () => clearInterval(timeoutId);
  }, [slide]);

  const handleSlideChange = index => {
    if (slide[index]) {
      return;
    } else {
      const previousState = [...slide];
      for (let i = 0; i < previousState.length; i++) {
        if (index === i) {
          previousState[i] = true;
        } else {
          previousState[i] = false;
        }
      }
      setSlide(previousState);
    }
  };
  return (
    <>
      <Background
        width={width}
        firstSlideVisible={slide[0]}
        secondSlideVisible={slide[1]}
        thirdSlideVisible={slide[2]}
        fourthSlideVisible={slide[3]}
        clickFirstDot={() => handleSlideChange(0)}
        clickSecondDot={() => handleSlideChange(1)}
        clickThirdDot={() => handleSlideChange(2)}
        clickFourthDot={() => handleSlideChange(3)}
      />
      <FirstSlide width={width} tabletWidth={tabletWidth} laptopWidth={laptopWidth} firstSlideVisible={slide[0]} />
      <SecondSlide width={width} tabletWidth={tabletWidth} laptopWidth={laptopWidth} secondSlideVisible={slide[1]} />
      <ThirdSlide width={width} tabletWidth={tabletWidth} laptopWidth={laptopWidth} thirdSlideVisible={slide[2]} />
      <FourthSlide width={width} tabletWidth={tabletWidth} laptopWidth={laptopWidth} fourthSlideVisible={slide[3]} />
    </>
  );
};

export default Home;
