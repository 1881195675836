import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { colors } from '@guryou/colors';
import { Block, FlexColumn, FlexRow, LargeText, LinkButton, MediumText } from '@guryou/html-components';
import HeroImageFeel from 'assets/img/feel-hero-image.jpg';
import HeroBackground from 'components/BackgroundFeelDeal/HeroBackground';
import HeroButton from './Button/HeroButton';

const Hero = ({ width, tabletWidth, laptopWidth }) => {
  const { t, i18n } = useTranslation();

  const w = width <= 1500 ? (width < 500 ? '90%' : '60%') : '100%';

  return (
    <>
      <Block display="flex" justifyContent="flex-end">
        <Link to={`/${i18n.language}/youdeal`}>
          {width < 650 ? (
            ''
          ) : (
            <MediumText color={colors.white} fontSize={width < tabletWidth ? '0.9rem' : '1.2rem'} textDecoration="underline" margin={5}>
              {t('HERO.areYouBusinessUser')}
            </MediumText>
          )}
        </Link>
      </Block>
      <HeroBackground laptopWidth={laptopWidth} width={width} tabletWidth={tabletWidth} image={HeroImageFeel} color={colors.mandy} isDeal={false} />
      <FlexColumn
        marginTop={10}
        xs={width < tabletWidth ? 12 : 11}
        paddingLeft={5}
        paddingRight={width < tabletWidth ? 5 : 0}
        alignItems="start"
        height={width < 650 ? '600px' : width < tabletWidth ? '800px' : '700px'}
        marginLeft={width > 700 ? 10 : 0}
      >
        <LargeText
          width={w}
          fontSize={width < 450 ? '2rem' : width < 800 ? '2.3rem' : width < tabletWidth ? '2.4rem' : '2.7rem'}
          fontWeight={900}
          lineHeight="1.1"
          marginBottom={5}
          color={colors.white}
        >
          {t('HERO.feelHeader')}
        </LargeText>
        <MediumText
          fontSize={width < 600 ? '1.2rem' : width < laptopWidth ? '1.3rem' : '1.7rem'}
          fontWeight={600}
          color={colors.white}
          marginBottom={10}
          width={w}
        >
          {t('HERO.feelSubHeader')}
        </MediumText>
        <FlexRow>
          <LinkButton to={`/${i18n.language}/info`}>
            <HeroButton width={width} tabletWidth={tabletWidth} text={t('HERO.requestInfo')} />
          </LinkButton>
          <LinkButton to={`/${i18n.language}/demo`}>
            <HeroButton width={width} tabletWidth={tabletWidth} text={t('HERO.requestADemo')} />
          </LinkButton>
        </FlexRow>
      </FlexColumn>
    </>
  );
};

export default Hero;
