import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { FlexColumn, FlexRow, LargeText, LinkButton } from '@guryou/html-components';
import CallToActionButton from 'components/CallToActionButton';
import MobilePricingPlanCards from './MobilePricingPlanCards';
import PricingPlanCards from './PricingPlanCards';
import ToggleSwitch from './toggleSwitch';

const PricingPlans = ({ width, tabletWidth, laptopWidth }) => {
  const { t, i18n } = useTranslation();
  const location = useLocation();

  const isRiminiOrDeal = location.pathname.includes('rimini-wellness') || location.pathname.includes('youdeal');

  // when false -> centri, when true -> professionalisti
  const [toggle, setToggle] = useState(true);
  return (
    <FlexColumn padding={0} margin={0} marginTop={width < 600 ? 10 : 20} marginBottom={15}>
      <LargeText marginBottom={5} fontSize={width < laptopWidth ? '2.2rem' : '2.8rem'} textAlign={width < 600 ? 'center' : ''} fontWeight={700}>
        {t('YD_PLANS.discoverAllGYPlans')}
      </LargeText>

      <FlexColumn xs={12}>
        <ToggleSwitch setToggle={setToggle} toggle={toggle} width={width} tabletWidth={tabletWidth} isRiminiOrDeal={isRiminiOrDeal} />
      </FlexColumn>

      <FlexRow margin={0} marginTop={5} paddingLeft={width < 600 ? 0 : 1} paddingRight={width < 600 ? 0 : 1}>
        {width < 830 ? <MobilePricingPlanCards toggle={toggle} width={width} /> : <PricingPlanCards toggle={toggle} />}
      </FlexRow>
      <FlexColumn marginTop={20}>
        <LinkButton to={`/${i18n.language}/info`}>
          <CallToActionButton text={t('YD_PLATFORM.requestMoreInfo').toUpperCase()} isRiminiOrDeal={isRiminiOrDeal} />
        </LinkButton>
      </FlexColumn>
    </FlexColumn>
  );
};

export default PricingPlans;
